import React from "react";
import { Button, Result } from "antd";


const ErrorBlock = (props) => {

    const {
        status = "500",
        title = "ERROR",
        subtitle = "Sorry something went wrong. Please try to reload page or contact with administrator.",
        action,
        buttonText = "Back home"
    } = props;

    return(
        <Result
            status={ status }
            title={ title }
            subTitle={ subtitle }
            extra={
                <Button
                    className="rounded-button"
                    type="primary"
                    onClick={ action }
                >
                    { buttonText }
                </Button>
            }
        />
    );
};

export default ErrorBlock;