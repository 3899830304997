import React from "react";
import { useQuery } from "@apollo/client";
import { GET_COLLECTION } from "graphql/query/collection-gql";
import { PageWrapDefault } from "components/layout";
import { useActiveMenuItem } from "components/use-hooks";
import CollectionRoute from 'components/routes/collection/collection-route';
import Collection from "components/collection";
import { Localize } from "components/service";



const CollectionPage = ({ match, history }) => {

    useActiveMenuItem([ "collections" ], [ "collections" ]);

    const collectionID = match ? parseInt(match.params.id) : null;

    const { data: { collection } = {}, loading } = useQuery(GET_COLLECTION, {
        skip: !collectionID,
        variables: {
            id: collectionID
        }
    });


    const pageNavbar = [
        {
            label: Localize({ children: "NAVBAR_PAGES.Label_Overview" }).props.children,
            path: "overview"
        },
        {
            label: Localize({ children: "NAVBAR_PAGES.Label_Posts" }).props.children,
            path: "posts"
        },
        {
            label: Localize({ children: "NAVBAR_PAGES.Label_Followers" }).props.children,
            path: "followers"
        }
    ];


    return (
        <PageWrapDefault
            className="page-collection"
            loading={ loading }
            title={ !loading && collection ? collection.title : 'Page 404' }
            dataExist={ !loading }
            pageNavbar={ pageNavbar }
            staticPath={ `${ Collection.Const.basePath }/${ collectionID }` }

        >
            <CollectionRoute
                collection={ collection }
                history={ history }
                loading={ loading } />

        </PageWrapDefault>
    );
};

export default CollectionPage;
