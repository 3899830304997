import HashtagFields from "./fields";
import HashtagConst from "./hashtag-const";
import HashtagHelpers from "./helpers";


const Hashtag = {
  Fields: HashtagFields,
  Const: HashtagConst,
  Helpers: HashtagHelpers,
}

export default Hashtag;
