import React from "react";
import { Link } from 'react-router-dom';
import Dealer from "components/dealer";
import UserConst from '../users-const';

const UserQuestionAuthorField = ({ userQuestion }) => {

    const question = userQuestion?.relAuthor;

    const id = question.id,
        name = question.name,
        nickname = question.nickname,
        email = question.email,
        phone = question.phone,
        dealer = question.dealer,
        dealerCompanyName = dealer?.company_name,
        dealerNumber = dealer?.dealer_number;

    return (
        <>
            { id && (
                <>
                    <Link to={ `${ UserConst.basePath }/${ id }` }>
                        { `${ name } (${ nickname })` }
                    </Link>
                    <br />
                    { dealer ? (
                        <Link to={ `${ Dealer.Const.basePath }/${ dealer?.id }` }>
                            <span>
                                { dealerCompanyName ? dealerCompanyName : <strong>&mdash;</strong> }
                            </span>
                            &nbsp;
                            <span>
                                { dealerNumber ? `(${ dealerNumber })` : <strong>&mdash;</strong> }
                            </span>
                        </Link>
                    ) : (
                        <strong>&mdash;</strong>
                    ) }
                    <br />
                    { email } <br />
                    { phone } <br />
                </>
            ) }
        </>
    );
}

export default UserQuestionAuthorField;
