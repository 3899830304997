import HashtagFields from "../fields";
import { Localize } from "components/service";


let hashtagColumns = [
    {
        title: <Localize>TABLES.Column_Title_ID</Localize>,
        dataIndex: 'id',
        columnIndex: 'ID',
        align: 'center',
        width: '5%'
    },
    {
        title: <Localize>TABLES.Column_Title_Title</Localize>,
        dataIndex: 'slug',
        columnIndex: 'SLUG',
        sorter: true
    },
    {
        title: <Localize>TABLES.Column_Title_Posts</Localize>,
        dataIndex: 'count_posts',
        columnIndex: 'COUNT_POSTS',
        align: 'center',
        sorter: true
    },

    {
        title: <Localize>TABLES.Column_Title_Followers</Localize>,
        dataIndex: 'count_followers',
        columnIndex: 'COUNT_FOLLOWERS',
        align: 'center',
        sorter: true
    },
    {
        title: <Localize>TABLES.Column_Title_CreateDate</Localize>,
        dataIndex: 'created_at',
        columnIndex: 'CREATED_AT',
        sorter: true
    },

    {
        // title: 'action',
        dataIndex: 'action',
        align: 'center',
        width: '4%'
    }
];


const hashtagData = ({ model }) => {

    if (!model)
    {
        return [];
    }

    return model.map(hashtag => {

        return {
            key: hashtag.id,
            id: hashtag.id,
            slug: <HashtagFields.Title hashtag={ hashtag } />,
            count_posts: hashtag.count_posts ?? 0,
            count_followers: hashtag.count_followers ?? 0,
            status: hashtag.status ?? <strong>&mdash;</strong>,
            created_at: hashtag.created_at ?? <strong>&mdash;</strong>,
            action: <HashtagFields.Action hashtag={ hashtag } />
        };

    });
};


const HashtagTableHelper = (columnsHide = []) => {

    hashtagColumns = hashtagColumns.filter(({ dataIndex }) => !columnsHide?.includes(dataIndex))

    return {
        columns: hashtagColumns,
        data: hashtagData,
    }
}


export default HashtagTableHelper;