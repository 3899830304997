import React from "react";
import Counter from "components/counter";
import { useBreadCrumbs } from "components/use-hooks";
import { Localize } from "components/service";



const SettingsCountersSubPage = ({ summaryStatistic }) => {

    const breadcrumbs = [
        {
            label: Localize({ children: "BREADCRUMBS.Label_Settings" }).props.children,
            path: `/settings`
        },
        {
            label: Localize({ children: "BREADCRUMBS.Label_Counters" }).props.children,
            path: `/settings/counters`
        }
    ]

    useBreadCrumbs(breadcrumbs);


    return (

        <div className="row-grid col-lg-2">

            <div className="col-left">
                <Counter.Forms.Counter summaryStatistic={ summaryStatistic } />
            </div>
            <div className="col-right"></div>
        </div>



    )
};

export default SettingsCountersSubPage;