import CounterForms from "./forms";
import { counterUseMutation } from "./hooks";


const Counter = {
  Forms: CounterForms,
  Hooks: counterUseMutation
}

export default Counter;
