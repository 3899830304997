import React from "react";
import { useHistory } from 'react-router-dom';
import { Dropdown } from 'antd';
import { EllipsisOutlined } from '@ant-design/icons';
import { Localize } from 'components/service';
import HashtagConst from '../hashtag-const';



const HashtagActionField = ({ hashtag }) => {

    const history = useHistory();

    let items = [
        {
            key: 'posts',
            label: <Localize>MENUS_DROP.Label_ViewPosts</Localize>,
            onClick: () => history.push(`${ HashtagConst.basePath }/${ hashtag.slug }`)
        },
        {
            key: 'followers',
            label: <Localize>MENUS_DROP.Label_ViewFollowers</Localize>,
            onClick: () => history.push(`${ HashtagConst.basePath }/${ hashtag.slug }/followers`)
        },
    ]




    return (
        <Dropdown
            // trigger={['click']}
            placement="bottomRight"
            menu={ { items } }
            overlayClassName="drop-more-menu"
            arrow
        >
            <EllipsisOutlined className="btn-more" />
        </Dropdown>
    )
};

export default HashtagActionField;

