// import { useHistory } from 'react-router-dom';
import { useMutation } from "@apollo/client";
import { SELLER_UPDATE, SELLER_CREATE } from "graphql/mutation/seller-gql";
import { GET_SELLER_STATISTICS } from "graphql/query/seller-gql";
import { successNotification, errorNotification } from "components/request-result";


const useMutationSellerCreateUpdate = ({ id, variables }) => {

  const DEALER_CREATE_UPDATE = id ? SELLER_UPDATE : SELLER_CREATE;
  const sellerCreateUpdate = id ? 'sellerStatisticUpdate' : 'sellerStatisticCreate';

  const [ _setSellerCreateUpdate, { loading } ] = useMutation(DEALER_CREATE_UPDATE,
    {
      update(cache, { data }) {

        const {
          [ sellerCreateUpdate ]: {
            label,
            message,
          }
        } = data;


        successNotification({
          title: label,
          description: message
        });
      },
      refetchQueries: [ { query: GET_SELLER_STATISTICS, variables } ],
      onError(error) {
        errorNotification(error);
      }
    }
  );


  return {
    _setSellerCreateUpdate,
    loadingMutationSellerCreateUpdate: loading,
  }
};

export default useMutationSellerCreateUpdate;

