import PostFields from "../fields";
import { Localize } from "components/service";


const attachmentsColumns = [
    {
        title: <Localize>TABLES.Column_Title_FileName</Localize>,
        dataIndex: 'original_name',
        columnIndex: 'ORIGINAL_NAME',
        // sorter: true
    },
    {
        title: <Localize>TABLES.Column_Title_Title</Localize>,
        dataIndex: 'title',
        columnIndex: 'TITLE',
        // sorter: true
    },
    {
        title: <Localize>TABLES.Column_Title_Author</Localize>,
        dataIndex: 'author',
        columnIndex: 'AUTHOR',
        // sorter: true
    },
    // {
    //     title: <Localize>TABLES.Column_Title_Description</Localize>,
    //     dataIndex: 'description',
    //     columnIndex: 'DESCRIPTION',
    //     // sorter: true
    // },
    {
        title: <Localize>TABLES.Column_Title_CreateDate</Localize>,
        dataIndex: 'created_at',
        columnIndex: 'CREATED_AT',
        // sorter: true
    },
    {
        // title: 'action',
        dataIndex: 'action',
        align: 'right',
        width: '4%'
    }
];



const attachmentsData = (attachments, _, variables) => {

    if (!attachments)
    {
        return [];
    }

    return attachments.map(attachment => {

        return {
            key: attachment.id,
            original_name: <PostFields.Attachment attachment={ attachment } dragHandle={ true } />,
            title: attachment.title ?? <strong>&mdash;</strong>,
            author: <PostFields.Info post={ attachment } />,
            // description: attachment.description ?? <strong>&mdash;</strong>,
            created_at: attachment.created_at,
            action: <PostFields.AttachmentAction attachment={ attachment } variables={ variables } />
        };
    });
};


const PostAttachmentsTableHelper = {
    columns: attachmentsColumns,
    data: attachmentsData,
}

export default PostAttachmentsTableHelper;