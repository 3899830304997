import React from "react";
import { Form, Button, DatePicker, InputNumber } from 'antd';
import { sellerUseMutation } from '../../hooks';
import User from "components/user";
import { Localize } from 'components/service';
import { timeFormat } from "utils/helper-functions";
import moment from 'moment';


const SellerEditForm = ({ seller, variables, action }) => {

    const [ form ] = Form.useForm();
    const id = +seller?.id;

    const {
        _setSellerCreateUpdate,
        loadingMutationSellerCreateUpdate
    } = sellerUseMutation.createUpdate({ id, variables });


    return (
        <Form
            key="edit-stand-form"
            layout="vertical"
            form={ form }
            className="model-form edit-stand-form"
            onFinish={ (values) => {

                _setSellerCreateUpdate({
                    variables: {
                        input: {
                            id: id ? id : undefined,
                            ...values,
                            user_id: values.user_id?.value || values.user_id,
                            year: timeFormat(values?.year, 'YYYY')
                        }
                    }
                });

                action();
                form.resetFields();
            } }>


            <User.Forms.Fields.AddUserRadioSelect
                form={ form }
                initialValue={ seller?.user?.id ? { label: seller?.user?.name, value: seller?.user?.id } : null }
                choseBtn={ false }
                withDealer={ true }
                extraLabel="seller_number"
            />

            <Form.Item
                name="year"
                label={ <Localize>FORMS.Input_Label_Year</Localize> }
                initialValue={ id ? moment(seller?.year.toString()) : '' }
                rules={ [ { required: true, message: <Localize>FORMS.Input_Placeholder_Year</Localize> } ] }
            >
                <DatePicker
                    disabled={ id ? true : false }
                    picker="year"
                    placeholder={ Localize({ children: "FORMS.Input_Placeholder_SelectDate" }).props.children }
                    showTime format="YYYY"
                />
            </Form.Item>

            <Form.Item
                name="q1"
                label="Q1"
                initialValue={ seller?.q1 || 0 }
            >
                <InputNumber name="q1" max={ 10000 } controls={ false } style={ { width: '100%' } } />
            </Form.Item>

            <Form.Item
                name="q2"
                label="Q2"
                initialValue={ seller?.q2 || 0 }
            >
                <InputNumber name="q2" max={ 10000 } controls={ false } style={ { width: '100%' } } />
            </Form.Item>

            <Form.Item
                name="q3"
                label="Q3"
                initialValue={ seller?.q3 || 0 }
            >
                <InputNumber name="q3" max={ 10000 } controls={ false } style={ { width: '100%' } } />
            </Form.Item>

            <Form.Item
                name="q4"
                label="Q4"
                initialValue={ seller?.q4 || 0 }
            >
                <InputNumber name="q4" max={ 10000 } controls={ false } style={ { width: '100%' } } />
            </Form.Item>


            <div className="form-btn-holder">

                <Button
                    className="ant-btn ant-btn-secondary bg-light-gray"
                    onClick={ action }
                >
                    <Localize>GLOBAL.Button_Text_Cancel</Localize>
                </Button>

                <Button
                    className="btn-right"
                    type="primary"
                    htmlType="submit"
                    loading={ loadingMutationSellerCreateUpdate }
                >
                    { id ? <Localize>GLOBAL.Button_Text_SaveChanges</Localize> : <Localize>SELLER.Button_Text_AddSeller</Localize> }
                </Button>
            </div>
        </Form>
    );
};

export default SellerEditForm;


