import moment from 'moment';
import { Localize } from 'components/service';


//eslint-disable-next-line
export const linkRegex = /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/;


export function convertToSelect(data) {
    const result = [];
    data?.forEach(item => {
        result.push({
            label: item.node.title,
            value: item.node.id
        });
    });
    return result;
}


export function getLabel(arr1, arr2) {
    const result = []
    arr1?.forEach(item => {
        arr2?.forEach(item2 => {
            if (item === item2.value)
            {
                result.push(item2)
            }
        })
    });

    return result;
}


export function countFilterList(filterList) {
    let count = false;
    for (let key in filterList)
    {
        if (filterList[ key ].column)
        {
            count++;
        }
    }
    return count;
}


export function charSplitToUpper(str, separator = '-', capitalize = true) {
    return str
        ?.split(separator)
        .join(' ')
        .replace(/\w/,
            firstLetter => !capitalize ? firstLetter : firstLetter.toUpperCase()
        );
}


export function normalizeCursorToPage(data) {
    return {
        paginatorInfo: {
            count: data.pageInfo.count,
            currentPage: data.pageInfo.currentPage,
            firstItem: data.pageInfo.startCursor,
            hasMorePages: data.pageInfo.hasNextPage,
            hasPreviousPages: data.pageInfo.hasPreviousPage,
            lastItem: data.pageInfo.endCursor,
            lastPage: data.pageInfo.lastPage,
            total: data.pageInfo.total
        },
        data: data.edges.map(edge => edge.node)
    }
}


export function timeFormat(time, format = 'DD.MM.YYYY HH:mm') {
    return moment(time).format(format);
}


export function getTranslationStatus(status) {

    switch (status)
    {
        case 'disabled':
            return <Localize>GLOBAL.Text_Status_Disabled</Localize>;

        case 'in_progress':
            return <Localize>GLOBAL.Text_Status_InProgress</Localize>;

        case 'completed':
            return <Localize>GLOBAL.Text_Status_Completed</Localize>;

        case 'overdue':
            return <Localize>GLOBAL.Text_Status_Overdue</Localize>;

        default:
            return '';
    }
}


export const initialValFormatted = (date, fieldName) => {
    const val = date?.[ fieldName ]?.value;
    if (val) return val.replace(/[^a-z0-9]/gi, '');
}


export const initialDate = (date, fieldName) => {
    const val = date?.[ fieldName ]?.value;
    if (val) return (moment(val.toString()));
}


export const initialDateBetween = (date, fieldName) => {
    const val = date?.[ fieldName ]?.value;
    if (val) return [ moment(val[ 0 ]), moment(val[ 1 ]) ];
}




const helperFunc = {
    convertToSelect,
    getLabel,
    countFilterList,
    charSplitToUpper,
    linkRegex,
    normalizeCursorToPage,
    timeFormat,
    getTranslationStatus,
    initialValFormatted,
    initialDate,
    initialDateBetween,
};

export default helperFunc;


