import React from 'react';
import { Form, Select } from "antd";
import Icons from "components/icons";
import { Localize } from "components/service";


const UserJobTypeSelect = ({
    disabled = false,
    rules = false,
    initialValue,
    mode = '',
    name = 'job_type',
    label = Localize({ children: "FORMS.Input_Label_JobType" }).props.children
}) => {


    return (
        <Form.Item
            name={ name }
            label={ label }
            initialValue={ initialValue }
            rules={ rules && [ { required: true, message: `${ Localize({ children: "FORM_RULES.Required_Choose" }).props.children } ${ label }` } ] }
        >
            <Select
                showSearch
                suffixIcon={ <Icons.Arrow /> }
                mode={ mode }
                className="select-serch-input"
                disabled={ disabled }
                placeholder={ `${ Localize({ children: "FORMS.Input_Placeholder_Choose" }).props.children } ${ label }` }
                popupClassName="select-serch-dropdown"
                optionFilterProp="value"
            >
                <Select.Option key='notSelected' value="">
                    <Localize>FORMS.Select_OptionLabel_NotSelected</Localize>
                </Select.Option>
                <Select.Option key='CEO' value="CEO">
                    <Localize>FORMS.Select_OptionLabel_CEO</Localize>
                </Select.Option>
                <Select.Option key='CSellingOwner' value="CSellingOwner">
                    <Localize>FORMS.Select_OptionLabel_CSellingOwner</Localize>
                </Select.Option>
                <Select.Option key='Marketing' value="Marketing">
                    <Localize>FORMS.Select_OptionLabel_Marketing</Localize>
                </Select.Option>
                <Select.Option key='BranchManager' value="BranchManager">
                    <Localize>FORMS.Select_OptionLabel_BranchManager</Localize>
                </Select.Option>
                <Select.Option key='SalesManager' value="SalesManager">
                    <Localize>FORMS.Select_OptionLabel_SalesManager</Localize>
                </Select.Option>
                <Select.Option key='CertifiedCarSalesman' value="CertifiedCarSalesman">
                    <Localize>FORMS.Select_OptionLabel_CertifiedCarSalesman</Localize>
                </Select.Option>
                <Select.Option key='Salesperson' value="Salesperson">
                    <Localize>FORMS.Select_OptionLabel_Salesperson</Localize>
                </Select.Option>
                <Select.Option key='ServiceConsultant' value="ServiceConsultant">
                    <Localize>FORMS.Select_OptionLabel_ServiceConsultant</Localize>
                </Select.Option>
                <Select.Option key='OwnerShareholder' value="OwnerShareholder">
                    <Localize>FORMS.Select_OptionLabel_OwnerShareholder</Localize>
                </Select.Option>
                <Select.Option key='CarMaster' value="CarMaster">
                    <Localize>FORMS.Select_OptionLabel_CarMaster</Localize>
                </Select.Option>
            </Select>
        </Form.Item>
    );

}

export default UserJobTypeSelect;