import React from 'react';
import Icon from '@ant-design/icons';


const EditSvg = () => (

	<svg fill="currentColor" width="1em" height="1em" viewBox="0 0 48 48" strokeWidth="1">
		<path d="M44.3,13.2l-7.8-7.8c-0.5-0.5-1.3-0.5-1.8,0L9.2,30.9c-0.2,0.2-0.3,0.4-0.3,0.6l-2.2,9.9c-0.1,0.4,0,0.8,0.3,1.2
	c0.2,0.2,0.6,0.4,0.9,0.4c0.1,0,0.2,0,0.3,0l10-2.1c0.2,0,0.5-0.2,0.6-0.3l25.5-25.5c0.2-0.2,0.4-0.6,0.4-0.9S44.5,13.4,44.3,13.2z
	 M18.7,37l-6-6l18.5-18.5l6,6L18.7,37z M11.1,32.9l5.6,5.6L9.5,40L11.1,32.9z M39,16.7l-6-6L35.6,8l6,6L39,16.7z"/>
	</svg>

);

const EditIcon = () => {
	return <Icon component={ EditSvg } />;
};


export default EditIcon;