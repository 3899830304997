import HashtagPostsSubPage from "./hashtag-posts-sub-page";
import HashtagFollowersSubPage from "./hashtag-followers-sub-page";


const SubPage = {
    Posts: HashtagPostsSubPage,
    Followers: HashtagFollowersSubPage,
};

export default SubPage;
