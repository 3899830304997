import React from "react";
import { Form, Input, InputNumber, Button } from 'antd';
import { dealerUseMutation } from '../../hooks';
import { Localize, EntityRemoveButton } from "components/service";


const DealerEditForm = ({ dealer, userID }) => {

    const [ form ] = Form.useForm();

    const {
        _setDealerCreateUpdate,
        loadingMutationDealerCreateUpdate: loading,
    } = dealerUseMutation.createUpdate(dealer?.id);

    const {
        _setMutationDealerDelete,
        loadingMutationDealerDelete,
    } = dealerUseMutation.delete(dealer?.id);


    return (

        <Form
            disabled={ userID ?? false }
            key="edit-dealer-form"
            layout="vertical"
            form={ form }
            className="model-form edit-dealer-form"
            onFinish={ (values) => {

                _setDealerCreateUpdate({
                    variables: {
                        input: {
                            id: dealer?.id ? +dealer.id : undefined,
                            ...values,
                        }
                    }
                })
            } }>

            <div className="row-grid grid-gap-10 row-gap-0 col-md-2">
                <Form.Item
                    name="company_name"
                    label={ <Localize>FORMS.Input_Label_CompanyName</Localize> }
                    initialValue={ dealer?.company_name }
                    rules={ [
                        { required: true, message: <Localize>FORM_RULES.Required_CompanyName</Localize> }
                    ] }
                >
                    <Input name="company_name" />
                </Form.Item>

                <Form.Item
                    name="dealer_number"
                    label={ <Localize>FORMS.Input_Label_DealerNumber</Localize> }
                    initialValue={ dealer?.dealer_number }
                    rules={ [
                        { required: true, message: <Localize>FORM_RULES.Required_DealerNumber</Localize> }
                    ] }
                >
                    <InputNumber name="dealer_number" min={ 3 } max={ 1000000 } controls={ false } style={ { width: '100%' } } />
                </Form.Item>

                <Form.Item
                    name="city"
                    label={ <Localize>FORMS.Input_Label_City</Localize> }
                    initialValue={ dealer?.city }
                >
                    <Input name="city" />
                </Form.Item>

                <Form.Item
                    name="zip_code"
                    label={ <Localize>FORMS.Input_Label_ZipCode</Localize> }
                    initialValue={ dealer?.zip_code }
                >
                    <Input name="zip_code" />
                </Form.Item>
            </div>

            <Form.Item
                name="street"
                label={ <Localize>FORMS.Input_Label_Street</Localize> }
                initialValue={ dealer?.street }
            >
                <Input name="street" />
            </Form.Item>

            <div className="row-grid grid-gap-10 row-gap-0 col-md-2">
                <Form.Item
                    name="region"
                    label={ <Localize>FORMS.Input_Label_Region</Localize> }
                    initialValue={ dealer?.region }
                >
                    <Input name="region" />
                </Form.Item>

                <Form.Item
                    name="district"
                    label={ <Localize>FORMS.Input_Label_District</Localize> }
                    initialValue={ dealer?.district }
                >
                    <Input name="district" />
                </Form.Item>
            </div>

            { !userID && <div className="form-btn-holder">
                { dealer?.id &&
                    <EntityRemoveButton
                        modalButton={
                            <Button><Localize>DEALER.Button_Text_Delete</Localize> </Button>
                        }
                        nameEntity={ Localize({ children: "ENTITY.Modal_Title_Dealer" }).props.children }
                        dataNameEntity={ dealer?.company_name }
                        loading={ loadingMutationDealerDelete }
                        deleteMutation={ _setMutationDealerDelete }
                        variables={ {
                            id: dealer?.id,
                        } }
                    /> }

                <Button
                    className="btn-right"
                    type="primary"
                    htmlType="submit"
                    loading={ loading }
                >
                    { dealer?.id ? <Localize>GLOBAL.Button_Text_SaveChanges</Localize> : <Localize>DEALER.Button_Text_Create</Localize> }
                </Button>
            </div> }
        </Form>
    );
};

export default DealerEditForm;


