import React from "react";
import { Form, Input, Button, InputNumber, Switch, DatePicker } from 'antd';
import { userUseMutation } from '../../hooks';
import UserFormItems from '../fields'
import Dealer from 'components/dealer';
import { useMe } from "components/use-hooks";
import { Localize, EntityRemoveButton } from "components/service";
import { timeFormat } from "utils/helper-functions";
import moment from "moment";



const UserEditForm = ({ user }) => {

    const { me } = useMe();

    const [ form ] = Form.useForm();

    const {
        _setUserCreateUpdate,
        loadingMutationUserCreateUpdate: loading,
    } = userUseMutation.createUpdate(user?.id);

    const {
        _setMutationUserDelete,
        loadingMutationUserDelete,
    } = userUseMutation.delete(user?.id);


    return (

        <Form
            key="edit-user-form"
            layout="vertical"
            form={ form }
            className="model-form edit-user-form"
            onFinish={ (values) => {

                _setUserCreateUpdate({
                    variables: {
                        input: {
                            id: user?.id ? +user.id : undefined,
                            ...values,
                            status: values.status ? 'active' : 'block',
                            dealer_id: values.dealer_id?.value || values.dealer_id,
                            seller_number: values.seller_number ?? undefined,
                            job_type: values.job_type ?? undefined,
                            phone: values.phone ?? undefined,
                            date_birth_at: values.date_birth_at ? timeFormat(values?.date_birth_at, 'YYYY-MM-DD') : undefined,
                            date_entrance_at: values.date_entrance_at ? timeFormat(values?.date_entrance_at, 'YYYY-MM-DD') : undefined,
                            about: values.about ?? undefined,
                        }
                    }
                })
            } }>

            <div className="row-grid grid-gap-10 row-gap-0 col-md-2">

                <UserFormItems.SalutationSelect initialValue={ user?.salutation } />

                <Form.Item
                    name="name"
                    label={ <Localize>FORMS.Input_Label_Name</Localize> }
                    initialValue={ user?.name }
                    rules={ [
                        { required: true, message: <Localize>FORM_RULES.Required_Name</Localize> }
                    ] }
                >
                    <Input name="name" />
                </Form.Item>

                <Form.Item
                    name="date_birth_at"
                    label={ <Localize>FORMS.Input_Label_DateBirth</Localize> }
                    initialValue={ user?.date_birth_at && moment(user?.date_birth_at) }
                >
                    <DatePicker
                        placeholder={ Localize({ children: "FORMS.Input_Placeholder_SelectDate" }).props.children }
                        showTime format="DD.MM.YYYY"
                    />
                </Form.Item>

                <Form.Item
                    name="date_entrance_at"
                    label={ <Localize>FORMS.Input_Label_DateEntrance</Localize> }
                    initialValue={ user?.date_entrance_at && moment(user?.date_entrance_at) }
                >
                    <DatePicker
                        placeholder={ Localize({ children: "FORMS.Input_Placeholder_SelectDate" }).props.children }
                        showTime format="DD.MM.YYYY"
                    />
                </Form.Item>

            </div>


            <UserFormItems.JobTypeSelect initialValue={ user?.job_type } />

            <Form.Item
                name="status"
                label={ <Localize>FORMS.Input_Label_Status</Localize> }
                initialValue={ (user?.status === 'inactive' || user?.status === 'block') ? false : true }
                valuePropName={ (user?.status === 'inactive' || user?.status === 'block') ? false : true }
                className='form-item-switch'
            >
                <Switch disabled={ me?.id === user?.id } defaultChecked={ (user?.status === 'inactive' || user?.status === 'block') ? false : true } />
            </Form.Item>

            <div className="row-grid grid-gap-10 row-gap-0 col-md-2">

                <UserFormItems.GenderSelect initialValue={ user?.gender } />

                <Form.Item
                    name="nickname"
                    label={ <Localize>FORMS.Input_Label_UserName</Localize> }
                    initialValue={ user?.nickname }
                    rules={ [
                        { required: true, message: <Localize>FORM_RULES.Required_UserName</Localize> }
                    ] }
                >
                    <Input name="nickname" />
                </Form.Item>

                <Form.Item
                    name="phone"
                    label={ <Localize>FORMS.Input_Label_Phone</Localize> }
                    initialValue={ user?.phone }
                >
                    <Input name="phone" />
                </Form.Item>

                <Form.Item
                    name="email"
                    label={ <Localize>FORMS.Input_Label_Email</Localize> }
                    initialValue={ user?.email }
                    rules={ [ { type: 'email', required: true, message: <Localize>FORM_RULES.Required_Email</Localize> } ] }
                >
                    <Input name="email" />
                </Form.Item>

                <UserFormItems.LanguageSelect initialValue={ user?.locale } />

            </div>

            <Dealer.Forms.Fields.Select
                label={ <Localize>FORMS.Input_Label_Dealer</Localize> }
                form={ form }
                initialValue={ user?.dealer_id ? { label: `${ user?.dealer?.company_name } ${ user?.dealer?.dealer_number }`, value: user?.dealer_id } : null } />

            <Form.Item
                name="seller_number"
                label={ <Localize>FORMS.Input_Label_SellerNumber</Localize> }
                initialValue={ user?.seller_number }
            // rules={ [
            //     { required: true, message: <Localize>FORM_RULES.Required_SellerNumber</Localize> }
            // ] }
            >
                <InputNumber name="seller_number" min={ 3 } max={ 1000000 } controls={ false } style={ { width: '100%' } } />
            </Form.Item>


            <UserFormItems.RoleSelect form={ form } initialValue={ user?.role } />

            <Form.Item
                name="about"
                label={ <Localize>FORMS.Input_Label_Description</Localize> }
                initialValue={ user?.about }
            >
                <Input.TextArea
                    maxLength={ 300 }
                    placeholder={ Localize({ children: "FORMS.Input_Placeholder_ShortDescription" }).props.children }
                    autoSize={ { minRows: 3, maxRows: 5 } }
                // showCount={
                //     { formatter: ({ count, maxLength }) => maxLength - count }
                // }
                />
            </Form.Item>

            <div className="form-btn-holder">
                { user?.id &&
                    <EntityRemoveButton
                        modalButton={
                            <Button><Localize>USER.Button_Text_Delete</Localize> </Button>
                        }
                        nameEntity={ Localize({ children: "ENTITY.Modal_Title_User" }).props.children }
                        dataNameEntity={ user?.name }
                        loading={ loadingMutationUserDelete }
                        deleteMutation={ _setMutationUserDelete }
                        variables={ {
                            id: user?.id,
                        } }
                    /> }

                <Button
                    className="btn-right"
                    type="primary"
                    htmlType="submit"
                    loading={ loading }
                >
                    { user?.id ? <Localize>GLOBAL.Button_Text_SaveChanges</Localize> : <Localize>USER.Button_Text_Create</Localize> }
                </Button>
            </div>
        </Form>
    );
};

export default UserEditForm;


