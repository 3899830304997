import { useHistory } from 'react-router-dom';
import { useMutation } from "@apollo/client";
import { POST_DELETE } from "graphql/mutation/post-gql";
import PostConst from '../post-const';
import { successNotification, errorNotification } from "components/request-result";


const useMutationPostDelete = (id) => {


  const history = useHistory();

  const [ _setMutationPostDelete, { loading } ] = useMutation(POST_DELETE,
    {
      update(cache, { data }) {

        const {
          postDelete: {
            label,
            message
          }
        } = data;

        history.push(PostConst.basePath);

        cache.evict({
          id: cache.identify({ id, __typename: "Post" })
        });
        cache.gc();

        successNotification({
          title: label.toUpperCase(),
          description: message
        });
      },
      onError(error) {
        errorNotification(error);
      }
    });

  return {
    _setMutationPostDelete,
    loadingMutationPostDelete: loading,
  }
};

export default useMutationPostDelete;