import React from "react";
import { useRouteMatch } from "react-router-dom";

import { GET_FOLLOWERS } from 'graphql/query/followers-gql';

import Hashtag from 'components/hashtag';

import { useBreadCrumbs } from "components/use-hooks";
import { Tables } from "components/layout";
import { Localize } from "components/service";



const HashtagFollowersSubPage = ({ hashtag }) => {

    const basePath = Hashtag.Const.basePath;
    const tableHelper = Hashtag.Helpers.TableFollowers();


    const breadcrumbs = [
        {
            label: Localize({ children: "BREADCRUMBS.Label_Hashtags" }).props.children,
            path: `${ basePath }`
        },
        {
            label: hashtag?.slug ?? <strong>the hashtag has no title</strong>,
            path: `${ basePath }/${ hashtag?.slug }`
        },
        {
            label: Localize({ children: "BREADCRUMBS.Label_Followers" }).props.children,
            path: `${ basePath }/${ hashtag?.slug }/followers`
        },
    ]

    useBreadCrumbs(breadcrumbs);

    const routeUrl = `${ basePath }/${ hashtag?.slug }/followers`;
    let match = useRouteMatch(`${ routeUrl }/page/:pageNum`);

    const objOrderBy = [ { column: "ID", order: "DESC" } ];


    return (

        <Tables.Main
            model="followersBy"
            query={ GET_FOLLOWERS }
            varSet={ { perPage: 30, id: hashtag?.id, model: 'Hashtag' } }
            routeUrl={ routeUrl }
            currentPage={ match ? parseInt(match.params.pageNum) : 1 }
            objOrderBy={ objOrderBy }
            tableHelper={ tableHelper }
        >
            <div className="table-action-bar">

                <div className="col"> </div>

                <div className="col"> </div>
            </div>
        </Tables.Main>
    )
};

export default HashtagFollowersSubPage;