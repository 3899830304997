import PostFields from "./fields";
import PostForms from "./forms";
import PostConst from "./post-const";
import PostHelpers from "./helpers";
import PostFilters from './filters';
import { postUseMutation } from "./hooks";


const Post = {
  Filters: PostFilters,
  Fields: PostFields,
  Forms: PostForms,
  Const: PostConst,
  Helpers: PostHelpers,
  Hooks: postUseMutation
}

export default Post;
