import React from "react";
import { Link } from 'react-router-dom';
import User from 'components/user';



const PostInfoField = ({ post }) => {

    const { id, name, nickname } = post?.author;

    return (
        <>
            <Link to={ `${ User.Const.basePath }/${ id }` }> { `${ name }` } <br /> { `${ nickname }` }</Link>
        </>
    )
}


export default PostInfoField;