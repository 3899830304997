import React from "react";
import { useMutation, useQuery } from "@apollo/client";
import { Form, Button } from 'antd';
import UserFormItems from "../fields";
import { errorNotification, successNotification } from "components/request-result";
import { Localize } from 'components/service';


const UserAddForm = ({
    action,
    propsObj: {
        query,
        queryVar,
        mutation,
        mutationName,
        model,
        modelID
    } }) => {


    const { data: dataQuery, loading: loadingQuery } = useQuery(query, {
        variables: { ...queryVar },
        fetchPolicy: 'cache-and-network',
        nextFetchPolicy: 'cache-first'
    });

    const [ form ] = Form.useForm();
    const [ _setMutation, { loading: loadingMutation } ] = useMutation(mutation,

        {
            refetchQueries: [ query ],

            update(cache, { data }) {

                const {
                    [ mutationName ]: {
                        label,
                        message
                    }
                } = data;

                successNotification({
                    title: label,
                    description: message
                });

            },
            onError(error) {
                errorNotification(error);
            }
        }
    );

    return (
        <Form
            key="add-user-form"
            layout="vertical"
            className="add-user-form"
            form={ form }
            onFinish={ (values) => {
                _setMutation({
                    variables: {
                        [ model ]: modelID,
                        ...values
                    }
                });

                action();
            } }
        >

            <UserFormItems.AddUserСheckboxSelect
                form={ form }
                initialValue={ dataQuery?.users?.data }
                loadingQuery={ loadingQuery }
            />

            <div className="form-btn-holder">

                <Button
                    className="ant-btn ant-btn-secondary bg-light-gray"
                    onClick={ action }
                >
                    <Localize>GLOBAL.Button_Text_Cancel</Localize>
                </Button>

                <Button
                    className="btn-right"
                    type="primary"
                    htmlType="submit"
                    loading={ loadingMutation }
                >
                    <Localize>GLOBAL.Button_Text_Confirm</Localize>
                </Button>
            </div>

        </Form>
    );

};

export default UserAddForm;