import React from "react";
import { Link } from "react-router-dom";
import Linkify from "react-linkify";


const parseSigns = {
    '#': {
        type: "hashtag",
        rule: /\B#[A-Za-z0-9\-_]+(?:|$)/g,
        route: "/hashtags/"
    },
    '@': {
        type: "user",
        rule: /\B@[A-Za-z0\-9-_.]+(?:|$)\b/g,
        route: "/users/"
    },
    '^': {
        type: "collection",
        rule: /\B\^[A-Za-z0-9\-_.]+(?:|$)/g,
        route: "/collections/"
    },
    '~': {
        type: "team",
        rule: /\B~[A-Za-z0-9\-_.]+(?:|$)/g,
        route: "/team/"
    }
};

//const mainRegExp = /(\B[\^|@|#|＃][^\s,?!:]+)/g;
const mainRegExp = /(\B[\^|@|#|＃][^\s,?!:]+)+(?:|$)\b/g;

const parseString = (props) => {

    const { strToParse, searchSigns, onClickAction } = props;

    return strToParse.split(mainRegExp).map((item) => {

        if (searchSigns.indexOf(item[ 0 ]) !== -1)
        {

            const {
                route,
                rule
            } = parseSigns[ item[ 0 ] ];

            if (item.match(rule) !== null)
            {
                let slug = item.slice(1);

                return (
                    <Link
                        className="inner-link"
                        key={ slug }
                        to={ `${ route }${ slug }` }
                        onClick={ onClickAction }
                    >
                        { item }
                    </Link>
                );
            }
        }

        return item !== "" ?
            item : null;

    });

};

const componentDecorator = (href, text, key) => (
    <a href={ href } key={ key } target="_blank" className="outer-link" rel="noopener noreferrer">
        { text }
    </a>
);

const LinkedMention = (props) => {

    const {
        searchSigns = [],
        children,
        onClick
    } = props;

    let items;

    if (typeof children === 'string')
    {

        items = parseString({
            strToParse: children,
            searchSigns,
            onClick
        });

    } else
    {

        items = children && children.map((str) => {
            return parseString({
                strToParse: str,
                searchSigns,
                onClick
            });
        });

    }
    return (
        <Linkify componentDecorator={ componentDecorator }>
            { items }
        </Linkify>
    );
};

export default LinkedMention;