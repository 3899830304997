import SellerFields from "./fields";
import SellerForms from "./forms";
import SellerConst from "./seller-const";
import SellerHelpers from "./helpers";
import SellerFilters from './filters';
import { sellerUseMutation } from "./hooks";


const Seller = {
  Filters: SellerFilters,
  Fields: SellerFields,
  Forms: SellerForms,
  Const: SellerConst,
  Helpers: SellerHelpers,
  Hooks: sellerUseMutation
}

export default Seller;
