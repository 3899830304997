import { Link } from "react-router-dom";
import { FileImageOutlined } from "@ant-design/icons";
import { Avatar } from "antd";
import Post from "components/post";


const PostTitleField = ({ post }) => {


    return (

        <Link to={ `${ Post.Const.basePath }/${ post.id }` } className="d-flex align-items-center">
            <div style={ { minWidth: '55px' } }>
                <Avatar shape="square" size="large" icon={ post?.media[ 0 ]?.thumbnail ? <img src={ post?.media[ 0 ]?.thumbnail } alt='avatar' /> : <FileImageOutlined /> } />
            </div>
            <span>{ post?.type === 'news' ? <strong style={ { color: '#E60013' } }>NEWS: </strong> : '' }{ post?.title ?? <strong>&mdash;</strong> }</span>
        </Link>
    );
};

export default PostTitleField;
