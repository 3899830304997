import EmailRuleForms from "./forms";
import { emailUseMutation } from "./hooks";


const Email = {
  Forms: EmailRuleForms,
  Hooks: emailUseMutation
}

export default Email;
