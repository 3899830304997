import { gql } from '@apollo/client';




export const CHANGE_STATUS_SUMMARY_STATISTIC = gql`
    mutation SummaryStatisticChangeStatus($key: SummaryStatisticKeyEnum, $value: Boolean) {
      summaryStatisticChangeStatus(key: $key, value: $value) {
        id
        key
        value
        active
        created_at
        updated_at
      }
    }
`;

export const UPDATE_SUMMARY_STATISTIC = gql`
    mutation SummaryStatisticUpdate($key: SummaryStatisticKeyEnum) {
      summaryStatisticUpdate(key: $key) {
        id
        key
        value
        active
        created_at
        updated_at
      }
    }
`;