import { gql } from '@apollo/client';


export const DEALER_CREATE = gql`
    mutation  DealerCreate ($input: DealerFieldsInput!) {
        dealerCreate( input: $input ) {
        label
        message
        dealer {
            id
            company_name
            dealer_number
            street
            zip_code
            city
            region
            district
            created_at
            updated_at
           }
        }
    }
`;


export const DEALER_UPDATE = gql`
    mutation  DealerUpdate ($input: DealerFieldsInput!) {
        dealerUpdate( input: $input ) {
        label
        message
        dealer {
            id
            company_name
            dealer_number
            street
            zip_code
            city
            region
            district
            created_at
            updated_at
           }
        }
    }
`;

export const DEALER_SYNC_USERS = gql`
    mutation DealerSyncToUsers($user_id: [ID]!, $dealer_id: ID!) {
        dealerSyncToUsers(
        user_id: $user_id
        dealer_id: $dealer_id
        ) {
        label
        message
        }
    }
`;


export const DEALER_REMOVE_USER = gql`
    mutation DealerRemoveUser (
        $user_id: ID!
        $dealer_id: ID!
    ) {
        dealerRemoveUser (
            user_id: $user_id
            dealer_id: $dealer_id
        ){
            label
            message
        }
    }
`;


export const DEALER_DELETE = gql`
    mutation DealerDelete($id: ID!){
        dealerDelete(id: $id){
            label
            message
        }
    }
`;
