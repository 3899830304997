import SettingsEmailRulesSubPage from "./settings-email-rules-sub-page";
import SettingsCountersSubPage from "./settings-counters-sub-page";


const SubPage = {
    EmailRules: SettingsEmailRulesSubPage,
    Counters: SettingsCountersSubPage
};

export default SubPage;
