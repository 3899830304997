import Dealer from "components/dealer";
import { useBreadCrumbs } from "components/use-hooks";
import { Localize } from "components/service";

const dealerBasePath = Dealer.Const.basePath;


const DealerOverviewSubPage = ({ dealer, history }) => {

    const breadcrumbs = [
        {
            label: Localize({ children: "BREADCRUMBS.Label_Dealers" }).props.children,
            path: `${ dealerBasePath }`
        },
        {
            label: (dealer && dealer?.company_name) || Localize({ children: "BREADCRUMBS.Label_Create" }).props.children,
            path: (dealer && `${ dealerBasePath }/${ dealer?.id }`) || `${ dealerBasePath }/create`
        }
    ]

    useBreadCrumbs(breadcrumbs);

    return (
        <div className="row-grid col-lg-2">

            <div className="col-left">
                <Dealer.Forms.Edit dealer={ dealer } history={ history } />
            </div>
            <div className="col-right"></div>
        </div>
    )
}

export default DealerOverviewSubPage;