import { useMutation } from "@apollo/client";
import { CHANGE_STATUS_SUMMARY_STATISTIC } from "graphql/mutation/statistic-gql";
import { successNotification, errorNotification } from "components/request-result";



const useMutationChangeStatus = (summaryLabels) => {


  const [ _setMutationChangeStatus, { loading } ] = useMutation(CHANGE_STATUS_SUMMARY_STATISTIC,
    {
      update(cache, { data }) {

        const {
          summaryStatisticChangeStatus: {
            key,
            active
          }
        } = data;


        successNotification({
          title: summaryLabels[ key ],
          description: active ? 'SWITCHED ON' : 'SWITCHED OFF'
        });
      },
      onError(error) {
        errorNotification(error);
      }
    }
  );


  return {
    _setMutationChangeStatus,
    loadingMutationChangeStatus: loading,
  }
};

export default useMutationChangeStatus;

