import React from "react";
import { Link, useHistory, useRouteMatch } from 'react-router-dom';
import { Skeleton } from 'antd';
import { Pages } from 'components/pages';

import './page-wrap-default.scss';


const PageWrapDefault = ({
    avatar = false,
    pageHeaderContent = false,
    pageNavbar = false,
    loading = false,
    headerRightBar = false,
    headerNavRightBar = false,
    dataExist = false,
    pageContentVisible = true,
    staticPath = null,
    title = '',
    className = '',
    tabsIndent = '',
    heightAuto = '',
    children }) => {


    const { path } = useRouteMatch();

    const pathname = useHistory().location.pathname;

    const findTab = pageNavbar && findMatches(pathname, pageNavbar?.map(el => el.path));

    const activeRoute = pathname.split('/').pop();

    const activeTab = (item) => findTab === item ? 'active' : '';

    const activeOverviewTab = pathname.split('/').find(el => el === 'page') !== 'page' && !!activeRoute.match(/\d+$/) ? 'active' : '';

    const overview = pageNavbar[ 0 ]?.path === 'overview';


    function findMatches(path, arr) {
        let result = '';
        for (let i = 0; i < arr.length; i++)
        {
            if (path?.includes(arr[ i ]))
            {
                result = arr[ i ];
            }
        }
        return result;
    }


    return (
        <div className={ `page ${ className }` }>

            <div className="page-header">
                <div className="page-header-top-bar">

                    { avatar }

                    <h1>{ !loading ? title : <Skeleton title /> }</h1>
                    { headerRightBar && <div className="right-bar">{ headerRightBar }</div> }
                </div>

                { pageHeaderContent &&

                    <div className="page-header-content">
                        { pageHeaderContent }
                    </div>
                }


                { pageNavbar.length &&

                    <nav className="page-navbar">
                        <ul className='page-links'>
                            { overview &&
                                <li
                                    key='default'
                                    className={ activeOverviewTab }>
                                    <Link to={ `${ staticPath ?? path }` }>{ pageNavbar[ 0 ]?.label }</Link>
                                </li>

                            }
                            { pageNavbar?.filter(item => item.path !== 'overview').map((item, index) => {

                                return (
                                    <li
                                        key={ index }
                                        className={ activeTab(item.path) }
                                    >
                                        <Link to={ `${ staticPath ?? path }${ `/${ item.path }` }` }>
                                            { item?.label }
                                        </Link>
                                    </li>
                                );
                            }) }

                        </ul>
                        { headerNavRightBar && <div className="right-bar">{ headerNavRightBar }</div> }
                    </nav>
                }
            </div>

            { pageContentVisible &&

                <div className={ `page-content ${ tabsIndent && 'tabs-indent' } ${ heightAuto && 'height-auto' }` }>
                    { dataExist ?
                        children :
                        <>{ !loading && <Pages.Page404 /> }</>
                    }
                    { loading && <Skeleton active={ true } paragraph={ { rows: 15 } } /> }

                </div>
            }

        </div>
    );
}

export default PageWrapDefault;

