import React from "react";
import { Switch, Route, useRouteMatch } from 'react-router-dom';
import SubPage from 'components/pages/collection/sub-pages';


const СollectionRoute = ({ collection, history }) => {

    const { path } = useRouteMatch();

    return (
        <Switch>
            <Route path={ `${ path }/posts` } >
                <SubPage.Posts
                    collection={ collection }
                    history={ history } />
            </Route>

            <Route path={ `${ path }/posts/page/:pageNum` } exact>
                <SubPage.Posts
                    collection={ collection }
                    history={ history } />
            </Route>

            <Route path={ `${ path }/followers` } >
                <SubPage.Followers
                    collection={ collection }
                    history={ history } />
            </Route>

            <Route path={ `${ path }/followers/page/:pageNum` } exact>
                <SubPage.Followers
                    collection={ collection }
                    history={ history } />
            </Route>

            <Route path={ `${ path }` }>
                <SubPage.OverView
                    collection={ collection }
                    history={ history } />
            </Route>
        </Switch>
    );
};

export default СollectionRoute;