import React from "react";
import { Form, DatePicker, Button } from "antd";
import CollectionFormItems from "../fields";
import User from 'components/user';
import { Localize } from "components/service";
import { initialValFormatted, initialDateBetween } from "utils/helper-functions";


const { RangePicker } = DatePicker;

const CollectionFiltersForm = ({ action, collectionsFilters, setCount, setFilters, setFilterList, filterList, gqlBuilderWhere, form }) => {


    const onChangeSetFilter = (e, name, value) => {

        if (e)
        {
            name = e.target.name
            value = e.target.value
        }

        value.toString().length > 0 ?

            setFilterList({
                ...filterList,
                [ name ]: {
                    column: name,
                    operator: "EQ",
                    value: value
                }
            }) : setFilterList({ ...filterList, [ name ]: {} });
    }


    return (

        <div className="ant-form ant-form-vertical filters-form">

            <User.Forms.Fields.AddUserRadioSelect
                initialValue={ initialValFormatted(collectionsFilters, 'AUTHOR_ID') }
                name="AUTHOR_ID"
                label={ <Localize>FORMS.Input_Label_Author</Localize> }
                form={ form }
                choseBtn={ false }
                onChangeSetFilter={ onChangeSetFilter }
                modalButtonText={ <Localize>MODAL.Button_Text_NotSelected</Localize> }
            />

            <CollectionFormItems.Status
                initialValue={ initialValFormatted(collectionsFilters, 'STATUS') }
                name="STATUS"
                form={ form }
                onChangeSetFilter={ onChangeSetFilter }
                placeholder={ Localize({ children: "FORMS.Input_Placeholder_NotSelected" }).props.children }
            />

            <Form.Item
                initialValue={ initialDateBetween(collectionsFilters, 'CREATED_AT') }
                name="CREATED_AT"
                label={ <Localize>FORMS.Input_Label_CreatedAt</Localize> }
            >

                <RangePicker
                    separator="&ndash;"
                    placeholder={ [ Localize({ children: "FORMS.Input_Placeholder_StartDate" }).props.children, Localize({ children: "FORMS.Input_Placeholder_EndDate" }).props.children ] }
                    onChange={ (_, dateSrings) =>
                        dateSrings[ 0 ] !== '' ?
                            setFilterList({
                                ...filterList,
                                CREATED_AT: {
                                    column: "CREATED_AT",
                                    operator: "BETWEEN",
                                    value: dateSrings
                                }
                            }) : setFilterList({
                                ...filterList,
                                CREATED_AT: {}
                            }) } />
            </Form.Item>

            <div className="form-btn-holder">
                <Button
                    type="primary"
                    htmlType="submit"
                    onClick={ () => {
                        setFilters(gqlBuilderWhere({ ...collectionsFilters, ...filterList }));
                        setCount({ ...collectionsFilters, ...filterList });
                        action();
                    } }
                >
                    <Localize>FORMS.Button_Text_ApplyFilters</Localize>
                </Button>
                <Button
                    className="ant-btn-secondary bg-light-gray btn-right"
                    htmlType="submit"
                    onClick={ () => {
                        setFilterList({});
                        setCount({});
                        setFilters(gqlBuilderWhere({}));
                        localStorage.removeItem("collectionsFilters");
                    } }
                >
                    <Localize>FORMS.Button_Text_ResetFilters</Localize>
                </Button>
            </div>
        </div>
    )
}

export default CollectionFiltersForm;