import UsersTableHelper from './users-table-helper';
import UsersQuestionTableHelper from './users-question-table-helper';
import usersFuncHelper from './users-functions-helper';


const UserHelpers = {
    TableMain: UsersTableHelper,
    TableQuestion: UsersQuestionTableHelper,
    Functions: usersFuncHelper,
}

export default UserHelpers;