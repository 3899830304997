import React from "react";
import { useHistory } from "react-router-dom";
import { Dropdown } from 'antd';
import { EllipsisOutlined } from '@ant-design/icons';
import { postUseMutation } from '../hooks';
import { EntityRemoveButton, Localize } from 'components/service';
import PostConst from '../post-const';
import Icons from 'components/icons';



const PostActionField = ({ post, actionFieldsHide }) => {

    const history = useHistory();


    const {
        _setMutationPostDelete,
        loadingMutationPostDelete,
    } = postUseMutation.delete(post.id);



    let items = [
        {
            key: 'edit',
            label: <Localize>MENUS_DROP.Label_View</Localize>,
            icon: <Icons.Edit />,
            // disabled: true,
            onClick: () => history.push(`${ PostConst.basePath }/${ post.id }`)
        },
        {
            key: 'delete',
            disabled: false,
            icon:
                <> { false ?
                    <span style={ { display: 'flex', alignItems: 'center' } } ><Icons.Delete /> <Localize>MENUS_DROP.Label_Delete</Localize></span> :

                    <EntityRemoveButton
                        modalButton={
                            <span style={ { display: 'flex', alignItems: 'center' } } ><Icons.Delete /> <Localize>MENUS_DROP.Label_Delete</Localize></span>
                        }
                        nameEntity={ Localize({ children: "ENTITY.Modal_Title_Post" }).props.children }
                        dataNameEntity={ post.title }
                        loading={ loadingMutationPostDelete }
                        deleteMutation={ _setMutationPostDelete }
                        variables={ {
                            id: post.id,
                        } } /> }
                </>
        } ]


    items = items.filter(({ key }) => !actionFieldsHide?.includes(key))


    return (
        <Dropdown
            // trigger={['click']}
            placement="bottomRight"
            menu={ { items } }
            overlayClassName="drop-more-menu"
            arrow
        >
            <EllipsisOutlined className="btn-more" />
        </Dropdown>
    )
};

export default PostActionField;

