
import './elements.scss';


const AnimationFolder = () => {

  return (

    <div className="folder">
      <span className="folder-tab"></span>
      <div className="folder-icn">
        <div className="downloading">
          <span className="custom-arrow"></span>
        </div>
        <div className="bar"></div>
      </div>
    </div>

  );
}

export default AnimationFolder;


