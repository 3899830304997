import React from "react";

import Post from 'components/post';

import { useBreadCrumbs } from "components/use-hooks";
import { Localize } from "components/service";
import Icons from "components/icons";

const postBasePath = Post.Const.basePath;


const PostCommentsSubPage = ({ post }) => {

    const breadcrumbs = [
        {
            label: Localize({ children: "BREADCRUMBS.Label_Posts" }).props.children,
            path: `${ postBasePath }`
        },
        {
            label: post?.title ?? <strong>the post has no title</strong>,
            path: `${ postBasePath }/${ post?.id }`
        },

        {
            label: Localize({ children: "BREADCRUMBS.Label_Comments" }).props.children,
            path: `${ postBasePath }/${ post?.id }/comments`
        },
    ]

    useBreadCrumbs(breadcrumbs);


    return (

       post?.comments?.edges?.length > 0 ?

            <div className="row-grid col-lg-2">
                <ul className="comments-list">
                    <Post.Fields.Comment postID={ post?.id } comments={ post.comments } />
                </ul>
            </div>
            :
            <div className="no-data-block">
                <div>
                    <i className="icon"> <Icons.NoContent type='userFollowing' /></i>
                    <span className="text">No comments have yet been created for this post.</span>
                </div>
            </div>
    )
};

export default PostCommentsSubPage;