import { gql } from '@apollo/client';


export const attachmentsFieldsFull = gql`
    fragment attachmentsFieldsFull on Attachment {
        id
        name
        title
        display_url
        extension
        order
        description
        created_at
        author {
            id
            name
            nickname
        }
    }
`;

export const attachmentsFragmentFull = gql`
    fragment attachmentsFragmentFull on Post {
        attachments{
            ...attachmentsFieldsFull
        }
    }
    ${ attachmentsFieldsFull }
`;