import React, { useState } from "react";
import { Waypoint } from 'react-waypoint'
import { Form, Skeleton, Checkbox } from "antd";
import { useQuery } from "@apollo/client";

import { GET_USERS_CURSOR } from "graphql/query/user-gql";

import MultiSelect from 'components/layout/form-elements/multi-select';
import { useDebounce } from "components/use-hooks";
import { Loader } from "components/request-result";
import { Localize, cursorPagination } from "components/service";


const UserAddСheckboxSelect = ({
    form,
    initialValue,
    model = "usersCursor",
    name = 'user_id',
    loadingQuery
}) => {


    const userListInit = initialValue?.map((user) => user?.id.toString());
    const [ searchUser, setSearchUser ] = useState("");


    let { data, loading: loadingQueryCursor, fetchMore } = useQuery(GET_USERS_CURSOR, {
        variables: {
            text: useDebounce(searchUser),
            first: 25,
        },
        fetchPolicy: 'cache-and-network',
        nextFetchPolicy: 'cache-first',
        notifyOnNetworkStatusChange: true
    });


    const {
        fetchMoreAction,
        pageInfo,
        node
    } =
        cursorPagination({
            model,
            data,
            loading: loadingQueryCursor,
            fetchMore
        });


    const nodes = node.map(
        ({ node }) => ({
            label: node.name,
            value: node.id,
            // disabled: (node.role === 'admin' || node.role === 'moderator'),
        }));

    const initialValues = initialValue?.map(
        user => ({
            label: user.name,
            value: user.id,
            // disabled: (node.role === 'admin' || node.role === 'moderator'),
        })
    )

    const loading = loadingQueryCursor || loadingQuery;


    return (

        <Form.Item name={ name }>

            <MultiSelect
                setSearch={ setSearchUser }
                search={ searchUser }
                disableBtn={ true }
                placeholder={ Localize({ children: "SEARCH.Input_Placeholder_User" }).props.children }
            >
                { setSearchUser && loading ? <Skeleton active={ true } paragraph={ { rows: 6 } } /> :
                    <Checkbox.Group
                        className='group-checkbox'

                        options={ [ ...new Set([ ...nodes, ...initialValues ].map(item => JSON.stringify(item))) ].map(item => JSON.parse(item)) }

                        defaultValue={ userListInit }
                        onChange={ value => form.setFieldsValue({ [ name ]: value }) } />
                }


                { pageInfo?.hasNextPage &&

                    <>
                        { loadingQueryCursor && <Loader style={ { marginTop: '-30px' } } /> }

                        <Waypoint onEnter={ () => fetchMoreAction() } />
                    </>
                }

            </MultiSelect>

        </Form.Item>
    )
};

export default UserAddСheckboxSelect;