import React from "react";
import { Switch, Route, useRouteMatch } from 'react-router-dom';
import SubPage from 'components/pages/settings/sub-pages';



const SettingRoute = ({ history, options, summaryStatistic }) => {

    const { path } = useRouteMatch();

    return (
        <Switch>
            <Route path={ `${ path }` } exact>
                <SubPage.EmailRules
                    options={ options }
                    history={ history } />
            </Route>

            <Route path={ `${ path }/counters` } exact>
                <SubPage.Counters
                    summaryStatistic={ summaryStatistic }
                    history={ history } />
            </Route>
        </Switch>
    );
};

export default SettingRoute;