import React from "react";
import { useQuery } from "@apollo/client";
import { GET_HASHTAG } from "graphql/query/hashtag-gql";
import { PageWrapDefault } from "components/layout";
import { useActiveMenuItem } from "components/use-hooks";
import HashtagRoute from 'components/routes/hashtag/hashtag-route';
import Hashtag from "components/hashtag";
import { Localize } from "components/service";



const HashtagPage = ({ match, history }) => {

    useActiveMenuItem([ "hashtags" ], [ "hashtags" ]);

    const slug = match ? match.params.slug : null;

    const { data: { hashtag } = {}, loading } = useQuery(GET_HASHTAG, {
        skip: !slug,
        variables: {
            slug
        }
    });


    const pageNavbar = [
        {
            label: Localize({ children: "NAVBAR_PAGES.Label_Posts" }).props.children,
            path: ""
        },
        {
            label: Localize({ children: "NAVBAR_PAGES.Label_Followers" }).props.children,
            path: "followers"
        }
    ];


    return (
        <PageWrapDefault
            className="page-hashtag"
            loading={ loading }
            title={ !loading && hashtag ? hashtag.title : 'Page 404' }
            dataExist={ !loading }
            pageNavbar={ pageNavbar }
            staticPath={ `${ Hashtag.Const.basePath }/${ slug }` }
        >
            <HashtagRoute
                hashtag={ hashtag }
                history={ history }
                loading={ loading } />

        </PageWrapDefault>
    );
};

export default HashtagPage;
