const makeVarParam = (paramVar, initialParam) => {

    const resetParams = () => {
        paramVar(initialParam);
    };

    const setParams = (newParams) => {

        paramVar({
            ...paramVar(),
            ...newParams
        });
    };

    const getParam = (param) => {

        if(param === undefined){
            return  {...paramVar()};
        }

        return paramVar()[param];
    };

    return {
        set : setParams,
        get : getParam,
        reset : resetParams
    }

};

export default makeVarParam;