import { useMutation } from "@apollo/client";
import { POST_COMMENT_DELETE } from "graphql/mutation/post-gql";
import { GET_POST } from "graphql/query/post-gql";
import { successNotification, errorNotification } from "components/request-result";



const useMutationPostDelete = (postID) => {

  const [ _setMutationPostCommentDelete, { loading } ] = useMutation(POST_COMMENT_DELETE,
    {
      update(cache, { data }) {


        successNotification({
          title: "Comment successfully deleted",
        });
      },

      refetchQueries: [ { query: GET_POST, variables: { id: postID } } ],

      onError(error) {
        errorNotification(error);
      }
    });

  return {
    _setMutationPostCommentDelete,
    loadingMutationPostCommentDelete: loading,
  }
};

export default useMutationPostDelete;