import React from "react";
import { useQuery } from "@apollo/client";

import { IS_LOGGED_IN } from "graphql/query/local-store-gql";

import GuestApp from "components/guest-app";


const Auth = ({ children }) => {

    const { data } = useQuery(IS_LOGGED_IN);

    return (
        <>
            { data !== undefined && data.isLoggedIn ? children : <GuestApp /> }
        </>
    );
}


export default Auth;