import CounterForm from "./counter-form";



import './forms.scss';

const CounterForms = {
    Counter: CounterForm,
};

export default CounterForms;
