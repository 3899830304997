import React from "react";

import { Forms } from "components/auth";
import { useRouteMatch } from "react-router-dom";


const ForgotPasswordPage = () => {

    const match = useRouteMatch( "/forgot-password/:token");

    const formItemLayout = {
        labelCol: { span: 24 },
        wrapperCol: { span: 24 }
    };

    return(
        <div className="page">
            <div className="container">
                <div className="block">
                    { match ?
                        <Forms.ChangeForgotPassword forgotToken={ match.params.token } />:
                        <Forms.ForgotPassword
                            formItemLayout={ formItemLayout }
                        />
                    }
                </div>
            </div>
        </div>
    );
};

export default ForgotPasswordPage;