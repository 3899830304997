import { Button, Table } from 'antd';
import { ModalStandard } from 'components/service';

import './elements.scss';


const Imported = ({ data, modalTitle = 'The file is import', isImport = false, model = false }) => {

  model === 'subcontractor' && (data = {
    sync_info: {
      subcontractors: {
        records: data?.sync_info?.records,
        new: data?.sync_info?.new,
        updated: data?.sync_info?.updated,
      }
    }
  })


  function convertData(data) {
    let newData = [];
    for (let key in data.sync_info)
    {
      newData.push({
        key: key,
        records: key,
        found: data.sync_info[key].records,
        new: data.sync_info[ key ].new,
        update: data.sync_info[ key ].updated,
      });
    }
    return newData;
  }


  let columns = [
    {
      title: 'RECORDS',
      dataIndex: 'records',
      className: 'primary-color',
    },
    {
      title: 'Found',
      dataIndex: 'found',
    },
    {
      title: 'New',
      dataIndex: 'new',
    },

    {
      title: 'Updated',
      dataIndex: 'update',
    },
  ];


  columns = isImport ?
    columns.filter(column => column.title !== 'New') : columns;


  const Inner = ({ action }) => (
    <div className='holder-importing'>
      <br />
      <strong className='modal-title'>{ modalTitle }</strong>

      <Table className='modal-table' columns={ columns } dataSource={ convertData(data) } pagination={ false } />

      <Button
        className="ant-btn-secondary bg-light-gray btn-right"
        onClick={ action }
      >
        Ok
      </Button>

    </div>
  )


  return (

    <ModalStandard
      width={ 380 }
      extraClass={ 'modal-form' }
      defaultShow={ true } >

      <Inner modalTitle={ modalTitle } />

    </ModalStandard>

  );
}

export default Imported;



