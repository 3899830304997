import { useMutation } from "@apollo/client";
import { COMMENT_DELETE } from "graphql/mutation/comment-gql";
import { successNotification, errorNotification } from "components/request-result";


const useMutationCommentDelete = (id) => {

  const [ _setMutationCommentDelete, { loading } ] = useMutation(COMMENT_DELETE,
    {
      update(cache, { data }) {

        cache.evict({
          id: cache.identify({ id, __typename: "Comment" })
        });
        cache.gc();


        successNotification({
          title: 'Comment successfully deleted',
        });
      },
      onError(error) {
        errorNotification(error);
      }
    });

  return {
    _setMutationCommentDelete,
    loadingMutationCommentDelete: loading,
  }
};

export default useMutationCommentDelete;