import React, { useState } from "react";
import { useRouteMatch } from "react-router-dom";
import { Input } from 'antd';
import { SearchOutlined } from '@ant-design/icons';

import { GET_USER_QUESTIONS } from 'graphql/query/user-gql';

import User from 'components/user';
import { useActiveMenuItem, useBreadCrumbs } from "components/use-hooks";
import { Tables } from "components/layout";
import { Localize } from "components/service";

const { Search } = Input;


const UserQuestionsSubPage = ({ user }) => {

    const basePath = User.Const.basePath;
    const tableHelper = User.Helpers.TableQuestion;

    useActiveMenuItem([ "users" ], [ "users" ]);


    const breadcrumbs = [
        {
            label: Localize({ children: "BREADCRUMBS.Label_Users" }).props.children,
            path: `${ basePath }`
        },
        {
            label: user?.name,
            path: `${ basePath }/${ user?.id }`
        },
        {
            label: Localize({ children: "BREADCRUMBS.Label_Questions" }).props.children,
            path: `${ basePath }/${ user?.id }/questions`
        }
    ]

    useBreadCrumbs(breadcrumbs);

    const routeUrl = `${ basePath }/${ user?.id }/questions`;
    let match = useRouteMatch(`${ routeUrl }/page/:pageNum`);


    const [ searchText, setSearchText ] = useState("");
    // const [ variables, setVariables ] = useState("");

    const userQuetions = { column: 'AUTHOR_ID', operator: "EQ", value: parseInt(user.id) }


    return (

        <Tables.Main
            model="userQuestions"
            extraClass="table-questions"
            query={ GET_USER_QUESTIONS }
            varSet={ { perPage: 30 } }
            routeUrl={ routeUrl }
            currentPage={ match ? parseInt(match.params.pageNum) : 1 }
            searchText={ searchText }
            objFilter={ userQuetions }
            // setVariables={ setVariables }
            tableHelper={ tableHelper }
        >
            <div className="table-action-bar">

                <div className="col">
                    <Search
                        className="filter-search"
                        prefix={ <SearchOutlined /> }
                        placeholder={ Localize({ children: "SEARCH.Input_Placeholder_MultiSelect" }).props.children }
                        onChange={ (e) => setSearchText(e.target.value) }
                    />
                </div>

                <div className="col">

                    {/* <ModalStandard
                        width={ 380 }
                        extraClass={ 'modal-form' }
                        modalButton={
                            <Button
                                type="primary">
                                <span>Create Question</span>
                            </Button>
                        }>
                        <strong className="modal-title"><Localize>FORMS.Title_Question</Localize></strong>

                        <User.Forms.Question variables={ variables } />
                    </ModalStandard> */}
                </div>
            </div>
        </Tables.Main>
    )
};

export default UserQuestionsSubPage;
