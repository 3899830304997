import { gql } from '@apollo/client';



export const GET_COLLECTIONS = gql`
    query GetCollections(
        $text: String
        $where: QueryCollectionsWhereWhereConditions
        $orderBy: [QueryCollectionsOrderByOrderByClause!]
        $first: Int
        $page: Int
    ){
        collections(
            text: $text,
            where: $where,
            orderBy: $orderBy,
            first: $first,
            page: $page
        ){
            paginatorInfo{
                total
                count
                currentPage
                perPage
                lastPage
            }
            data {
                id
                title
                slug
                cover_url
                description
                status
                author {
                  id
                  name
                  nickname
                }
                count_posts
                me_following
                count_followers
                created_at
                updated_at
              }
        }
    }
`;

export const GET_COLLECTION = gql`
    query GetCollection(
        $slug: String
        $id: ID
    ){
        collection(
            slug: $slug,
            id: $id
        ){
            id
            title
            slug
            cover_url
            description
            status
            author {
                id
                name
                nickname
            }
            count_posts
            me_following
            count_followers
            created_at
            updated_at
        }
    }
`;