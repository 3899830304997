import { useHistory } from 'react-router-dom';
import { useMutation } from "@apollo/client";
import { DEALER_DELETE } from "graphql/mutation/dealer-gql";
import DealerConst from '../dealer-const';
import { successNotification, errorNotification } from "components/request-result";


const useMutationDealerDelete = (id) => {

  const history = useHistory();

  const [ _setMutationDealerDelete, { loading } ] = useMutation(DEALER_DELETE,
    {
      update(cache, { data }) {

        const {
          dealerDelete: {
            label,
            message,
          }
        } = data;


        history.push(DealerConst.basePath);

        cache.evict({
          id: cache.identify({ id, __typename: "Dealer" })
        });
        cache.gc();

        successNotification({
          title: label.toUpperCase(),
          description: message
        });
      },

      onError(error) {
        errorNotification(error);
      },
    });

  return {
    _setMutationDealerDelete,
    loadingMutationDealerDelete: loading,
  }
};

export default useMutationDealerDelete;