import { useMutation } from "@apollo/client";
import { OPTION_SET } from "graphql/mutation/email-gql";
import { successNotification, errorNotification } from "components/request-result";



const useMutationEmailOption = () => {


  const [ _setEmailOption, { loading } ] = useMutation(OPTION_SET,
    {
      update(cache, { data }) {


        successNotification({
          title: 'Update Email rules success',
        });
      },
      onError(error) {
        errorNotification(error);
      }
    }
  );


  return {
    _setEmailOption,
    loadingMutationEmailOption: loading,
  }
};

export default useMutationEmailOption;

