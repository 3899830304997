import React from "react";

import Post from 'components/post';

import { useBreadCrumbs } from "components/use-hooks";
import { Localize } from "components/service";

const postBasePath = Post.Const.basePath;


const PostMediaSubPage = ({ post, history }) => {

    const breadcrumbs = [
        {
            label: Localize({ children: "BREADCRUMBS.Label_Posts" }).props.children,
            path: `${ postBasePath }`
        },
        {
            label: post?.title ?? <strong>the post has no title</strong>,
            path: `${ postBasePath }/${ post?.id }`
        },

        {
            label: Localize({ children: "BREADCRUMBS.Label_Media" }).props.children,
            path: `${ postBasePath }/${ post?.id }/media`
        },
    ]

    useBreadCrumbs(breadcrumbs);


    return (

        <div className="row-grid col-lg-2">
            <Post.Forms.Media media={ post.media } history={ history } />
        </div>

    )
};

export default PostMediaSubPage;