import UserOverviewSubPage from "./user-owerview-sub-page";
import UserDealerSubPage from "./user-dealer-sub-page";
import UserPostSubPage from "./user-posts-sub-page";
import UserQuestionsSubPage from "./user-questions-sub-page";


const SubPage = {
    OverView: UserOverviewSubPage,
    Dealer: UserDealerSubPage,
    Posts: UserPostSubPage,
    Questions: UserQuestionsSubPage
};

export default SubPage;
