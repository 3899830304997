import React from "react";
import { useHistory } from 'react-router-dom';
import { Menu, Dropdown } from 'antd';
import { EllipsisOutlined } from '@ant-design/icons';
import { dealerUseMutation } from '../hooks';

import DealerConst from '../dealer-const';
import { EntityRemoveButton, Localize } from 'components/service';
import Icons from 'components/icons';




const DealerActionField = ({ dealerId, dealerName, variables }) => {

    const history = useHistory();

    const {
        _setMutationDealerDelete,
        loadingMutationDealerDelete,
    } = dealerUseMutation.delete(dealerId, variables);


    const menu = (
        <Menu
            items={ [
                {
                    label: <Localize>MENUS_DROP.Label_Edit</Localize>,
                    key: '1',
                    icon: <Icons.Edit />,
                    onClick: () => history.push(`${ DealerConst.basePath }/${ dealerId }`)
                },
                {
                    key: '2',
                    disabled: false,
                    icon:
                        <> { false ?
                            <span style={ { display: 'flex', alignItems: 'center' } } ><Icons.Delete /> <Localize>MENUS_DROP.Label_Delete</Localize></span> :

                            <EntityRemoveButton
                                modalButton={
                                    <span style={ { display: 'flex', alignItems: 'center' } } ><Icons.Delete /> <Localize>MENUS_DROP.Label_Delete</Localize></span>
                                }
                                nameEntity={ Localize({ children: "ENTITY.Modal_Title_Dealer" }).props.children }
                                dataNameEntity={ dealerName }
                                loading={ loadingMutationDealerDelete }
                                deleteMutation={ _setMutationDealerDelete }
                                variables={ {
                                    id: dealerId,
                                } }
                            /> }
                        </>,
                },
            ] }
        />
    );

    return (
        <Dropdown
            // trigger={['click']}
            placement="bottomRight"
            overlay={ menu }
            overlayClassName="drop-more-menu"
            arrow
        >
            <EllipsisOutlined className="btn-more" />
        </Dropdown>
    )
};

export default DealerActionField;

