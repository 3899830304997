import React from "react";
import { Form, Input, Button } from 'antd';
import { postUseMutation } from '../../hooks';
import { Localize, EntityRemoveButton } from "components/service";
import Post from "components/post";


const PostForm = ({ post, history }) => {

    const [ form ] = Form.useForm();

    const {
        _setMutationPostDelete,
        loadingMutationPostDelete,
    } = postUseMutation.delete(post?.id);


    return (

        <Form
            key="edit-post-form"
            layout="vertical"
            form={ form }
            className="model-form edit-post-form"
        >
            <Form.Item

                name="title"
                label={ <Localize>FORMS.Input_Label_Title</Localize> }
                initialValue={ post?.title }
            >
                <Input disabled={ true } name="title" />
            </Form.Item>


            <Form.Item

                name="description"
                label={ <Localize>FORMS.Input_Label_Description</Localize> }
                initialValue={ post?.description }
            >
                <Input.TextArea
                    disabled={ true }
                    maxLength={ 300 }
                    // placeholder={ Localize({ children: "FORMS.Input_Placeholder_ShortDescription" }).props.children }
                    autoSize={ { minRows: 3, maxRows: 35 } }
                // showCount={
                //     { formatter: ({ count, maxLength }) => maxLength - count }
                // }
                />
            </Form.Item>

            <div className="form-btn-holder">
                { post?.id &&
                    <EntityRemoveButton
                        modalButton={
                            <Button><Localize>POST.Button_Text_Delete</Localize> </Button>
                        }
                        nameEntity={ Localize({ children: "ENTITY.Modal_Title_Post" }).props.children }
                        dataNameEntity={ post?.title ?? '' }
                        loading={ loadingMutationPostDelete }
                        deleteMutation={ _setMutationPostDelete }
                        variables={ {
                            id: post?.id,
                        } }
                    /> }

                <Button
                    type="primary"
                    className="btn-right"
                    onClick={ () => history.push(Post.Const.basePath) }
                >
                    <Localize>GLOBAL.Button_Text_Cancel</Localize>
                </Button>
            </div>
        </Form>
    );
};

export default PostForm;


