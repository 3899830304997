import React from "react";
import { Switch, Route, useRouteMatch } from 'react-router-dom';
import SubPage from 'components/pages/post/sub-pages';


const PostRoute = ({ post, history }) => {

    const { path } = useRouteMatch();

    return (
        <Switch>
            <Route path={ `${ path }/media` } >
                <SubPage.Media
                    post={ post }
                    history={ history } />
            </Route>

            <Route path={ `${ path }/comments` } >
                <SubPage.Comments
                    post={ post } />
            </Route>

            <Route path={ `${ path }/attachments` } >
                <SubPage.Attachments
                    post={ post } />
            </Route>

            <Route path={ `${ path }` }>
                <SubPage.OverView
                    post={ post }
                    history={ history } />
            </Route>
        </Switch>
    );
};

export default PostRoute;