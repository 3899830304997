import { Link } from "react-router-dom";
import { useQuery } from "@apollo/client";
import { GET_USER_QUESTIONS_NOT_ANSWERED } from "graphql/query/user-gql";
import { Dropdown, Avatar } from 'antd';
import { DownOutlined, UserOutlined, LogoutOutlined, MailOutlined } from '@ant-design/icons';
import { useLogout, useMe } from "components/use-hooks";
import { Localize } from "components/service";


import './dropdown-menu.scss';



const DropMenu = () => {

  const { data: { userQuestions } = {} } = useQuery(GET_USER_QUESTIONS_NOT_ANSWERED, {
    variables: {
      where: {
        column: 'ANSWER_ID',
        operator: 'IS_NULL'
      }
    }
  });

  const { me } = useMe();
  const { logout } = useLogout();

  const avatar = <Avatar size="small" icon={ !!me?.avatar ? <img src={ `${ me.avatar }` } alt='avatar' /> : me.initials } />

  const items = [
    {
      key: 'profile',
      label: <Link to={ { pathname: `/users/${ me.id }` } } > <UserOutlined /> &nbsp; <Localize>MENUS_DROP.Label_MyProfile</Localize></Link>,
    },
    {
      key: 'logout',
      label: <span onClick={ () => logout({ redirectRoute: "/" }) }><LogoutOutlined /> &nbsp; <Localize>MENUS_DROP.Label_Logout</Localize></span>
    }
  ];


  return (
    <div className="holder-profile-link">

      { userQuestions?.data?.length ?
        <Link to="/user-questions">
          <span className="answer-count">
            <MailOutlined />
            <span className="badge">{ userQuestions?.data?.length }</span>
          </span>
        </Link>
        : '' }

      <Dropdown
        className="dropdown-profile"
        menu={ { items } }
        trigger={ [ 'click' ] } >

        <span className="my-profile-link" onClick={ e => e.preventDefault() }>
          { avatar } &nbsp; &nbsp; <span className="profile-info"> { me.name } </span> <DownOutlined />
        </span>
      </Dropdown>
    </div>

  )
};


export default DropMenu;


