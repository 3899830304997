import React from "react";
import { Dropdown } from 'antd';
import { EllipsisOutlined } from '@ant-design/icons';
import { userUseMutation } from '../hooks';
import UserForms from "../forms";
import { EntityRemoveButton, ModalStandard, Localize } from 'components/service';
import Icons from 'components/icons';


const UserQuestionActionField = ({ userQuestion, variables }) => {


    const {
        _setMutationUserQuestionDelete,
        loadingMutationUserQuestionDelete
    } = userUseMutation.questionDelete(userQuestion?.id);


    let items = [

        {
            key: 'edit',
            icon:
                <ModalStandard
                    width={ 680 }
                    extraClass={ 'modal-form' }
                    modalButton={
                        <span style={ { display: 'flex', alignItems: 'center' } } >
                            <Icons.Edit /> <Localize>MENUS_DROP.Label_Answer</Localize>
                        </span> }>

                    <strong className="modal-title"><Localize>FORMS.Title_Answer</Localize></strong>

                    <UserForms.Question userQuestion={ userQuestion } variables={ variables } />
                </ModalStandard>
        },
        {
            key: 'delete',
            icon:
                <>
                    <EntityRemoveButton
                        modalButton={
                            <span style={ { display: 'flex', alignItems: 'center' } } ><Icons.Delete /> <Localize>MENUS_DROP.Label_Delete</Localize></span>
                        }
                        nameEntity={ Localize({ children: "ENTITY.Modal_Title_User" }).props.children }
                        dataNameEntity={ userQuestion?.relAuthor.name }
                        loading={ loadingMutationUserQuestionDelete }
                        deleteMutation={ _setMutationUserQuestionDelete }
                        variables={ {
                            id: userQuestion.id,
                        } } />
                </>
        }
    ]



    return (

        <Dropdown
            // trigger={['click']}
            placement="bottomRight"
            menu={ { items } }
            overlayClassName="drop-more-menu"
            arrow
        >
            <EllipsisOutlined className="btn-more" />
        </Dropdown>

    )
};

export default UserQuestionActionField;

