import React, { useState } from "react";
import { useRouteMatch } from "react-router-dom";
import { Button } from 'antd';
import { PlusOutlined } from '@ant-design/icons';

import { GET_USERS } from 'graphql/query/user-gql';
import { DEALER_SYNC_USERS } from 'graphql/mutation/dealer-gql';

import User from 'components/user';
import Dealer from 'components/dealer';

import { useBreadCrumbs } from "components/use-hooks";
import { Tables } from "components/layout";
import { Localize, ModalStandard } from "components/service";
import { gqlBuilderWhere } from "utils";



const DealerUsersSubPage = ({ dealer }) => {

    const basePath = Dealer.Const.basePath;
    const userBasePath = User.Const.basePath;
    const tableHelper = User.Helpers.TableMain([ 'status', 'role', 'dealer' ]);

    const breadcrumbs = [
        {
            label: Localize({ children: "BREADCRUMBS.Label_Dealers" }).props.children,
            path: `${ basePath }`
        },
        {
            label: dealer?.company_name,
            path: `${ basePath }/${ dealer?.id }`
        },
        {
            label: Localize({ children: "BREADCRUMBS.Label_Users" }).props.children,
            path: `${ basePath }/${ dealer?.id }${ userBasePath }`
        },
    ]

    useBreadCrumbs(breadcrumbs);

    const routeUrl = `${ basePath }/${ dealer?.id }${ userBasePath }`;
    let match = useRouteMatch(`${ routeUrl }/page/:pageNum`);

    const objOrderBy = [ { column: "ID", order: "DESC" } ];

    const [ searchText, setSearchText ] = useState("");
    const [ filters, setFilters ] = useState();
    const [ resetSorting, setResetSorting ] = useState(false);

    const modelFilters = `usersFilters_dealerID-${ dealer?.id }`;
    const modelSorting = `usersSorting_dealerID-${ dealer?.id }`;

    const usersFilters = JSON.parse(localStorage.getItem(modelFilters));
    const usersSorting = JSON.parse(localStorage.getItem(modelSorting));


    return (
        <Tables.Main
            model="users"
            query={ GET_USERS }
            varSet={ { perPage: 30, dealer_id: dealer?.id } }
            routeUrl={ routeUrl }
            currentPage={ match ? parseInt(match.params.pageNum) : 1 }
            searchText={ searchText }
            objFilter={ filters || gqlBuilderWhere(usersFilters) }
            objOrderBy={ usersSorting || objOrderBy }
            sortingName={ modelSorting }
            tableHelper={ tableHelper }
            resetSorting={ resetSorting }
            entityRemoveUser={ true }
        >
            <div className="table-action-bar">

                <div className="col">
                    <User.Filters.Table
                        formFieldsHide={ [ 'DEALER_ID', 'STATUS', 'ROLE' ] }
                        filters={ filters }
                        setSearchText={ setSearchText }
                        setFilters={ setFilters }
                        usersFilters={ usersFilters }
                        setResetSorting={ setResetSorting }
                        objOrderBy={ objOrderBy }
                        modelFilters={ modelFilters }
                        modelSorting={ modelSorting }
                    />
                </div>

                <div className="col">

                    <ModalStandard
                        width={ 380 }
                        extraClass={ 'modal-form' }
                        modalButton={
                            <Button
                                type="primary">
                                <span><PlusOutlined /> <Localize>MODAL.Button_Text_AddUserDealer</Localize></span>
                            </Button>
                        }>
                        <strong className="modal-title"><Localize>FORMS.Title_AddUsers</Localize></strong>

                        <User.Forms.Add
                            propsObj={ {
                                query: GET_USERS,
                                queryVar: {
                                    text: '',
                                    dealer_id: +dealer?.id,
                                    first: 10000,
                                },
                                mutation: DEALER_SYNC_USERS,
                                mutationName: "dealerSyncToUsers",
                                model: "dealer_id",
                                modelID: +dealer?.id,
                            }
                            } />
                    </ModalStandard>
                </div>
            </div>
        </Tables.Main>
    )
};

export default DealerUsersSubPage;