import { useHistory } from "react-router-dom";
import useVarParam from "./use-var-param";


const useAuthRedirect = () => {

    const history = useHistory(),
          guestParams = useVarParam("guest");

    if( guestParams.get("comeFrom") ){
        return () => history.replace(guestParams.get("comeFrom"));
    }

    return () => history.replace("/");
}

export default useAuthRedirect;