import Importing from "./importing";
import Imported from "./imported";
import AnimationFolder from "./animation-folder";


const Elements = {
  Importing,
  Imported,
  AnimationFolder,
}

export default Elements;