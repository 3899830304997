import { LoginPage, ForgotPasswordPage } from "./authorization";
import { UsersPage, UsersQuestionsPage, UserPage } from "./user";
import { DealersPage, DealerPage } from "./dealer";
import { PostsPage, PostPage } from "./post";
import { CommentsPage } from "./comment";
import { HashtagsPage, HashtagPage } from "./hashtag";
import { CollectionsPage, CollectionPage } from "./collection";
import { AnalyticPage } from "./analytic";
import { SettingsPage } from "./settings";
import Dashboard from "./dashboard";
import Page404 from "./404";


import "./pages.scss";



export const Pages = {
    Dashboard,
    Users: UsersPage,
    User: UserPage,
    Questions: UsersQuestionsPage,
    Dealers: DealersPage,
    Dealer: DealerPage,
    Posts: PostsPage,
    Post: PostPage,
    Comments: CommentsPage,
    Hashtags: HashtagsPage,
    Hashtag: HashtagPage,
    Collections: CollectionsPage,
    Collection: CollectionPage,
    Analytic: AnalyticPage,
    Settings: SettingsPage,
    Page404
};

export const GuestPages = {
    LoginPage,
    ForgotPasswordPage,
}