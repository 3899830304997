import { gql } from '@apollo/client';



export const GET_SUMMARY_STATISTIC = gql`
    query GetSummaryStatistic{
        summaryStatistic{
            id
            key
            value
            active
            updated_at
        }
    }`;