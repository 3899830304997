import React, { useEffect } from "react";
import { Form, InputNumber } from 'antd';
import { sellerUseMutation } from '../hooks';



const SellerQuarterField = ({ id, name, initialValue }) => {


    const [ form ] = Form.useForm();

    const {
        _setSellerCreateUpdate,
    } = sellerUseMutation.createUpdate({ id });

    /*eslint-disable */
    useEffect(() => {
        form.setFieldsValue({ [ name ]: initialValue });
    }, [ initialValue ]);
    /*eslint-enable */

    const handleBlur = (value) => {


        if (initialValue !== value)
        {
            _setSellerCreateUpdate({
                variables: {
                    input: {
                        id,
                        [ name ]: value < 0 ? 0 : value,
                    }
                }
            });
        }
    };


    return (

        <Form
            key="edit-quarter-form"
            layout="vertical"
            form={ form }
            className="model-form edit-quarter-form">

            <Form.Item
                name={ name }
            >
                <InputNumber onBlur={ e => handleBlur(+e.target.value) } name={ name } min={ 0 } max={ 10000 } controls={ false } style={ { width: '100%' } } />

            </Form.Item>
        </Form>
    );
}


export default SellerQuarterField;