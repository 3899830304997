import React from 'react';
import { Link } from 'react-router-dom';
import { Avatar, Card, Skeleton } from 'antd';
import { FileTextOutlined } from '@ant-design/icons';
import UserConst from '../users-const';
const { Meta } = Card;


const UserTopPostsField = ({ user, loadingUsers }) => {


    return (

        <Link to={ `${ UserConst.basePath }/${ user.id }` }>
            <Card>
                <Skeleton loading={ loadingUsers } avatar active>
                    <Meta
                        avatar={ <Avatar shape="circle" size="large" icon={ !!user?.avatar ? <img src={ `${ user.avatar }` } alt='avatar' /> : user?.initials } /> }
                        title={
                            <div className="d-flex justify-content-between white-space-wrap">
                                <strong style={ { marginRight: '15px' } }>{ user?.name ?? <strong>&mdash;</strong> }</strong>
                                <div className="min-w-100px text-right">{ user?.user_counters?.public_posts ? <Link to='/'><FileTextOutlined /> { user.user_counters.public_posts } Posts</Link> : <span><FileTextOutlined /> 0 Posts</span> }</div>
                            </div>
                        }
                        description={ user?.nickname }
                    />
                </Skeleton>
            </Card>
        </Link>
    );
};


export default UserTopPostsField;