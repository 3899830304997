import DealerEditForm from "./dealer-edit-form";
import DealerFormItems from "./fields";
import DealerFiltersForm from "./dealer-filters-form";


import './forms.scss';

const DealerForms = {
    Edit: DealerEditForm,
    Fields: DealerFormItems,
    Filter: DealerFiltersForm,
};

export default DealerForms;
