import { gql } from '@apollo/client';



export const optionFragment = gql`
  fragment optionFields on Option {
    id
    key
    value
  }
`;


export const OPTION_SET = gql`
  mutation OptionSet($input: [OptionInput] = [
    {
      key: "account_activation_by_email"
      value: false
    }
    {
      key: "registration_by_email"
      value: false
    }
    {
      key: "allowed_email"
      value: []
    }
    {
      key: "allowed_personal_email"
      value: []
    }
  ])
   {
    optionSet(input: $input) {
      ...optionFields
    }
  }

  ${optionFragment }
`;

export const OPTION_DELETE = gql`
  mutation OptionDelete($key: String = "", $id: ID = "") {
    optionDelete(id: $id, key: $key) {
      ...optionFields
    }
  }

  ${optionFragment }
`;