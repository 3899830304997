import { useEffect } from "react";

import { useVarParam } from "components/use-hooks";


const useActiveMenuItem = ( currentItem = [], parrentItem = [] ) => {

    const appParam = useVarParam();

    useEffect( () => {
        appParam.set({
            activeMenu : {
                selectedKeys: [ ...currentItem ],
                openKeys: [ ...parrentItem ]
            }
        })
    })
    
};

export default useActiveMenuItem;