import React from "react";
import Email from "components/email";
import { useBreadCrumbs } from "components/use-hooks";
import { Localize } from "components/service";



const SettingsEmailRulesSubPage = ({ options }) => {

    const breadcrumbs = [
        {
            label: Localize({ children: "BREADCRUMBS.Label_Settings" }).props.children,
            path: `/settings`
        }
    ]

    useBreadCrumbs(breadcrumbs);


    return (

        <div className="row-grid col-lg-2">

            <div className="col-left">
                <Email.Forms.Rule option={ options } />
            </div>
            <div className="col-right"></div>
        </div>



    )
};

export default SettingsEmailRulesSubPage;