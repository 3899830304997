import { useMutation } from "@apollo/client";
import { POST_ATTACHED_DELETE } from "graphql/mutation/post-gql";
import { successNotification, errorNotification } from "components/request-result";


const useMutationPostAttachedDelete = (id) => {


  const [ _setMutationPostAttachedDelete, { loading } ] = useMutation(POST_ATTACHED_DELETE,
    {
      update(cache, { data }) {

        const {
          attachedDelete: {
            label,
            message
          }
        } = data;


        cache.evict({
          id: cache.identify({ id, __typename: "Attachment" })
        });
        cache.gc();

        successNotification({
          title: label.toUpperCase(),
          description: message
        });
      },
      onError(error) {
        errorNotification(error);
      }
    });

  return {
    _setMutationPostAttachedDelete,
    loadingMutationPostAttachedDelete: loading,
  }
};

export default useMutationPostAttachedDelete;