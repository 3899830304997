import EmailRuleForm from "./email-rule-form";



import './forms.scss';

const EmailRuleForms = {
    Rule: EmailRuleForm,
};

export default EmailRuleForms;
