import { gql } from '@apollo/client';



export const GET_HASHTAGS = gql`
    query GetHashtags(
        $text: String
        $orderBy: [QueryHashtagsOrderByOrderByClause!]
        $first: Int
        $page: Int
    ){
        hashtags(
            text: $text,
            orderBy: $orderBy,
            first: $first,
            page: $page
        ){
            paginatorInfo{
                total
                count
                currentPage
                perPage
                lastPage
            }
            data {
                id
                slug
                cover_url
                me_following
                count_posts
                count_followers
                created_at
                updated_at
              }
        }
    }
`;


export const GET_HASHTAG = gql`
    query GetHashtag(
        $slug: String
        $id: ID
    ){
        hashtag(
            slug: $slug,
            id: $id
        ){
            id
            slug
            cover_url
            me_following
            count_posts
            count_followers
            created_at
            updated_at
        }
    }
`;