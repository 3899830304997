import React from "react";
import { Form, Input, Button } from 'antd';
import { Localize } from "components/service";
import Post from "components/post";


const PostMediaForm = ({ media, history }) => {

    const [ form ] = Form.useForm();

    return (

        <Form
            key="edit-media-form"
            layout="vertical"
            form={ form }
            className="model-form edit-post-form"
        >
            {
                media?.map(
                    (media) => {
                        return (

                            <div key={ media.id } className='row-grid col-2' style={ { gridTemplateColumns: '105px 1fr' } } >
                                <div className="box-img">
                                    <img src={ media?.thumbnail } alt={ media?.description } />
                                </div>

                                <Form.Item
                                    name={ `description_${ media?.id }` }
                                    label={ <Localize>FORMS.Input_Label_Description</Localize> }
                                    initialValue={ media?.description }
                                >
                                    <Input.TextArea
                                        disabled={ true }
                                        maxLength={ 300 }
                                        autoSize={ { minRows: 3, maxRows: 35 } }
                                    />
                                </Form.Item>
                            </div>
                        )
                    }
                )
            }
            <div className="form-btn-holder">

                <Button
                    type="primary"
                    className="btn-right"
                    onClick={ () => history.push(Post.Const.basePath) }
                >
                    <Localize>GLOBAL.Button_Text_Cancel</Localize>
                </Button>
            </div>
        </Form>
    );
};

export default PostMediaForm;


