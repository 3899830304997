import CollectionInfoField from "./collection-info-field";
import CollectionActionField from './collection-action-field';
import CollectionTitleField from './collection-title-field';


const CollectionFields = {
    Info : CollectionInfoField,
    Action: CollectionActionField,
    Title: CollectionTitleField,
}

export default CollectionFields;