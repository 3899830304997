import React from "react";
import { useQuery } from "@apollo/client";
import { GET_OPTIONS } from "graphql/query/email-gql";
import { GET_SUMMARY_STATISTIC } from "graphql/query/statistic-gql";
import { PageWrapDefault } from "components/layout";
import { useActiveMenuItem } from "components/use-hooks";
import SettingRoute from 'components/routes/settings/setting-route';
import { Localize } from "components/service";



const SettingsPage = ({ history }) => {

    useActiveMenuItem([ "settings" ], [ "settings" ]);


    const { data: { options } = {}, loading: loadingQueryOptions } = useQuery(GET_OPTIONS);
    const { data: { summaryStatistic } = {}, loading: loadingQueryStatistics } = useQuery(GET_SUMMARY_STATISTIC);

    const pageNavbar = [
        {
            label: Localize({ children: "NAVBAR_PAGES.Label_EmailRules" }).props.children,
            path: ""
        },
        {
            label: Localize({ children: "NAVBAR_PAGES.Label_Counters" }).props.children,
            path: "counters"
        }
    ];


    const loading = loadingQueryOptions || loadingQueryStatistics;


    return (
        <PageWrapDefault
            className="page-settings"
            dataExist={ !loading }
            loading={ loading }
            title={ <Localize>PAGES.Title_Settings</Localize> }
            pageNavbar={ pageNavbar }
            staticPath={ `/settings` }
        >
            <SettingRoute history={ history } options={ options } summaryStatistic={ summaryStatistic } />

        </PageWrapDefault>
    );
};

export default SettingsPage;