import React from 'react';
import { Form, Select } from "antd";
import Icons from "components/icons";
import { Localize } from "components/service";


const UserRoleSelect = ({
    disabled = false,
    rules = false,
    form,
    initialValue,
    mode = '',
    name = 'role',
    onChangeSetFilter = () => { },
    label = Localize({ children: "FORMS.Input_Label_Role" }).props.children
}) => {


    return (
        <Form.Item
            name={ name }
            label={ label }
            initialValue={ initialValue }
            rules={ rules && [ { required: true, message: `${ Localize({ children: "FORM_RULES.Required_Choose" }).props.children } ${ label }` } ] }
        >
            <Select
                showSearch
                suffixIcon={ <Icons.Arrow /> }
                mode={ mode }
                className="select-serch-input"
                disabled={ disabled }
                placeholder={ `${ Localize({ children: "FORMS.Input_Placeholder_Choose" }).props.children } ${ label }` }
                popupClassName="select-serch-dropdown"
                optionFilterProp="value"
                onChange={
                    (value) => {
                        form.setFieldsValue({ [ name ]: value });
                        onChangeSetFilter(false, name, value);
                    } }
            >

                <Select.Option key="user" value="user"><Localize>FORMS.Select_OptionLabel_User</Localize> </Select.Option>
                <Select.Option key="moderator" value="moderator"><Localize>FORMS.Select_OptionLabel_Moderator</Localize> </Select.Option>
                <Select.Option key="admin" value="admin"><Localize>FORMS.Select_OptionLabel_Admin</Localize> </Select.Option>

            </Select>
        </Form.Item>
    );

}

export default UserRoleSelect;


