import CollectionOverviewSubPage from "./collection-owerview-sub-page";
import CollectionPostsSubPage from "./collection-posts-sub-page";
import CollectionFollowersSubPage from "./collection-followers-sub-page";


const SubPage = {
    OverView: CollectionOverviewSubPage,
    Posts: CollectionPostsSubPage,
    Followers: CollectionFollowersSubPage,
};

export default SubPage;
