import React from "react";
import { Form, Button, DatePicker } from "antd";
import { Localize } from "components/service";

import User from "components/user";
import Dealer from "components/dealer";
import { initialValFormatted, initialDate } from "utils/helper-functions";




const SellerFiltersForm = ({
    action,
    sellersFilters,
    setCount,
    setFilters,
    setFilterList,
    filterList,
    gqlBuilderWhere,
    onChangeSetFilter,
    form }) => {


    return (

        <div className="ant-form ant-form-vertical filters-form">

            <Form.Item
                initialValue={ initialDate(sellersFilters, 'YEAR') }
                name="YEAR"
                label={ <Localize>FORMS.Input_Label_Year</Localize> }
                onChange={ onChangeSetFilter }
            >
                <DatePicker
                    picker="year"
                    placeholder={ Localize({ children: "FORMS.Input_Placeholder_SelectDate" }).props.children }
                    showTime format="YYYY"
                    onChange={ (_, dateSrings) =>
                        dateSrings[ 0 ] !== '' ?
                            setFilterList({
                                ...filterList,
                                YEAR: {
                                    column: "YEAR",
                                    operator: "EQ",
                                    value: dateSrings
                                }
                            }) : setFilterList({
                                ...filterList,
                                YEAR: {}
                            }) } />
            </Form.Item>

            <User.Forms.Fields.AddUserRadioSelect
                initialValue={ initialValFormatted(sellersFilters, 'USER_ID') }
                name="USER_ID"
                form={ form }
                choseBtn={ false }
                onChangeSetFilter={ onChangeSetFilter }
                modalButtonText={ <Localize>MODAL.Button_Text_NotSelected</Localize> }
            />

            <Dealer.Forms.Fields.Select
                initialValue={ initialValFormatted(sellersFilters, 'DEALER_ID') }
                name="DEALER_ID"
                form={ form }
                choseBtn={ false }
                onChangeSetFilter={ onChangeSetFilter }
                modalButtonText={ <Localize>MODAL.Button_Text_NotSelected</Localize> }
            />

            <div className="form-btn-holder">
                <Button
                    type="primary"
                    htmlType="submit"
                    onClick={ () => {
                        setFilters(gqlBuilderWhere({ ...sellersFilters, ...filterList }));
                        setCount({ ...sellersFilters, ...filterList });
                        action();
                    } }
                >
                    <Localize>FORMS.Button_Text_ApplyFilters</Localize>
                </Button>
                <Button
                    className="ant-btn-secondary bg-light-gray btn-right"
                    htmlType="submit"
                    onClick={ () => {
                        setFilterList({});
                        setCount({});
                        setFilters(gqlBuilderWhere({}));
                        localStorage.removeItem("sellersFilters");
                    } }
                >
                    <Localize>FORMS.Button_Text_ResetFilters</Localize>
                </Button>
            </div>
        </div>
    )
}

export default SellerFiltersForm;