import React from "react";
import { Form, Select } from "antd";
import Icons from "components/icons";
import { Localize } from "components/service";

const UserVisitedSelect = ({
  disabled = false,
  rules = false,
  form,
  initialValue,
  mode = "",
  name = "last_visit_at",
  onChangeSetFilter = () => {},
  label = Localize({ children: "FORMS.Input_Label_VisitedSite" }).props
    .children,
}) => {
  return (
    <Form.Item
      name={name}
      label={label}
      initialValue={initialValue}
      rules={
        rules && [
          {
            required: true,
            message: `${
              Localize({ children: "FORM_RULES.Required_Choose" }).props
                .children
            } ${label}`,
          },
        ]
      }
    >
      <Select
        showSearch
        suffixIcon={<Icons.Arrow />}
        mode={mode}
        className="select-serch-input"
        disabled={disabled}
        placeholder={`${
          Localize({ children: "FORMS.Input_Placeholder_Choose" }).props
            .children
        } ${label}`}
        popupClassName="select-serch-dropdown"
        optionFilterProp="value"
        onChange={(value) => {
          form.setFieldsValue({ [name]: value });
          onChangeSetFilter(false, name, value);
        }}
      >
        <Select.Option key="any" value="any">
          <Localize>FORMS.Select_OptionLabel_Any</Localize>
        </Select.Option>
        <Select.Option key="yes" value="yes">
          <Localize>FORMS.Select_OptionLabel_Yes</Localize>
        </Select.Option>
        <Select.Option key="no" value="no">
          <Localize>FORMS.Select_OptionLabel_No</Localize>
        </Select.Option>
      </Select>
    </Form.Item>
  );
};

export default UserVisitedSelect;
