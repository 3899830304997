import React from "react";
import { Form, Input, Button } from 'antd';
import { Localize } from "components/service";
import Collection from "components/collection";


const CollectionForm = ({ collection, history }) => {

    return (

        <Form
            key="edit-collection-form"
            layout="vertical"

            className="model-form edit-collection-form"
        >
            <Form.Item

                name="title"
                label={ <Localize>FORMS.Input_Label_CollectionName</Localize> }
                initialValue={ collection?.title }
            >
                <Input disabled={ true } name="title" />
            </Form.Item>

            <Form.Item

                name="slug"
                label={ <Localize>FORMS.Input_Label_CollectionUniqName</Localize> }
                initialValue={ collection?.slug }
            >
                <Input disabled={ true } name="slug" />
            </Form.Item>


            <Form.Item
                name="description"
                label={ <Localize>FORMS.Input_Label_Description</Localize> }
                initialValue={ collection?.description }
            >
                <Input.TextArea
                    disabled={ true }
                    maxLength={ 300 }
                    autoSize={ { minRows: 3, maxRows: 35 } }
                />
            </Form.Item>

            <div className="form-btn-holder">

                <Button
                    type="primary"
                    className="btn-right"
                    onClick={ () => history.push(Collection.Const.basePath) }
                >
                    <Localize>GLOBAL.Button_Text_Cancel</Localize>
                </Button>
            </div>
        </Form>
    );
};

export default CollectionForm;


