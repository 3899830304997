import { useMutation } from "@apollo/client";

import { USER_IMPORT } from "graphql/mutation/user-gql";
import { GET_USERS } from "graphql/query/user-gql";

import { successNotification, errorNotification } from "components/request-result";


const useMutationUserImportReimport = ({ variables }) => {

    const [ _setImportReimport, { loading, data } ] = useMutation(USER_IMPORT,
        {
            update(cache, { data }) {

                const {
                  userImport: {
                        label,
                        message,
                    }
                } = data;


                successNotification({
                    title: label,
                    description: message
                })
            },

            onError(error) {
                errorNotification(error);
            },

            refetchQueries: [
                {
                    query: GET_USERS,
                    variables
                },
            ]
        }
    );


    return {
        _setImportReimport,
        dataMutationImportReimport: data,
        loadingMutationImportReimport: loading,
    }
};

export default useMutationUserImportReimport;

