import { Button } from 'antd';
import AnimationFolder from './animation-folder';
import { ModalStandard } from 'components/service';

import './elements.scss';


const Importing = ({ modalTitle = 'The file is import', loading, extraClass = '', maskClosable = true, cancelHide }) => {

  const Inner = ({ action }) => (
    <div className={ `holder-importing ${ extraClass }` }>
      <br />
      <strong className='modal-title'>{ modalTitle }</strong>

      <AnimationFolder />

      { !cancelHide &&
        <Button
          className="ant-btn-secondary bg-light-gray btn-right"
          onClick={ action }
        >
          Cancel
        </Button>
      }


    </div>
  )


  return (

    <ModalStandard
      width={ 380 }
      extraClass={ 'modal-form' }
      maskClosable={ maskClosable }
      closable={ !loading }
      defaultShow={ true } >

      <Inner modalTitle={ modalTitle } />

    </ModalStandard>

  );
}

export default Importing;


