import React from "react";
import { Switch, Route, useRouteMatch } from 'react-router-dom';
import SubPage from 'components/pages/dealer/sub-pages';


const DealerRoute = ({ dealer, history }) => {

    const { path } = useRouteMatch();

    return (
        <Switch>
            <Route path={ `${ path }/users` } >
                <SubPage.Users
                    dealer={ dealer }
                    history={ history } />
            </Route>

            <Route path={ `${ path }/users/page/:pageNum` } exact>
                <SubPage.Users
                    dealer={ dealer }
                    history={ history } />

            </Route>

            <Route path={ `${ path }` }>
                <SubPage.OverView
                    dealer={ dealer }
                    history={ history } />
            </Route>
        </Switch>
    );
};

export default DealerRoute;