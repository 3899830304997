import React from "react";
import { Switch, Route, useRouteMatch } from 'react-router-dom';
import SubPage from 'components/pages/analytic/sub-pages';


const AnalyticRoute = ({ history }) => {

    const { path } = useRouteMatch();

    return (
        <Switch>
            <Route path={ `${ path }` } exact>
                <SubPage.Sallers
                    history={ history } />
            </Route>

            <Route path={ `${ path }/page/:pageNum` } exact>
                <SubPage.Sallers
                    history={ history } />
            </Route>

        </Switch>
    );
};

export default AnalyticRoute;