import React, { useState } from "react";
import { useRouteMatch, Link } from "react-router-dom";
import { PlusOutlined } from "@ant-design/icons";
import { GET_DEALERS } from 'graphql/query/dealer-gql';
import Dealer from 'components/dealer';
import { useActiveMenuItem, useBreadCrumbs } from "components/use-hooks";
import { PageWrapDefault, Tables } from "components/layout";
import { Localize } from "components/service";
import { gqlBuilderWhere } from "utils";
import './dealer.scss';


const basePath = Dealer.Const.basePath;
const tableHelper = Dealer.Helpers.TableMain;


const DealersPage = () => {

    useActiveMenuItem([ "dealers" ], [ "dealers" ]);

    const breadcrumbs = [
        {
            label: Localize({ children: "BREADCRUMBS.Label_Dealers" }).props.children,
            path: `${ basePath }`
        }
    ]

    useBreadCrumbs(breadcrumbs);

    let match = useRouteMatch(`${ basePath }/page/:pageNum`);

    const objOrderBy = [ { column: "ID", order: "DESC" } ];

    const [ searchText, setSearchText ] = useState("");
    const [ filters, setFilters ] = useState();
    const [ resetSorting, setResetSorting ] = useState(false);

    const dealerFilters = JSON.parse(localStorage.getItem("dealerFilters"));
    const dealerSorting = JSON.parse(localStorage.getItem("dealerSorting"));


    return (
        <PageWrapDefault
            className="page-dealers"
            dataExist={ true }
            title={ <Localize>PAGES.Title_Dealers</Localize> }
            staticPath=""
        >
            <Tables.Main
                model="dealers"
                query={ GET_DEALERS }
                varSet={ { perPage: 30 } }
                routeUrl={ `${ basePath }` }
                currentPage={ match ? parseInt(match.params.pageNum) : 1 }
                searchText={ searchText }
                objFilter={ filters || gqlBuilderWhere(dealerFilters) }
                objOrderBy={ dealerSorting || objOrderBy }
                tableHelper={ tableHelper }
                resetSorting={ resetSorting }
            >
                <div className="table-action-bar">

                    <div className="col">
                        <Dealer.Filters.Table
                            filters={ filters }
                            setSearchText={ setSearchText }
                            setFilters={ setFilters }
                            dealerFilters={ dealerFilters }
                            setResetSorting={ setResetSorting }
                            objOrderBy={ objOrderBy }
                        />
                    </div>

                    <div className="col">
                        <Link
                            className="ant-btn ant-btn-primary"
                            to={ `${ basePath }/create` }>

                            <span>
                                <PlusOutlined />
                                <Localize>DEALER.Button_Text_Create</Localize>
                            </span>
                        </Link>
                    </div>
                </div>
            </Tables.Main>
        </PageWrapDefault>
    )
};

export default DealersPage;