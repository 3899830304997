import React from "react";
import { Button, Form, Input } from "antd";
import { useMutation } from "@apollo/client";
import { USER_CHANGE_PASSWORD } from "graphql/mutation/user-gql";
import { errorNotification, successNotification } from "components/request-result";
import { Localize } from "components/service";


const UserChangePasswordForm = ({ userID, action }) => {

    const [ form ] = Form.useForm();

    const [ _setUserChangePassword, { loading } ] = useMutation(USER_CHANGE_PASSWORD,
        {
            update(cache, { data }) {

                const {
                    userChangePassword: {
                        label,
                        message
                    }
                } = data;


                successNotification({
                    title: label,
                    description: message
                });

            },
            onError(error) {
                errorNotification(error);
            }
        }
    );

    return (

        <Form
            key="change-password-user"
            layout="vertical"
            className=""
            form={ form }
            onFinish={
                (values) => {
                    _setUserChangePassword({
                        variables: {
                            input: {
                                user_id: userID,
                                new_password: values[ "new-password" ],
                                new_password_confirmation: values[ "confirm-password" ]
                            }
                        }
                    }).catch((error) => {
                        errorNotification(error);
                    });

                    action();
                }
            }
        >
            <Form.Item
                name="new-password"
                label={ <Localize>FORMS.Input_Label_NewPassword</Localize> }
                rules={ [
                    { min: 8, message: <Localize minLength={ 8 }>FORM_RULES.Min_NewPassword</Localize> },
                    { required: true, message: <Localize>FORM_RULES.Required_NewPassword</Localize> }
                ] }
            >
                <Input.Password type="password" name="new-password" />
            </Form.Item>

            <Form.Item
                name="confirm-password"
                label={ <Localize>FORMS.Input_Label_ConfirmPassword</Localize> }
                rules={ [
                    { required: true, message: <Localize>FORM_RULES.Required_ConfirmPassword</Localize> },
                    ({ getFieldValue }) => ({
                        validator(_, value) {
                            if (!value || getFieldValue('new-password') === value)
                            {
                                return Promise.resolve();
                            }
                            return Promise.reject(new Error('The two passwords that you entered do not match!'));
                        },
                    })
                ] }
            >
                <Input.Password type="password" name="confirm-password" />
            </Form.Item>
            <div className="form-btn-holder">

                <Button
                    className="btn-right"
                    type="primary"
                    htmlType="submit"
                    loading={ loading }

                >
                    <Localize>GLOBAL.Button_Text_Save</Localize>
                </Button>
            </div>
        </Form>
    )
}

export default UserChangePasswordForm;