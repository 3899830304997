import UserFields from "../fields";
import { Localize } from "components/service";
import { timeFormat } from "utils/helper-functions";


let usersQuestionColumns = [
    {
        title: <Localize>TABLES.Column_Title_ID</Localize>,
        dataIndex: 'id',
        columnIndex: 'ID',
        align: 'center',
        width: '5%',
        sorter: true,
    },
    {
        title: <Localize>TABLES.Column_Title_Asked</Localize>,
        dataIndex: 'relAuthor',
        columnIndex: 'AUTHOR',
    },
    {
        title: <Localize>TABLES.Column_Title_Question</Localize>,
        dataIndex: 'question',
        columnIndex: 'QUESTION',
    },
    {
        title: <Localize>TABLES.Column_Title_Answered</Localize>,
        dataIndex: 'relAnswer',
        columnIndex: 'ANSWERERED',
    },
    {
        title: <Localize>TABLES.Column_Title_Answer</Localize>,
        dataIndex: 'answer',
        columnIndex: 'ANSWER',
    },
    {
        title: <Localize>TABLES.Column_Title_QuestionDate</Localize>,
        dataIndex: 'created_at',
        columnIndex: 'CREATED_AT',
        align: 'center',
        width: '10%',
        sorter: true,
    },
    {
        // title: 'action',
        dataIndex: 'action',
        align: 'center',
        width: '4%'
    }
];


const usersQuestionData = ({ model, variables }) => {

    if (!model)
    {
        return [];
    }

    return model.map(userQuestion => {

        return {
            key: userQuestion.id,
            id: userQuestion.id,
            relAuthor: <UserFields.Author userQuestion={ userQuestion } />,
            question: <UserFields.Question userQuestion={ userQuestion } questionOrAnswear='question' />,
            relAnswer: <UserFields.Answered userQuestion={ userQuestion } />,
            answer: <UserFields.Question userQuestion={ userQuestion } questionOrAnswear='answer' />,
            created_at: <> { timeFormat(userQuestion?.created_at, 'DD.MM.YYYY') } <br /> { timeFormat(userQuestion?.created_at, 'HH:mm') }</>,
            action: <UserFields.ActionQuestion userQuestion={ userQuestion } variables={ variables } />
        };

    });
};


const UsersQuestionTableHelper = {
    columns: usersQuestionColumns,
    data: usersQuestionData,
}


export default UsersQuestionTableHelper;