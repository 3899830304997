const collectionBasePath = '/collections';


const CollectionConst = {
  basePath     : collectionBasePath
};

export default CollectionConst;









