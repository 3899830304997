import Icons from 'components/icons';


const PostAttachmentField = ({ attachment }) => {


    return (
        <div className="d-flex align-items-center">

            <Icons.Document type={ attachment.extension } style={ { fontSize: '50px' } } />

            <div style={ { width: '100%', overflow: 'hidden' } }>
                <div className="download-file-info">
                    <a href={ attachment.display_url } className="download-file-name" target="_blank" rel="noreferrer">
                        <strong className="file-name">{ attachment.title }</strong>
                        <strong>.{ attachment.extension }</strong>
                    </a>
                </div>
            </div>
        </div>
    );
};

export default PostAttachmentField;