import React from "react";


const UserQuestionField = ({ userQuestion, questionOrAnswear }) => {

    return (
        <>
            { userQuestion?.[ questionOrAnswear ] ?
                <span style={ {
                    display: '-webkit-box',
                    textOverflow: 'ellipsis',
                    overflow: 'hidden',
                    WebkitLineClamp: '5',
                    WebkitBoxOrient: 'vertical'
                } }>
                    { userQuestion?.[ questionOrAnswear ] }
                </span>
                :
                <strong>&mdash;</strong>
            }
        </>
    )
}


export default UserQuestionField;