import { gql } from '@apollo/client';


export const SELLER_CREATE = gql`
    mutation  SellerStatisticCreate ($input: SellerStatisticFieldsInput!) {
        sellerStatisticCreate( input: $input ) {
        label
        message
        sellerStatistic {
                id
                user_id
                dealer_id
                year
                q1
                q2
                q3
                q4
                total
                user {
                    id
                }
                dealer {
                    id
                }
                created_at
                updated_at
            }
        }
    }
`;


export const SELLER_UPDATE = gql`
    mutation  SellerStatisticUpdate ($input: SellerStatisticFieldsInput!) {
        sellerStatisticUpdate( input: $input ) {
        label
        message
        sellerStatistic {
                id
                user_id
                dealer_id
                year
                q1
                q2
                q3
                q4
                total
                user {
                    id
                }
                dealer {
                    id
                }
                created_at
                updated_at
            }
        }
    }
`;


export const SELLER_STATISTIC_DELETE = gql`
    mutation SellerStatisticDelete($id: ID!){
        sellerStatisticDelete(id: $id){
            label
            message
        }
    }
`;