import React, { useState, useEffect } from "react";
import { InputNumber, Button, Form } from 'antd';
import { gqlBuilderWhere, helperFunc } from "utils";
import SellerForms from '../../forms';
import Icons from 'components/icons';
import { DrawerStandart, Localize } from "components/service";
import { initialValFormatted } from "utils/helper-functions";



const SellerTableFilter = ({
    setFilters = () => { },
    setResetSorting = () => { },
    sellersFilters,
    setFilterSellerID,
    filters,
    objOrderBy }) => {

    const [ form ] = Form.useForm();

    const [ filterList, setFilterList ] = useState(null);
    const [ count, setCount ] = useState();

    let filterCount = helperFunc.countFilterList(count);


    /*eslint-disable */
    useEffect(() => {

        if (filters)
        {
            localStorage.setItem("sellersFilters", JSON.stringify({ ...sellersFilters, ...filterList }));
        } else form.resetFields();

    }, [ filters ]);


    useEffect(() => {

        if (sellersFilters)
        {
            setFilters(gqlBuilderWhere(sellersFilters));
            setCount(sellersFilters);
        }

    }, []);
    /*eslint-enable */



    const onChangeSetFilter = (e, name, value) => {

        if (e)
        {
            name = e.target.name
            value = e.target.value
        }

        value.toString().length > 0 ?

            setFilterList({
                ...filterList,
                [ name ]: {
                    column: name,
                    operator: "EQ",
                    value: value
                }
            }) : setFilterList({ ...filterList, [ name ]: {} });
    }


    return (
        <>
            { setFilters &&
                <Form
                    key="filters-form"
                    className="clear-css-prop table-filter-form"
                    layout="vertical"
                    form={ form }
                >
                    <Form.Item
                        className="filter-search"
                        initialValue={ initialValFormatted(sellersFilters, 'SELLER_NUMBER') }
                        name="SELLER_NUMBER"
                        onChange={ (e) => {
                            const value = e.target.value;
                            value.toString().length > 0 ?
                                setFilterSellerID({
                                    SELLER_NUMBER: {
                                        column: 'SELLER_NUMBER',
                                        operator: "LIKE",
                                        value: `${ value }%`
                                    }
                                }) : setFilterSellerID({ SELLER_NUMBER: {} });
                        } } >
                        <InputNumber
                            name="SELLER_NUMBER"
                            placeholder={ Localize({ children: "FORMS.Input_Placeholder_SellerNumber" }).props.children }
                            min={ 3 } max={ 1000000 }
                            controls={ false }
                            style={ { width: '100%' } } />
                    </Form.Item>

                    <DrawerStandart
                        width="287"
                        extraClass={ 'modal-form filters' }
                        modalButton={
                            <Button>
                                <Icons.Filter />
                                <span><Localize>FORMS.Button_Text_ApplyFilters</Localize></span>
                                { filterCount && <span className="filter-count">{ filterCount }</span> }
                            </Button>
                        }
                    >
                        <strong className="modal-title">
                            <Localize>FORMS.Title_Text_Filters</Localize>
                        </strong>

                        <SellerForms.Filter
                            setCount={ setCount }
                            setFilters={ setFilters }
                            setFilterList={ setFilterList }
                            filterList={ filterList }
                            gqlBuilderWhere={ gqlBuilderWhere }
                            sellersFilters={ sellersFilters }
                            onChangeSetFilter={ onChangeSetFilter }
                            form={ form } />

                    </DrawerStandart>

                    <Button
                        type="link"
                        onClick={ () => {
                            setFilterList({});
                            setCount({});
                            setFilters(gqlBuilderWhere({}));
                            setFilterSellerID({});
                            setResetSorting(objOrderBy);
                            localStorage.removeItem("sellersFilters");
                            localStorage.removeItem("sellersSorting");
                        } }
                    >
                        <span><Localize>FORMS.Button_Text_ResetFilters</Localize></span>
                    </Button>
                </Form>
            }
        </>
    );
};

export default SellerTableFilter;