import { Link } from "react-router-dom";
import Post from "components/post";


const CommentPostField = ({ comment }) => {


    return (

        <Link to={ `${ Post.Const.basePath }/${ comment?.post.id }` } className="d-flex align-items-center">
            <span>{ comment?.post?.type === 'news' ? <strong style={ { color: '#E60013' } }>NEWS: </strong> : '' }{ comment?.post?.title ?? <strong>&mdash;</strong> }</span>
        </Link>
    );
};

export default CommentPostField;
