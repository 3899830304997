import { Link } from "react-router-dom";
import { Avatar } from "antd";
import { FileImageOutlined } from "@ant-design/icons";
import CollectionConst from "../collection-const";


const CollectionTitleField = ({ collection }) => {


    return (

        <Link to={ `${ CollectionConst.basePath }/${ collection?.id }` } className="d-flex align-items-center">
            <div style={ { minWidth: '55px' } }>
                <Avatar shape="square" size="large" icon={ collection?.cover_url ? <img src={ collection.cover_url } alt='avatar' /> : <FileImageOutlined /> } />
            </div>

            <span>{ collection?.title ?? <strong>&mdash;</strong> }</span>
        </Link>
    );
};

export default CollectionTitleField;
