import React from "react";
import { useQuery } from "@apollo/client";
import { Avatar } from 'antd';
import { FileImageOutlined } from "@ant-design/icons";
import { GET_POST } from "graphql/query/post-gql";
import { PageWrapDefault } from "components/layout";
import { useActiveMenuItem } from "components/use-hooks";
import PostRoute from 'components/routes/post/post-route';
import Post from "components/post";
import { Localize } from "components/service";



const PostPage = ({ match, history }) => {

    useActiveMenuItem([ "posts" ], [ "posts" ]);

    const postID = match ? parseInt(match.params.id) : null;

    const { data: { post } = {}, loading } = useQuery(GET_POST, {
        skip: !postID,
        variables: {
            id: postID
        }
    });


    const pageNavbar = [
        {
            label: Localize({ children: "NAVBAR_PAGES.Label_Overview" }).props.children,
            path: "overview"
        },
        {
            label: Localize({ children: "NAVBAR_PAGES.Label_Media" }).props.children,
            path: "media"
        },
        {
            label: Localize({ children: "NAVBAR_PAGES.Label_Attachments" }).props.children,
            path: "attachments"
        },
        {
            label: Localize({ children: "NAVBAR_PAGES.Label_Comments" }).props.children,
            path: "comments"
        }
    ];


    const avatar = <Avatar shape="square" size="large" icon={ post?.media[ 0 ]?.thumbnail ? <img src={ post?.media[ 0 ]?.thumbnail } alt='avatar' /> : <FileImageOutlined /> } />


    return (
        <PageWrapDefault
            className="page-post"
            loading={ loading }
            title={ !loading && post ? post.title : 'Page 404' }
            dataExist={ !loading }
            pageNavbar={ pageNavbar }
            avatar={ avatar }
            staticPath={ `${ Post.Const.basePath }/${ postID }` }
        >
            <PostRoute
                post={ post }
                history={ history }
                loading={ loading } />

        </PageWrapDefault>
    );
};

export default PostPage;
