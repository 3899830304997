import React, { useState } from "react";
import { Waypoint } from 'react-waypoint';
import { Form, Radio, Skeleton } from "antd";
import { useQuery } from "@apollo/client";
import { GET_USERS_CURSOR } from "graphql/query/user-gql";
import Icons from "components/icons";
import MultiSelect from 'components/layout/form-elements/multi-select';
import { ModalStandard, cursorPagination, Localize } from "components/service";
import { useDebounce } from "components/use-hooks";
import { Loader } from "components/request-result";


const UserAddRadioSelect = ({
    form,
    rules = false,
    choseBtn = true,
    withDealer = false,
    extraLabel = false,
    initialValue,
    defaultValue,
    model = "usersCursor",
    name = 'user_id',
    onChangeSetFilter = () => { },
    label = Localize({ children: "FORMS.Input_Label_User" }).props.children,
    placeholder = Localize({ children: "FORMS.Title_ChooseUser" }).props.children,
    modalButtonText = <Localize>MODAL.Button_Text_NotAssigned</Localize>
}) => {

    const modelId = Form.useWatch([ name ], form);

    const [ searchModel, setSearchModel ] = useState("");

    let { data, loading, fetchMore } = useQuery(GET_USERS_CURSOR, {
        variables: {
            text: useDebounce(searchModel),
            first: 25,
        },
        fetchPolicy: 'cache-and-network',
        nextFetchPolicy: 'cache-first',
        notifyOnNetworkStatusChange: true
    });


    function sortData(data) {
        const { usersCursor } = data ? data : {};
        const { edges } = usersCursor ? usersCursor : {};

        const sortedEdges = edges?.filter(({ node }) => node.dealer_id !== null);

        const sortedData = {
            ...data,
            usersCursor: {
                ...usersCursor,
                edges: sortedEdges,
                pageInfo: {
                    ...usersCursor?.pageInfo,
                    total: sortedEdges?.length,
                    count: sortedEdges?.length,
                }
            }
        };
        return sortedData;
    }


    let {
        // onPopupScroll,
        fetchMoreAction,
        nodes,
        pageInfo
    } =
        cursorPagination({
            model,
            data: withDealer ? sortData(data) : data,
            label: "name",
            extraLabel,
            loading,
            fetchMore
        });


    nodes = [ { value: null, label: modalButtonText }, ...nodes ];


    return (
        <Form.Item
            initialValue={ initialValue }
            name={ name }
            label={ label }
            rules={ rules && [ { required: true, message: `${ Localize({ children: "FORM_RULES.Required_Choose" }).props.children }` } ] }
            className="multi-select-user"
        >

            <ModalStandard
                width={ 380 }
                extraClass={ 'modal-form' }
                modalButton={
                    <div className="d-flex align-items-center w-100">
                        <span className="select-model-modal-button w-100">
                            { modelId ?
                                nodes?.filter(({ value }) => value === modelId)[ 0 ]?.label || initialValue?.label || defaultValue?.label : modalButtonText }
                            { <Icons.Arrow className='arrow-down' /> }
                        </span>
                        { choseBtn && <strong className="btn-text"><Localize>MODAL.Button_Text_Choose</Localize></strong> }
                    </div>
                }>
                <strong className="modal-title"><Localize>{ placeholder }</Localize> </strong>

                <MultiSelect
                    setSearch={ setSearchModel }
                    placeholder={ Localize({ children: "SEARCH.Input_Placeholder_User" }).props.children }
                >

                    { searchModel && loading ? <Skeleton active={ true } paragraph={ { rows: 6 } } /> :
                        <Radio.Group
                            className='group-radio radio-dark'
                            options={ nodes }
                            defaultValue={ defaultValue?.value }
                            value={ modelId }
                            onChange={
                                (e) => {
                                    let value = e.target.value;
                                    form.setFieldsValue({ [ name ]: value });
                                    onChangeSetFilter(false, name, value);
                                }
                            }
                        />
                    }

                    { pageInfo?.hasNextPage &&

                        <>
                            { loading && <Loader style={ { marginTop: '-30px' } } /> }
                            <Waypoint onEnter={ () => fetchMoreAction() } />
                        </>
                    }

                </MultiSelect>
            </ModalStandard>
        </Form.Item>
    )
};

export default UserAddRadioSelect;