import { useMutation } from "@apollo/client";
import { successNotification } from "components/request-result";
import { USER_SEND_PASSWORD } from "graphql/mutation/user-gql";


const useMutationUserSendPass = () => {


  const [ _setMutationUserSendPass, { loading } ] = useMutation(USER_SEND_PASSWORD,
    {
      update(cache, { data }) {

        const {
          userSendPassword: {
            label,
            message
          }
        } = data;

        successNotification({
          title: label.toUpperCase(),
          description: message
        });
      },
    });

  return {
    _setMutationUserSendPass,
    loadingMutationSendPass: loading,
  }
};

export default useMutationUserSendPass;