import PostFiltersForm from "./post-filters-form";
import PostForm from "./post-form";
import PostMediaForm from "./post-media-form";
import './forms.scss';

const PostForms = {
    Post: PostForm,
    Media: PostMediaForm,
    Filter: PostFiltersForm,
};

export default PostForms;
