import { useMutation } from "@apollo/client";
import { USER_QUESTION_DELETE } from "graphql/mutation/user-gql";
import { successNotification, errorNotification } from "components/request-result";


const useMutationUserQuestionDelete = (id) => {

  const [ _setMutationUserQuestionDelete, { loading } ] = useMutation(USER_QUESTION_DELETE,
    {
      update(cache, { data }) {

        const {
          userQuestionDelete: {
            label,
            message
          }
        } = data;

        cache.evict({
          id: cache.identify({ id, __typename: "UserQuestion" })
        });
        cache.gc();

        successNotification({
          title: label.toUpperCase(),
          description: message
        });
      },
      onError(error) {
        errorNotification(error);
      }
    });

  return {
    _setMutationUserQuestionDelete,
    loadingMutationUserQuestionDelete: loading,
  }
};

export default useMutationUserQuestionDelete;