import { useHistory } from 'react-router-dom';
import { useMutation } from "@apollo/client";
import { DEALER_UPDATE, DEALER_CREATE } from "graphql/mutation/dealer-gql";
import DealerConst from '../dealer-const';
import { successNotification, errorNotification } from "components/request-result";



const useMutationDealerCreateUpdate = (id, redirect = true) => {

  const history = useHistory();

  const DEALER_CREATE_UPDATE = id ? DEALER_UPDATE : DEALER_CREATE;
  const userCreateUpdate = id ? 'dealerUpdate' : 'dealerCreate';

  const [ _setDealerCreateUpdate, { loading } ] = useMutation(DEALER_CREATE_UPDATE,
    {
      update(cache, { data }) {

        const {
          [ userCreateUpdate ]: {
            label,
            message,
          }
        } = data;


        redirect && history.push(DealerConst.basePath);

        successNotification({
          title: label,
          description: message
        });
      },
      onError(error) {
        errorNotification(error);
      }
    }
  );


  return {
    _setDealerCreateUpdate,
    loadingMutationDealerCreateUpdate: loading,
  }
};

export default useMutationDealerCreateUpdate;

