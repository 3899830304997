import React from "react";
import { useHistory } from 'react-router-dom';
import { Dropdown } from 'antd';
import { CloseOutlined, CloseCircleOutlined, EllipsisOutlined } from '@ant-design/icons';
import { userUseMutation } from '../hooks';
import UserAction from "../actions";
import UserConst from '../users-const';
import { useMe } from "components/use-hooks";
import Dealer from 'components/dealer';
import { EntityRemoveButton, ModalStandard, Localize } from 'components/service';
import Icons from 'components/icons';



const UserActionField = ({ user, actionFieldsHide, entityRemoveUser, variables }) => {

    const { me } = useMe();

    const history = useHistory();

    const {
        _setUserCreateUpdate,
    } = userUseMutation.createUpdate(user?.id);

    const {
        _setMutationUserDelete,
        loadingMutationUserDelete,
    } = userUseMutation.delete(user?.id);



    const {
        _setMutationUserRemove,
        loadingMutationUserRemove,
    } = Dealer.Hooks.remove(variables);


    let items = [
        {
            key: 'edit',
            label: <Localize>MENUS_DROP.Label_Edit</Localize>,
            icon: <Icons.Edit />,
            onClick: () => history.push(`${ UserConst.basePath }/${ user?.id }`)
        },
        {
            key: 'activeBlock',
            disabled: me.id === user?.id,
            label: `${ user?.status === 'active' ? `${ Localize({ children: "MENUS_DROP.Label_Block" }).props.children }` : `${ Localize({ children: "MENUS_DROP.Label_Active" }).props.children }` }`,
            icon: user?.status === 'active' ? <CloseCircleOutlined style={ { fontSize: '17px' } } /> : <Icons.Crafts.MPControl />,
            onClick: () => {

                _setUserCreateUpdate({
                    variables: {
                        input: {
                            id: user?.id,
                            status: user?.status === 'active' ? 'block' : 'active',
                        }
                    }
                });
            }
        },
        {
            key: 'sendPass',
            icon:
                <ModalStandard
                    width={ 380 }
                    modalButton={
                        <span style={ { display: 'flex', alignItems: 'center' } } >
                            <Icons.SendPass /> { `${ user?.sendPassword?.created_at ?
                                Localize({ children: "MENUS_DROP.Label_SendPass" }).props.children
                                :
                                Localize({ children: "MENUS_DROP.Label_SendMail" }).props.children
                                }` }
                        </span> }>
                    <UserAction.Fields.SendPass userID={ user?.id } user={ user } />
                </ModalStandard>
        },
        {
            key: 'delete',
            disabled: /* me?.id === user?.id */ true,
            icon:
                <> {/* me?.id === user?.id */ true ?
                    <span style={ { display: 'flex', alignItems: 'center' } } ><Icons.Delete /> <Localize>MENUS_DROP.Label_Delete</Localize></span> :

                    <EntityRemoveButton
                        modalButton={
                            <span style={ { display: 'flex', alignItems: 'center' } } ><Icons.Delete /> <Localize>MENUS_DROP.Label_Delete</Localize></span>
                        }
                        nameEntity={ Localize({ children: "ENTITY.Modal_Title_User" }).props.children }
                        dataNameEntity={ user.name }
                        loading={ loadingMutationUserDelete }
                        deleteMutation={ _setMutationUserDelete }
                        variables={ {
                            id: user?.id,
                        } } /> }
                </>
        } ]


    items = items.filter(({ key }) => !actionFieldsHide?.includes(key))


    return (
        <>
            { entityRemoveUser ?
                <EntityRemoveButton
                    modalButton={ <CloseOutlined className="icon-delete" /> }
                    nameEntity={ Localize({ children: "ENTITY.Modal_Title_User" }).props.children }
                    okText={ Localize({ children: "GLOBAL.Button_Text_Remove" }).props.children }
                    dataNameEntity={ user.name }
                    loading={ loadingMutationUserRemove }
                    deleteMutation={ _setMutationUserRemove }
                    variables={ { user_id: user?.id, dealer_id: user.dealer_id } } />
                :
                <Dropdown
                    // trigger={['click']}
                    placement="bottomRight"
                    menu={ { items } }
                    overlayClassName="drop-more-menu"
                    arrow
                >
                    <EllipsisOutlined className="btn-more" />
                </Dropdown> }
        </>

    )
};

export default UserActionField;

