import { gql } from '@apollo/client';


export const GET_SELLER_STATISTICS = gql`
    query GetSellerStatistics(
        $first: Int,
        $page: Int,
        $orderBy: [QuerySellerStatisticsOrderByOrderByClause!],
        $where: QuerySellerStatisticsWhereWhereConditions
        $whereUser: QuerySellerStatisticsWhereUserWhereHasConditions
    ){
      sellerStatistics(
            first: $first,
            page: $page,
            orderBy: $orderBy,
            where: $where,
            whereUser: $whereUser
        ){
            paginatorInfo{
                total
                count
                currentPage
                perPage
                lastPage
            }
            data {
              id
              user_id
              dealer_id
              year
              q1
              q2
              q3
              q4
              total
              user {
                id
                name
                seller_number
                salutation
              }
              dealer {
                company_name
              }
              created_at
              updated_at
            }
        }
    }
`;


