const postsBasePath = '/posts';


const PostConst = {
  basePath     : postsBasePath
};

export default PostConst;









