import SellerEditForm from "./seller-edit-form";
import SellerFiltersForm from "./seller-filters-form";


import './forms.scss';

const SellerForms = {
    Edit: SellerEditForm,
    Filter: SellerFiltersForm,
};

export default SellerForms;
