import React from 'react';
import { Switch, Route, useRouteMatch } from 'react-router-dom';
import { Pages } from "components/pages";

export default function PostsRoute() {

    const { path } = useRouteMatch();

    return (
        <Switch>
            <Route path={ `${ path }` } exact>
                <Pages.Posts />
            </Route>
            <Route path={ `${ path }/page/:pageNum` } exact>
                <Pages.Posts />
            </Route>
            <Route path={ `${ path }/page` } exact>
                <Pages.Posts />
            </Route>
            <Route path={ `${ path }/:id` } >
                { ({ match, history }) => <Pages.Post match={ match } history={ history } exact /> }
            </Route>
        </Switch>
    );
}

