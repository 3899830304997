import React from 'react';
import { Form, Select } from "antd";
import Icons from "components/icons";
import { Localize } from "components/service";


const PostStatusSelect = ({
    disabled = false,
    rules = false,
    initialValue,
    form,
    mode = '',
    name = 'status',
    onChangeSetFilter = () => { },
    label = Localize({ children: "FORMS.Input_Label_Status" }).props.children,
    placeholder = Localize({ children: "FORMS.Input_Placeholder_Choose" }).props.children
}) => {


    return (
        <Form.Item
            name={ name }
            label={ label }
            initialValue={ initialValue }
            rules={ rules && [ { required: true, message: `${ Localize({ children: "FORM_RULES.Required_Choose" }).props.children } ${ label }` } ] }
        >
            <Select
                showSearch
                suffixIcon={ <Icons.Arrow /> }
                mode={ mode }
                className="select-serch-input"
                disabled={ disabled }
                placeholder={ placeholder }
                popupClassName="select-serch-dropdown"
                optionFilterProp="value"
                onChange={
                    (value) => {
                        form.setFieldsValue({ [ name ]: value });
                        onChangeSetFilter(false, name, value);
                    } }
            >

                <Select.Option key="public" value="public"><Localize>FORMS.Select_OptionLabel_Public</Localize> </Select.Option>
                <Select.Option key="draft" value="draft"><Localize>FORMS.Select_OptionLabel_Draft</Localize> </Select.Option>
                <Select.Option key="trash" value="trash"><Localize>FORMS.Select_OptionLabel_Trash</Localize> </Select.Option>

            </Select>
        </Form.Item>
    );
}

export default PostStatusSelect;


