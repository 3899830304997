import React from "react";
import { useQuery } from "@apollo/client";
import { GET_DEALER } from "graphql/query/dealer-gql";
import { useActiveMenuItem } from "components/use-hooks";
import DealerRoute from "components/routes/dealer/dealer-route";
import { PageWrapDefault } from "components/layout";
import { Localize } from "components/service";


const DealerPage = ({ match, history }) => {

    useActiveMenuItem([ "dealers" ], [ "dealers" ]);

    const path = history.location.pathname.split('/').pop()
    const dealerID = match ? parseInt(match.params.id) : null;


    const { data: { dealer } = {}, loading } = useQuery(GET_DEALER, {
        skip: !dealerID,
        variables: {
            id: dealerID
        }
    });

    const pageNavbar = [
        {
            label: Localize({ children: "NAVBAR_PAGES.Label_Overview" }).props.children,
            path: "overview"
        },

        {
            label: Localize({ children: "NAVBAR_PAGES.Label_Users" }).props.children,
            path: "users"
        } ];


    return (
        <PageWrapDefault
            className="page-dealer"
            loading={ loading }
            title={ path === 'create' ? <Localize>PAGES.Title_CreateDealer</Localize> : !loading && dealer ? dealer.company_name : 'Page 404' }
            dataExist={ path === 'create' ? true : !loading && dealer }
            pageNavbar={ !loading && dealer ? pageNavbar : false }
            staticPath={ `/dealers/${ dealerID }` }
        >
            <DealerRoute
                dealer={ dealer }
                history={ history }
                loading={ loading } />

        </PageWrapDefault>
    );
};

export default DealerPage;
