import React from 'react';
import Icon from '@ant-design/icons';


const UsersSvg = () => (
	<svg fill="currentColor"  width="1em" height="1em" viewBox="0 0 48 48" strokeWidth="1">
		<path d="M35.5 22.3c3-1.1 5.2-4 5.2-7.4 0-4.4-3.5-7.9-7.9-7.9s-7.9 3.6-7.9 7.9c0 3.4 2.2 6.3 5.2 7.4-2.3.5-4.5 1.7-6.4 3.5-1.8-1.7-4-2.9-6.3-3.5 3-1.1 5.2-4 5.2-7.5 0-4.4-3.5-7.9-7.9-7.9-4.3 0-7.9 3.6-7.9 7.9 0 3.4 2.2 6.4 5.2 7.5C5.6 23.8.6 30.6.6 38.8c0 .7.5 1.3 1.2 1.3s1.2-.6 1.3-1.2c.1-7.8 5.3-14.2 11.7-14.2 6.4 0 11.7 6.4 11.7 14.2 0 .7.6 1.2 1.2 1.2.7 0 1.2-.6 1.2-1.3 0-4.2-1.4-8.1-3.6-11 2.1-2 4.7-3.2 7.4-3.2 6.4 0 11.7 6.2 11.7 13.7 0 .7.6 1.2 1.2 1.2.7 0 1.2-.6 1.2-1.3.2-7.8-4.8-14.4-11.3-15.9zM9.4 14.9c0-3 2.4-5.4 5.4-5.4s5.4 2.4 5.4 5.4-2.4 5.4-5.4 5.4-5.4-2.4-5.4-5.4zm18 0c0-3 2.4-5.4 5.4-5.4s5.4 2.4 5.4 5.4-2.4 5.4-5.4 5.4-5.4-2.4-5.4-5.4z" />
	</svg>
);

const UsersIcon = () => {
	return <Icon component={ UsersSvg } />;
};


export default UsersIcon;