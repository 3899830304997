import CollectionFields from "../fields";
import { Localize } from "components/service";


let collectionColumns = [
    {
        title: <Localize>TABLES.Column_Title_ID</Localize>,
        dataIndex: 'id',
        columnIndex: 'ID',
        align: 'center',
        width: '5%'
    },
    {
        title: <Localize>TABLES.Column_Title_Title</Localize>,
        dataIndex: 'title',
        columnIndex: 'TITLE',
        sorter: true
    },
    {
        title: <Localize>TABLES.Column_Title_Author</Localize>,
        dataIndex: 'author',
        columnIndex: 'AUTHOR_ID',
        sorter: true
    },
    {
        title: <Localize>TABLES.Column_Title_Posts</Localize>,
        dataIndex: 'count_posts',
        columnIndex: 'COUNT_POSTS',
        align: 'center',
        sorter: true
    },

    {
        title: <Localize>TABLES.Column_Title_Followers</Localize>,
        dataIndex: 'count_followers',
        columnIndex: 'COUNT_FOLLOWERS',
        align: 'center',
        sorter: true
    },

    {
        title: <Localize>TABLES.Column_Title_Status</Localize>,
        dataIndex: 'status',
        columnIndex: 'STATUS',
        sorter: true
    },

    {
        title: <Localize>TABLES.Column_Title_CreateDate</Localize>,
        dataIndex: 'created_at',
        columnIndex: 'CREATED_AT',
        sorter: true
    },

    {
        // title: 'action',
        dataIndex: 'action',
        align: 'center',
        width: '4%'
    }
];


const collectionData = ({ model, actionFieldsHide, entityRemoveCollection, variables }) => {

    if (!model)
    {
        return [];
    }

    return model.map(collection => {

        return {
            key: collection.id,
            id: collection.id,
            title: <CollectionFields.Title collection={ collection } />,
            author: <CollectionFields.Info collection={ collection } />,
            count_posts: collection.count_posts ?? 0,
            count_followers: collection.count_followers ?? 0,
            status: collection.status ?? <strong>&mdash;</strong>,
            created_at: collection.created_at ?? <strong>&mdash;</strong>,
            action: <CollectionFields.Action collection={ collection } />
        };

    });
};


const CollectionTableHelper = (columnsHide = []) => {

    collectionColumns = collectionColumns.filter(({ dataIndex }) => !columnsHide?.includes(dataIndex))

    return {
        columns: collectionColumns,
        data: collectionData,
    }
}


export default CollectionTableHelper;