const usersBasePath = '/users';


const UserConst = {
  basePath     : usersBasePath
};

export default UserConst;









