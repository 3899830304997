import useMutationDealerCreateUpdate from './dealer-use-mutation-create-update';
import useMutationDealerDelete from "./dealer-use-mutation-delete";
import useMutationDealerRemove from "./dealer-use-mutation-remove";



export const dealerUseMutation = {
  createUpdate: useMutationDealerCreateUpdate,
  delete: useMutationDealerDelete,
  remove: useMutationDealerRemove,
}