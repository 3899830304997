import DealerFields from "../fields";
import { Localize } from "components/service";


const dealerColumns = [
    {
        title: <Localize>TABLES.Column_Title_ID</Localize>,
        dataIndex: 'id',
        columnIndex: 'ID',
        sorter: true,
        align: 'center',
        width: '5%'
    },
    {
        title: <Localize>TABLES.Column_Title_CompanyName</Localize>,
        dataIndex: 'company_name',
        columnIndex: 'COMPANY_NAME',
        sorter: true
    },
    {
        title: <Localize>TABLES.Column_Title_DealerNumber</Localize>,
        dataIndex: 'dealer_number',
        columnIndex: 'DEALER_NUMBER',
        sorter: true
    },
    {
        title: <Localize>TABLES.Column_Title_City</Localize>,
        dataIndex: 'city',
        columnIndex: 'CITY',
        sorter: true
    },
    {
        title: <Localize>TABLES.Column_Title_Street</Localize>,
        dataIndex: 'street',
        columnIndex: 'STREET',
        sorter: true
    },
    {
        title: <Localize>TABLES.Column_Title_ZipCode</Localize>,
        dataIndex: 'zip_code',
        columnIndex: 'ZIP_CODE',
        sorter: true
    },
    {
        title: <Localize>TABLES.Column_Title_Region</Localize>,
        dataIndex: 'region',
        columnIndex: 'REGION',
        sorter: true
    },
    {
        title: <Localize>TABLES.Column_Title_District</Localize>,
        dataIndex: 'district',
        columnIndex: 'DISTRIC',
        sorter: true
    },
    {
        // title: 'action',
        dataIndex: 'action',
        align: 'center',
        width: '4%'
    }
];


const dealerData = ({ model, variables }) => {

    if (!model)
    {
        return [];
    }

    return model.map(dealer => {

        return {
            key: dealer.id,
            id: dealer.id ?? <strong>&mdash;</strong>,
            company_name: <DealerFields.Info dealer={ dealer } />,
            dealer_number: dealer.dealer_number ?? <strong>&mdash;</strong>,
            city: dealer.city ?? <strong>&mdash;</strong>,
            street: dealer.street ?? <strong>&mdash;</strong>,
            zip_code: dealer.zip_code ?? <strong>&mdash;</strong>,
            region: dealer.region ?? <strong>&mdash;</strong>,
            district: dealer.district ?? <strong>&mdash;</strong>,
            action: <DealerFields.Action variables={ variables } dealerId={ dealer.id } dealerName={ dealer.company_name } />
        };

    });
};


const DealerTableHelper = {
    columns: dealerColumns,
    data: dealerData,
}

export default DealerTableHelper;