import DealerOverviewSubPage from "./dealer-owerview-sub-page";
import DealerUsersSubPage from "./dealer-users-sub-page";


const SubPage = {
    OverView: DealerOverviewSubPage,
    Users: DealerUsersSubPage,
};

export default SubPage;
