const dealerBasePath = '/dealers';


const DealerConst = {
  basePath     : dealerBasePath
};

export default DealerConst;









