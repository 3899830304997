import { Link } from 'react-router-dom';
import { Avatar } from "antd";
import { AreaChartOutlined } from "@ant-design/icons";
import HashtagConst from "../hashtag-const";



const HashtagTitleField = ({ hashtag }) => {

    return (

        <Link to={ `${ HashtagConst.basePath }/${ hashtag?.slug }` } className="d-flex align-items-center">
            <div style={ { minWidth: '55px' } }>
                <Avatar shape="square" size="large" icon={ hashtag?.cover_url ? <img src={ hashtag.cover_url } alt='avatar' /> : <AreaChartOutlined /> } />
            </div>

            <span>{ hashtag?.slug ?? <strong>&mdash;</strong> }</span>
        </Link>
    );
};

export default HashtagTitleField;
