import CommentAuthorField from "./comment-author-field";
import CommentPostField from "./comment-post-field";
import CommentContentField from "./comment-content-field";
import CommentActionField from './comment-action-field';



const CommentFields = {
    Author: CommentAuthorField,
    Post: CommentPostField,
    Content: CommentContentField,
    Action: CommentActionField,
}

export default CommentFields;