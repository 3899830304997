import { useQuery } from "@apollo/client";
import { GET_POSTS_TOP } from "graphql/query/post-gql";
import { GET_USERS_POSTS_TOP } from "graphql/query/user-gql";
import { GET_LOG_GRAPHIC } from "graphql/query/chart-gql";
import { useActiveMenuItem } from "components/use-hooks";
import { PageWrapDefault } from "components/layout";
import User from "components/user";
import Post from "components/post";
import { Localize } from "components/service";

import Chart from "components/chart";
import Icons from "components/icons";

const Dashboard = () => {
  useActiveMenuItem(["dashboard"], ["dashboard"]);

  const { data: { postsTop } = {}, loading: loadingPosts } =
    useQuery(GET_POSTS_TOP);

  const { data: { users } = {}, loading: loadingUsers } =
    useQuery(GET_USERS_POSTS_TOP);

  let { data: { logGraphic } = {}, loading: loadingCharts } =
    useQuery(GET_LOG_GRAPHIC);

  logGraphic = logGraphic && Array.from(Object.entries(logGraphic));
  logGraphic && logGraphic.shift();

  const loading = loadingPosts && loadingUsers && loadingCharts;

  function getTranslationChartTitle(name) {
    switch (name) {
      case "attachments_count":
        return <Localize>DASHBOARD.Chart_Title_AttachmentsCount</Localize>;

      case "collections_count":
        return <Localize>DASHBOARD.Chart_Title_CollectionsCount</Localize>;

      case "comments_count":
        return <Localize>DASHBOARD.Chart_Title_CommentsCount</Localize>;

      case "hashtags_count":
        return <Localize>DASHBOARD.Chart_Title_HashtagsCount</Localize>;

      case "media_count":
        return <Localize>DASHBOARD.Chart_Title_MediaCount</Localize>;

      case "posts_count":
        return <Localize>DASHBOARD.Chart_Title_PostsCount</Localize>;

      case "users_count":
        return <Localize>DASHBOARD.Chart_Title_UsersCount</Localize>;

      case "users_visit_count":
        return <Localize>DASHBOARD.Chart_Title_UsersVisitCount</Localize>;

      default:
        return "";
    }
  }

  return (
    <PageWrapDefault
      loading={loading}
      dataExist={!loading}
      className="page-dashboard"
      title={<Localize>PAGES.Title_Dashboard</Localize>}
    >
      <div className="row-grid col-lg-2">
        <div className="col-left">
          <h4>
            <Localize>PAGES.SubTitle_TopPosts</Localize>
          </h4>

          {!postsTop?.data.length ? (
            <div className="d-flex flex-column justify-content-center align-items-center h-100 fz-200">
              <Icons.NoContent type="posts" />
              <p className="fz-20">
                <Localize>PAGES.Text_NoTopPosts</Localize>
              </p>
            </div>
          ) : (
            <ul className="posts-top-list">
              {postsTop?.data?.map((post) => (
                <li key={post.id} style={{ marginBottom: "15px" }}>
                  <Post.Fields.Top post={post} loadingPosts={loadingPosts} />
                </li>
              ))}
            </ul>
          )}
        </div>
        <div className="col-right">
          <h4>
            <Localize>PAGES.SubTitle_TopAuthors</Localize>
          </h4>

          {!users?.data.length ? (
            <div className="d-flex flex-column justify-content-center align-items-center h-100 fz-200">
              <Icons.NoContent type="userFollowing" />
              <p className="fz-20">
                <Localize>PAGES.Text_NoTopAuthors</Localize>
              </p>
            </div>
          ) : (
            <ul className="users-top-list">
              {users?.data?.map((user) => (
                <li key={user.id} style={{ marginBottom: "15px" }}>
                  <User.Fields.TopPosts
                    user={user}
                    loadingUsers={loadingUsers}
                  />
                </li>
              ))}
            </ul>
          )}
        </div>
      </div>

      <br />

      <ul className="charts-list row-grid col-lg-3 col-md-2">
        {logGraphic
          ?.filter(([_, data]) => data !== null)
          ?.map(([nameCounter, data]) => {
            return (
              <li key={nameCounter}>
                <div className="header">
                  <strong className="title">
                    {getTranslationChartTitle(nameCounter)}
                  </strong>
                  <strong className="counter">
                    <Localize>DASHBOARD.Chart_Count_Total</Localize>:{" "}
                    {data[data.length - 1].total}
                  </strong>
                </div>

                <Chart data={data} />
              </li>
            );
          })}
      </ul>
    </PageWrapDefault>
  );
};

export default Dashboard;
