import { gql } from '@apollo/client';


export const GET_ME = gql`
  query GetMe {
    me {
      id
      status
      nickname
      name
      email
      locale
      role
      initials
      gender
      avatar
      about
    }
  }
`;

export const GET_USERS = gql`
    query GetUsers(
        $dealer_id: ID,
        $text: String,
        $first: Int,
        $page: Int,
        $orderBy: [QueryUsersOrderByRelationOrderByClause!],
        $where: QueryUsersWhereWhereConditions
    ){
        users(
            dealer_id: $dealer_id,
            text: $text,
            first: $first,
            page: $page,
            orderBy: $orderBy,
            where: $where
        ){
            paginatorInfo{
                total
                count
                currentPage
                perPage
                lastPage
            }
            data {
                id
                name
                initials
                nickname
                email
                status
                locale
                role
                gender
                avatar
                about
                itsMe
                me_following
                is_online
                last_active_date
                last_visit_at
                dealer_id
                dealer {
                  id
                  company_name
                  dealer_number
                }
                salutation
                job_type
                seller_number
                phone
                sendPassword {
                  id
                  user_id
                  send_user_id
                  message
                  created_at
                }
                date_birth_at
                date_entrance_at
                created_at
                updated_at
              }
        }
    }
`;

export const GET_USERS_POSTS_TOP = gql`
    query GetUsers{
      users(
        first: 5
        page: 1
        orderBy: [{
          order: DESC
          user_counters: {
            aggregate: SUM
            column: PUBLIC_POSTS
          }
        } ]
       ) {
        data {
          id
          avatar
          name
          nickname
          initials
          user_counters {
            public_posts
          }
        }
      }
   }
`;


export const GET_USERS_CURSOR = gql`
    query UsersCursor(
        $dealer_id: ID,
        $text: String
        $where: QueryUsersCursorWhereWhereConditions
        $first: Int
        $after: String
    ) {
        usersCursor(
        dealer_id: $dealer_id,
        text: $text
        where: $where
        first: $first
        after: $after
        ) {
        pageInfo {
            hasNextPage
            hasPreviousPage
            startCursor
            endCursor
            total
            count
            currentPage
            lastPage
        }
        edges {
            cursor
              node {
                id
                dealer_id
                seller_number
                name
                role
             }
           }
        }
    }
`;


export const GET_USER = gql`
    query GetUser(
        $id: ID,
    ) {
        user(
            id: $id,
        ){
            id
            name
            initials
            nickname
            email
            status
            locale
            role
            gender
            avatar
            about
            itsMe
            me_following
            is_online
            last_active_date
            last_visit_at
            dealer_id
            dealer {
              id
              company_name
              dealer_number
              street
              zip_code
              city
              region
              district
              created_at
              updated_at
            }
            salutation
            job_type
            seller_number
            phone
            sendPassword {
              id
              user_id
              send_user_id
              message
              created_at
            }
            created_at
            updated_at
            date_birth_at
            date_entrance_at
        }
    }
`;


export const GET_USER_QUESTION = gql`
    query GetUserQuestion( $id: ID ) {
      userQuestion( id: $id) {
        id
        question
        author_id
        answer
        answer_id
        relAuthor {
          id
          name
          nickname
          email
          phone
          dealer {
            id
            company_name
            dealer_number
          }
        }
        relAnswer {
          id
          name
          nickname
        }
        created_at
      }
    }
`;


export const GET_USER_QUESTIONS = gql`
    query GetUserQuestions(
        $text: String,
        $first: Int,
        $page: Int,
        $orderBy: [QueryUserQuestionsOrderByOrderByClause!],
        $where: QueryUserQuestionsWhereWhereConditions
    ){
      userQuestions(
            text: $text,
            first: $first,
            page: $page,
            orderBy: $orderBy,
            where: $where
        ){
            paginatorInfo{
                total
                count
                currentPage
                perPage
                lastPage
            }
            data {
              id
              question
              author_id
              answer
              answer_id
              relAuthor {
                id
                name
                nickname
                email
                phone
                dealer {
                  id
                  company_name
                  dealer_number
                }
              }
              relAnswer {
                id
                name
                nickname
              }
              created_at
            }
        }
    }
`;


export const GET_USER_QUESTIONS_NOT_ANSWERED = gql`
    query GetUserQuestionsNotAnswered($where: QueryUserQuestionsWhereWhereConditions) {
      userQuestions(where: $where ){
            data {
              id
            }
        }
    }
`;