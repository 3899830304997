import SellerFields from "../fields";
import { Localize } from "components/service";


const sellerColumns = [
    {
        title: <Localize>TABLES.Column_Title_User</Localize>,
        dataIndex: 'user',
        columnIndex: 'USER',
    },
    {
        title: <Localize>TABLES.Column_Title_SellerNumber</Localize>,
        dataIndex: 'seller_number',
        columnIndex: 'SELLER_NUMBER',
        // sorter: true
    },
    {
        title: <Localize>TABLES.Column_Title_Year</Localize>,
        dataIndex: 'year',
        columnIndex: 'YEAR',
        sorter: true
    },
    {
        title: 'Q1',
        dataIndex: 'q1',
        columnIndex: 'Q1',
        sorter: true
    },
    {
        title: 'Q2',
        dataIndex: 'q2',
        columnIndex: 'Q2',
        sorter: true
    },
    {
        title: 'Q3',
        dataIndex: 'q3',
        columnIndex: 'Q3',
        sorter: true
    },
    {
        title: 'Q4',
        dataIndex: 'q4',
        columnIndex: 'Q4',
        sorter: true
    },
    {
        title: <Localize>TABLES.Column_Title_Total</Localize>,
        dataIndex: 'total',
        columnIndex: 'TOTAL',
        align: 'center',
        sorter: true
    },
    {
        // title: 'action',
        dataIndex: 'action',
        align: 'center',
        width: '4%'
    }
];


const sellerData = ({ model, variables }) => {

    if (!model)
    {
        return [];
    }

    return model.map(seller => {

        return {
            key: seller.id,
            user: <SellerFields.Info seller={ seller } variables={ variables } />,
            seller_number: seller?.user?.seller_number,
            year: seller.year,
            q1: <SellerFields.Quarter id={ seller.id } name="q1" initialValue={ seller?.q1 } />,
            q2: <SellerFields.Quarter id={ seller.id } name="q2" initialValue={ seller?.q2 } />,
            q3: <SellerFields.Quarter id={ seller.id } name="q3" initialValue={ seller?.q3 } />,
            q4: <SellerFields.Quarter id={ seller.id } name="q4" initialValue={ seller?.q4 } />,
            total: seller.total,
            action: <SellerFields.Remove variables={ variables } sellerID={ seller.id } sellerName={ seller?.user?.name } />
        };

    });
};


const SellerTableHelper = {
    columns: sellerColumns,
    data: sellerData,
}

export default SellerTableHelper;