import { Link } from 'react-router-dom';
import { Avatar } from 'antd';
import { postUseMutation } from './../hooks/index';
import { LinkedMention } from 'components/service';
import Icons from 'components/icons';
import { useMe } from "components/use-hooks";



const PostCommentField = ({ postID, comments }) => {

    const { me } = useMe();

    const {
        _setMutationPostCommentDelete,
        // loadingMutationPostCommentDelete,
    } = postUseMutation.commentDelete(postID);

    return comments?.edges?.map(({ node }) => {

        const { id, author, content, created_at } = node;

        return (

            <li key={ id } className="d-flex" style={ { padding: '15px 0', borderBottom: '1px solid #ccc' } }>

                <Link style={ { minWidth: '55px' } } to={ `/users/${ author.id }` }>
                    <Avatar shape="circle" size="large" icon={ !!author?.avatar ? <img src={ `${ author.avatar }` } alt='avatar' /> : author?.initials } />
                </Link>

                <div className="comment-description">
                    <div>
                        <Link to={ `/users/${ author.id }` }><strong>{ author.nickname }</strong></Link> &nbsp;
                        <LinkedMention searchSigns={ [ '@', '^', '#' ] }>
                            { content }
                        </LinkedMention>
                    </div>
                    <span className="comment-date">{ created_at }</span>
                </div>

                <div className="comment-actions" style={ { marginLeft: 'auto', fontSize: '20px', cursor: 'pointer' } }>
                    { me.role === "admin" &&
                        <span
                            className="comment-delete-button"
                            onClick={ () => _setMutationPostCommentDelete({ variables: { id } }) }>
                            <Icons.Delete />
                        </span>
                    }
                </div>
            </li>
        );
    })
};

export default PostCommentField;