import React, { useState } from "react";
import { useRouteMatch } from "react-router-dom";
import { Button } from 'antd';
import { PlusOutlined } from '@ant-design/icons';

import { GET_SELLER_STATISTICS } from 'graphql/query/seller-gql';

import Seller from 'components/seller';

import { useBreadCrumbs, useDebounce } from "components/use-hooks";
import { Tables } from "components/layout";
import { Localize, ModalStandard } from "components/service";
import { gqlBuilderWhere } from "utils";


const sellerBasePath = Seller.Const.basePath;
const tableHelper = Seller.Helpers.TableMain;


const AnalyticSallersSubPage = ({ dealer }) => {

    const breadcrumbs = [
        {
            label: Localize({ children: "BREADCRUMBS.Label_Analytics" }).props.children,
            path: `/analytics/sales`
        },
        {
            label: Localize({ children: "BREADCRUMBS.Label_Sales" }).props.children,
            path: `/analytics/sales`
        },
    ]

    useBreadCrumbs(breadcrumbs);

    const routeUrl = `/analytics${ sellerBasePath }`;
    let match = useRouteMatch(`${ routeUrl }/page/:pageNum`);

    const objOrderBy = [ { column: "ID", order: "DESC" } ];

    const [ variables, setVariables ] = useState({});
    const [ filters, setFilters ] = useState();
    const [ filterSellerID, setFilterSellerID ] = useState();
    const [ resetSorting, setResetSorting ] = useState(false);

    const sellersFilters = JSON.parse(localStorage.getItem("sellersFilters"));
    const sellersSorting = JSON.parse(localStorage.getItem("sellersSorting"));


    return (

        <Tables.Main
            model="sellerStatistics"
            query={ GET_SELLER_STATISTICS }
            varSet={ { perPage: 30, dealer_id: dealer?.id, whereUser: useDebounce(gqlBuilderWhere(filterSellerID)) } }
            routeUrl={ routeUrl }
            currentPage={ match ? parseInt(match.params.pageNum) : 1 }
            objFilter={ filters || gqlBuilderWhere(sellersFilters) }
            objOrderBy={ sellersSorting || objOrderBy }
            sortingName="sellersSorting"
            tableHelper={ tableHelper }
            resetSorting={ resetSorting }
            setVariables={ setVariables }
        >
            <div className="table-action-bar">

                <div className="col">
                    <Seller.Filters.Table
                        filters={ filters }
                        setFilters={ setFilters }
                        sellersFilters={ sellersFilters }
                        setResetSorting={ setResetSorting }
                        setFilterSellerID={ setFilterSellerID }
                        objOrderBy={ objOrderBy }
                    />
                </div>

                <div className="col">

                    <ModalStandard
                        width={ 380 }
                        extraClass={ 'modal-form' }
                        modalButton={
                            <Button
                                type="primary">
                                <span><PlusOutlined /> <Localize>MODAL.Button_Text_AddSeller</Localize></span>
                            </Button>
                        }>
                        <strong className="modal-title"><Localize>FORMS.Title_AddSeller</Localize></strong>

                        <Seller.Forms.Edit variables={ variables } />
                    </ModalStandard>
                </div>
            </div>
        </Tables.Main>

    )
};

export default AnalyticSallersSubPage;