import { Link } from "react-router-dom";
import { Avatar } from "antd";
import User from "components/user";


const CommentAuthorField = ({ comment }) => {

    return (

        <Link to={ `${ User.Const.basePath }/${ comment?.author.id }` } className="d-flex align-items-center">
            <div style={ { minWidth: '55px' } }>
                <Avatar shape="circle" size="large" icon={ !!comment?.author?.avatar ? <img src={ `${ comment?.author.avatar }` } alt='avatar' /> : comment?.author?.initials } />
            </div>
            <div>
                <span>{ `${ comment?.author.name }` }</span> <br />
                <span>{ `${ comment?.author.nickname }` }</span>
            </div>

        </Link>
    );
};

export default CommentAuthorField;
