import React from "react";

import Post from 'components/post';

import { useBreadCrumbs } from "components/use-hooks";
import { Tables } from "components/layout";
import { Localize } from "components/service";

const postBasePath = Post.Const.basePath;


const PostAttachmentsSubPage = ({ post }) => {

    const tableHelper = Post.Helpers.TableAttachments;

    const breadcrumbs = [
        {
            label: Localize({ children: "BREADCRUMBS.Label_Posts" }).props.children,
            path: `${ postBasePath }`
        },
        {
            label: post?.title ?? <strong>the post has no title</strong>,
            path: `${ postBasePath }/${ post?.id }`
        },

        {
            label: Localize({ children: "BREADCRUMBS.Label_Attachments" }).props.children,
            path: `${ postBasePath }/${ post?.id }/attachments`
        },
    ]

    useBreadCrumbs(breadcrumbs);


    return (


        <Tables.Mock
            mockData={ post?.attachments }
            tableHelper={ tableHelper }
        >
            <div className="table-action-bar">

                <div className="col"> </div>

                <div className="col"> </div>
            </div>
        </Tables.Mock>
    )
};

export default PostAttachmentsSubPage;