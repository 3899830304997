import React from "react";
import { useHistory } from "react-router-dom";
import { Dropdown } from 'antd';
import { EllipsisOutlined, FileOutlined } from '@ant-design/icons';
import { commentUseMutation } from '../hooks';
import { EntityRemoveButton, Localize } from 'components/service';
import Post from 'components/post';
import Icons from 'components/icons';



const CommentActionField = ({ comment }) => {

    const history = useHistory();


    const {
        _setMutationCommentDelete,
        loadingMutationCommentDelete,
    } = commentUseMutation.delete(comment.id);



    let items = [
        {
            key: 'view',
            label: <Localize>MENUS_DROP.Label_ViewPost</Localize>,
            icon: <FileOutlined />,
            onClick: () => history.push(`${ Post.Const.basePath }/${ comment?.post?.id }`)
        },
        {
            key: 'delete',
            disabled: false,
            icon:
                <> { false ?
                    <span style={ { display: 'flex', alignItems: 'center' } } ><Icons.Delete /> <Localize>MENUS_DROP.Label_Delete</Localize></span> :

                    <EntityRemoveButton
                        modalButton={
                            <span style={ { display: 'flex', alignItems: 'center' } } ><Icons.Delete /> <Localize>MENUS_DROP.Label_Delete</Localize></span>
                        }
                        nameEntity={ Localize({ children: "ENTITY.Modal_Title_Comment" }).props.children }
                        dataNameEntity=""
                        loading={ loadingMutationCommentDelete }
                        deleteMutation={ _setMutationCommentDelete }
                        variables={ {
                            id: comment.id,
                        } } /> }
                </>
        } ]


    return (
        <Dropdown
            // trigger={['click']}
            placement="bottomRight"
            menu={ { items } }
            overlayClassName="drop-more-menu"
            arrow
        >
            <EllipsisOutlined className="btn-more" />
        </Dropdown>
    )
};

export default CommentActionField;

