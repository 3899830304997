import React from "react";
import { BrowserRouter } from 'react-router-dom';

import { errorNotification } from "components/request-result";


const Router = ({ children }) => {

    return (
        <BrowserRouter
            getUserConfirmation={(message, callback) => {

                const allowTransition = errorNotification({
                    label: message,
                    reason: ""
                });

                callback(allowTransition);
            }}
        >
            { children }
        </BrowserRouter>
    );
};

export default Router;