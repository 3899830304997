import CommentFields from "../fields";
import { Localize } from "components/service";


let commentsColumns = [
    {
        title: <Localize>TABLES.Column_Title_ID</Localize>,
        dataIndex: 'id',
        columnIndex: 'ID',
        align: 'center',
        width: '5%'
    },
    {
        title: <Localize>TABLES.Column_Title_Author</Localize>,
        dataIndex: 'author',
        columnIndex: 'AUTHOR_ID',
        sorter: true
    },
    {
        title: <Localize>TABLES.Column_Title_PostTitle</Localize>,
        dataIndex: 'post',
        columnIndex: 'POST_ID',
        sorter: true
    },
    {
        title: <Localize>TABLES.Column_Title_Comments</Localize>,
        dataIndex: 'content',
        columnIndex: 'CONTENT',
    },
    {
        title: <Localize>TABLES.Column_Title_Likes</Localize>,
        dataIndex: 'like_count',
        columnIndex: 'LIKE_COUNT',
        sorter: true
    },
    {
        title: <Localize>TABLES.Column_Title_CreateDate</Localize>,
        dataIndex: 'created_at',
        columnIndex: 'CREATED_AT',
        sorter: true
    },
    {
        // title: 'action',
        dataIndex: 'action',
        align: 'center',
        width: '4%'
    }
];


const commentsData = ({ model, actionFieldsHide, entityRemovePost, variables }) => {

    if (!model)
    {
        return [];
    }

    return model.map(comment => {

        return {
            key: comment.id,
            id: comment.id,
            author: <CommentFields.Author comment={ comment } />,
            post: <CommentFields.Post comment={ comment } />,
            content: <CommentFields.Content comment={ comment } />,
            like_count: comment.like_count ?? <strong>&mdash;</strong>,
            created_at: comment.created_at ?? <strong>&mdash;</strong>,
            action: <CommentFields.Action comment={ comment } variables={ variables } actionFieldsHide={ actionFieldsHide } entityRemovePost={ entityRemovePost } />
        };

    });
};


const CommentTableHelper = {
    columns: commentsColumns,
    data: commentsData
}


export default CommentTableHelper;