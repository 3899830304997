import { useQuery } from "@apollo/client";

import { GET_ME } from "graphql/query/user-gql";

import MockData from "components/mock-data";


const useTranslation = () => {

    const { translation } = MockData;

    const { loading, data } = useQuery(GET_ME);

    const defaultLang = "en";

    if (!loading && data)
    {
        return { ...translation[ defaultLang ], ...translation[ data.me.locale ] };
    }

    return { ...translation[ defaultLang ] }
}

export default useTranslation;