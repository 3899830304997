import React from "react";
import { useLocation } from "react-router-dom";

import { Forms } from "components/auth";
import { useVarParam } from "components/use-hooks";


const LoginPage = () => {

    const location = useLocation(),
        guestParams = useVarParam("guest");

    if (location.state)
    {
        guestParams.set({ "comeFrom": location.state.path });
    }

    return (
        <Forms.SignIn />
    );
};

export default LoginPage;