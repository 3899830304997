import { gql } from '@apollo/client';

export const pageInfoFieldsFull = gql`
    fragment pageInfoFieldsFull on PageInfo{
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
        total
        count
        currentPage
        lastPage
    }
`;