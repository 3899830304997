const hashtagBasePath = '/hashtags';


const HashtagConst = {
  basePath     : hashtagBasePath
};

export default HashtagConst;









