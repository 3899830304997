import DealerInfoField from "./dealer-info-field";
import DealerActionField from './dealer-action-field';



const DealerFields = {
    Info : DealerInfoField,
    Action: DealerActionField,
}

export default DealerFields;