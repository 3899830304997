import React from "react";
import { EntityRemoveButton, Localize } from "components/service";
import { CloseOutlined } from "@ant-design/icons";
import Seller from "components/seller";



const SellerRemoveField = ({ variables, sellerID, sellerName }) => {


    const {
        _setMutationSellerRemove,
        loadingMutationSellerRemove,
    } = Seller.Hooks.remove(sellerID, variables);


    return (
        <EntityRemoveButton
            modalButton={ <CloseOutlined className="icon-delete" /> }
            nameEntity={ Localize({ children: "ENTITY.Modal_Title_Seller" }).props.children }
            okText={ Localize({ children: "GLOBAL.Button_Text_Remove" }).props.children }
            dataNameEntity={ sellerName }
            loading={ loadingMutationSellerRemove }
            deleteMutation={ _setMutationSellerRemove }
            variables={ { id: sellerID } } />
    );
}


export default SellerRemoveField;