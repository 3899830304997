import React from "react";
import { useHistory } from 'react-router-dom';
import { Dropdown } from 'antd';
import { EllipsisOutlined } from '@ant-design/icons';
import { Localize } from 'components/service';
import Post from 'components/post';
import CollectionConst from '../collection-const';



const CollectionActionField = ({ collection }) => {

    const history = useHistory();


    let items = [
        {
            key: 'overview',
            label: <Localize>MENUS_DROP.Label_Overview</Localize>,
            onClick: () => history.push(`${ CollectionConst.basePath }/${ collection.id }`)
        },
        {
            key: 'viewPosts',
            label: <Localize>MENUS_DROP.Label_ViewPosts</Localize>,
            onClick: () => history.push(`${ CollectionConst.basePath }/${ collection.id }${ Post.Const.basePath }`)
        },
        {
            key: 'viewFollowers',
            label: <Localize>MENUS_DROP.Label_ViewFollowers</Localize>,
            onClick: () => history.push(`${ CollectionConst.basePath }/${ collection.id }/followers`)
        },
    ]




    return (
        <Dropdown
            // trigger={['click']}
            placement="bottomRight"
            menu={ { items } }
            overlayClassName="drop-more-menu"
            arrow
        >
            <EllipsisOutlined className="btn-more" />
        </Dropdown>
    )
};

export default CollectionActionField;

