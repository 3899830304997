import React from "react";
import { Form, Input, Button } from "antd";
import { Localize } from "components/service";
import { initialValFormatted } from "utils/helper-functions";


const DealerFiltersForm = ({ action, dealerFilters, setCount, setFilters, setFilterList, filterList, gqlBuilderWhere }) => {


    const onChangeSetFilter = (e, name, value) => {

        if (e)
        {
            name = e.target.name
            value = e.target.value
        }

        value.toString().length > 0 ?

            setFilterList({
                ...filterList,
                [ name ]: {
                    column: name,
                    operator: "LIKE",
                    value: `${ value }%`
                }
            }) : setFilterList({ ...filterList, [ name ]: {} });
    }


    return (

        <div className="ant-form ant-form-vertical filters-form">

            <Form.Item
                initialValue={ initialValFormatted(dealerFilters, 'CITY') }
                name="CITY"
                label={ <Localize>FORMS.Input_Label_City</Localize> }
                onChange={ onChangeSetFilter }
            >
                <Input name="CITY" />
            </Form.Item>

            <Form.Item
                initialValue={ initialValFormatted(dealerFilters, 'STREET') }
                name="STREET"
                label={ <Localize>FORMS.Input_Label_Street</Localize> }
                onChange={ onChangeSetFilter }
            >
                <Input name="STREET" />
            </Form.Item>
            <Form.Item
                initialValue={ initialValFormatted(dealerFilters, 'ZIP_CODE') }
                name="ZIP_CODE"
                label={ <Localize>FORMS.Input_Label_ZipCode</Localize> }
                onChange={ onChangeSetFilter }
            >
                <Input name="ZIP_CODE" />
            </Form.Item>

            <Form.Item
                initialValue={ initialValFormatted(dealerFilters, 'REGION') }
                name="REGION"
                label={ <Localize>FORMS.Input_Label_Region</Localize> }
                onChange={ onChangeSetFilter }
            >
                <Input name="REGION" />
            </Form.Item>

            <Form.Item
                initialValue={ initialValFormatted(dealerFilters, 'DISTRIC') }
                name="DISTRIC"
                label={ <Localize>FORMS.Input_Label_District</Localize> }
                onChange={ onChangeSetFilter }
            >
                <Input name="DISTRIC" />
            </Form.Item>


            <div className="form-btn-holder">
                <Button
                    type="primary"
                    htmlType="submit"
                    onClick={ () => {
                        setFilters(gqlBuilderWhere({ ...dealerFilters, ...filterList }));
                        setCount({ ...dealerFilters, ...filterList });
                        action();
                    } }
                >
                    <Localize>FORMS.Button_Text_ApplyFilters</Localize>
                </Button>
                <Button
                    className="ant-btn-secondary bg-light-gray btn-right"
                    htmlType="submit"
                    onClick={ () => {
                        setFilterList({});
                        setCount({});
                        setFilters(gqlBuilderWhere({}));
                        localStorage.removeItem("dealerFilters");
                    } }
                >
                    <Localize>FORMS.Button_Text_ResetFilters</Localize>
                </Button>
            </div>
        </div>
    )
}

export default DealerFiltersForm;