import { gql } from '@apollo/client';



export const GET_FOLLOWERS = gql`
    query GetFollowersBy(
      $id: ID!
      $model: modelSubscription!
      $first: Int
      $page: Int
    ){
      followersBy(
          id: $id
          model: $model
          first: $first
          page: $page
        ){
            paginatorInfo{
                total
                count
                currentPage
                perPage
                lastPage
            }
            data {
                id
                name
                initials
                nickname
                email
                status
                locale
                role
                gender
                avatar
                about
                itsMe
                me_following
                is_online
                last_active_date
                last_visit_at
                dealer_id
                dealer {
                  id
                  company_name
                  dealer_number
                }
                salutation
                job_type
                seller_number
                phone
                sendPassword {
                  id
                  user_id
                  send_user_id
                  message
                  created_at
                }
                date_birth_at
                date_entrance_at
                created_at
                updated_at
              }
        }
    }
`;

