import CollectionFields from "./fields";
import CollectionForms from "./forms";
import CollectionConst from "./collection-const";
import CollectionHelpers from "./helpers";
import CollectionFilters from './filters';


const Collection = {
  Filters: CollectionFilters,
  Fields: CollectionFields,
  Forms: CollectionForms,
  Const: CollectionConst,
  Helpers: CollectionHelpers,
}

export default Collection;
