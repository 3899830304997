import { gql } from '@apollo/client';



export const optionFragment = gql`
  fragment optionFields on Option {
    id
    key
    value
  }
`;

export const GET_OPTIONS = gql`
  query GetOptions($id: [ID] = "",
                   $key: [String] = [
     "account_activation_by_email",
     "registration_by_email",
     "allowed_email",
     "allowed_personal_email"
    ])
    {
    options(id: $id, key: $key) {
      ...optionFields
    }
  }
  ${ optionFragment }
`;
