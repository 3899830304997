import { Link } from 'react-router-dom';
import Dealer from 'components/dealer';


const UserDealerField = ({ user }) => {

    const dealer = user.dealer?.company_name && user.dealer?.dealer_number;

    return (

        !dealer ?
            <strong>&mdash;</strong>
            :

            <Link to={ `${ Dealer.Const.basePath }/${ user?.dealer?.id }` }>
                <span>{ user.dealer?.company_name ? user.dealer?.company_name : <strong>&mdash;</strong> }</span> &nbsp;
                <span>{ user.dealer?.dealer_number ? "(" + user.dealer?.dealer_number + ")" : <strong>&mdash;</strong> }</span>
            </Link>
    );
};

export default UserDealerField;
