
import { useMutation } from "@apollo/client";
import { DEALER_REMOVE_USER } from "graphql/mutation/dealer-gql";
import { GET_USERS } from "graphql/query/user-gql";
import { successNotification, errorNotification } from "components/request-result";


const useMutationDealerRemove = (variables) => {

  const [ _setMutationUserRemove, { loading } ] = useMutation(DEALER_REMOVE_USER,
    {
      update(cache, { data }) {

        const {
          dealerRemoveUser: {
            label,
            message
          }
        } = data;

        successNotification({
          title: label,
          description: message
        });

      },
      refetchQueries: [ { query: GET_USERS, variables } ],
      onError(error) {
        errorNotification(error);
      }
    }
  );

  return {
    _setMutationUserRemove,
    loadingMutationUserRemove: loading,
  }
};

export default useMutationDealerRemove;