import { useMutation } from "@apollo/client";
import { USER_QUESTION_UPDATE, USER_QUESTION_CREATE } from "graphql/mutation/user-gql";
import { GET_USER_QUESTIONS, GET_USER_QUESTIONS_NOT_ANSWERED } from "graphql/query/user-gql";
import { successNotification, errorNotification } from "components/request-result";



const useMutationUserQuestionCreateUpdate = (id, variables) => {

  const USER_QUESTION_CREATE_UPDATE = id ? USER_QUESTION_UPDATE : USER_QUESTION_CREATE;
  const userQuestionCreateUpdate = id ? 'userQuestionUpdate' : 'userQuestionCreate';

  const [ _setUserQuestionCreateUpdate, { loading } ] = useMutation(USER_QUESTION_CREATE_UPDATE,
    {
      update(cache, { data }) {

        const {
          [ userQuestionCreateUpdate ]: {
            label,
            message,
          }
        } = data;


        successNotification({
          title: label,
          description: message
        });
      },
      onError(error) {
        errorNotification(error);
      },

      refetchQueries: [
        {
          query: GET_USER_QUESTIONS,
          variables
        },

        {
          query: GET_USER_QUESTIONS_NOT_ANSWERED,
          variables: {
            where: {
              column: 'ANSWER_ID',
              operator: 'IS_NULL'
            }
          }
        },
      ]
    }
  );


  return {
    _setUserQuestionCreateUpdate,
    loadingMutationUserQuestionCreateUpdate: loading,
  }
};

export default useMutationUserQuestionCreateUpdate;

