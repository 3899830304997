import React from "react";


const CommentContentField = ({ comment }) => {

    return (
        <>
            { comment?.content ?
                <span style={ {
                    display: '-webkit-box',
                    textOverflow: 'ellipsis',
                    overflow: 'hidden',
                    WebkitLineClamp: '3',
                    WebkitBoxOrient: 'vertical',
                    // whiteSpace: 'pre-line'
                } }>
                    { comment?.content }
                </span>
                :
                <strong>&mdash;</strong>
            }
        </>
    )
}


export default CommentContentField;