import PostOverviewSubPage from "./post-owerview-sub-page";
import PostAttachmentsSubPage from "./post-attachments-sub-page";
import PostCommentsSubPage from "./post-comments-sub-page";
import PostMediaSubPage from "./post-media-sub-page";


const SubPage = {
    OverView: PostOverviewSubPage,
    Attachments: PostAttachmentsSubPage,
    Comments: PostCommentsSubPage,
    Media: PostMediaSubPage

};

export default SubPage;
