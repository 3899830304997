import React from 'react';
import Icon from '@ant-design/icons';


const ProfileSvg = () => (

	<svg fill="currentColor" width="1em" height="1em" viewBox="0 0 48 48" strokeWidth="1">
		<path d="M26.6,22.4c3.7-1,6.5-4.5,6.5-8.6c0-4.9-4-8.9-8.8-8.9s-8.8,4-8.8,8.9c0,4.1,2.7,7.5,6.5,8.6c-8.6,1.2-15.4,9.2-15.5,18.8
	c0,0.7,0.5,1.3,1.2,1.3c0.7,0,1.3-0.5,1.3-1.2c0.1-9.1,7-16.5,15.3-16.5c8.4,0,15.2,7.4,15.3,16.5c0,0.7,0.6,1.2,1.2,1.2
	c0,0,0,0,0,0c0.7,0,1.2-0.6,1.2-1.3C42,31.6,35.2,23.7,26.6,22.4z M17.9,13.9c0-3.5,2.8-6.4,6.3-6.4s6.3,2.9,6.3,6.4
	s-2.8,6.4-6.3,6.4S17.9,17.4,17.9,13.9z"/>
	</svg>

);

const ProfileIcon = props => {
	return <Icon className={ props.className } component={ ProfileSvg } />;
};


export default ProfileIcon;