import React from "react";
import { Menu, Dropdown } from 'antd';
import { EllipsisOutlined } from '@ant-design/icons';
import { saveAs } from "file-saver";
import { postUseMutation } from '../hooks';
import Icons from 'components/icons';
import { EntityRemoveButton, Localize } from 'components/service';


const PostAttachmentActionField = ({ attachment }) => {


    const {
        _setMutationPostAttachedDelete,
        loadingMutationPostAttachedDelete,
    } = postUseMutation.attachedDelete(attachment?.id);


    let items = [
        {
            key: '1',
            icon: <Icons.Download />,
            label: <Localize>MENUS_DROP.Label_Download</Localize>,
            onClick: () => saveAs(attachment?.display_url, attachment?.title),

        },
        {
            key: '2',
            icon:
                <EntityRemoveButton
                    modalButton={
                        <span style={ { display: 'flex', alignItems: 'center', width: '100%' } } ><Icons.Delete /> <Localize>MENUS_DROP.Label_Delete</Localize></span>
                    }
                    nameEntity={ Localize({ children: "ENTITY.Modal_Title_Document" }).props.children }
                    dataNameEntity={ attachment?.title }
                    loading={ loadingMutationPostAttachedDelete }
                    deleteMutation={ _setMutationPostAttachedDelete }
                    variables={ {
                        id: attachment?.id,
                    } }
                />,
        },
    ];

    return (
        <Dropdown
            // trigger={['click']}
            placement="bottomRight"
            overlay={ <Menu items={ items } /> }
            overlayClassName="drop-more-menu"
            arrow
        >
            <EllipsisOutlined className="btn-more" />
        </Dropdown>
    )
};

export default PostAttachmentActionField;