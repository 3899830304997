import React from "react";
import { Link } from 'react-router-dom';
import Dealer from 'components/dealer';
import User from 'components/user';


const SellerInfoField = ({ seller }) => {

    return (
        <>
            <Link to={ `${ User.Const.basePath }/${ seller?.user?.id }` }> { `${ seller?.user?.salutation ? seller?.user?.salutation : '' } ${ seller?.user?.name }` }</Link>
            <br />
            { seller?.dealer?.company_name && <Link to={ `${ Dealer.Const.basePath }/${ seller?.dealer_id }` }> { seller?.dealer?.company_name }</Link> }
        </>
    );
}


export default SellerInfoField;