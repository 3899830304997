import CollectionFiltersForm from "./collection-filters-form";
import CollectionForm from "./collection-form";

import './forms.scss';

const CollectionForms = {
    Filter: CollectionFiltersForm,
    Collection: CollectionForm
};

export default CollectionForms;
