import React from 'react';
import Icon from '@ant-design/icons';


const LastMinuteSvg = () => (

	<svg fill="currentColor"  width="1em" height="1em" viewBox="0 0 48 48" strokeWidth="1">
		<g>
			<path d="M26.7,4.5c-6.5,0-12.4,3.2-16,8.6c-0.4,0.6-0.2,1.4,0.3,1.7c0.6,0.4,1.3,0.2,1.7-0.3C15.8,9.8,21,7,26.7,7
		c9.2,0,16.7,7.5,16.7,16.7s-7.5,16.7-16.7,16.7c-5.7,0-11-2.9-14.1-7.7c-0.4-0.6-1.1-0.7-1.7-0.4c-0.6,0.4-0.7,1.1-0.4,1.7
		C14,39.7,20.1,43,26.7,43c10.6,0,19.2-8.6,19.2-19.2S37.3,4.5,26.7,4.5z"/>
			<path d="M26,28c0.2,0.1,0.5,0.2,0.7,0.2c0.3,0,0.6-0.1,0.9-0.4l9-9c0.5-0.5,0.5-1.3,0-1.8s-1.3-0.5-1.8,0l-8.3,8.3l-5-3.3
		c-0.6-0.4-1.4-0.2-1.7,0.4c-0.4,0.6-0.2,1.4,0.4,1.7L26,28z"/>
			<path d="M17,18.4c0-0.7-0.6-1.2-1.3-1.2H2.1c-0.7,0-1.2,0.6-1.2,1.2s0.6,1.2,1.2,1.2h13.6C16.4,19.7,17,19.1,17,18.4z" />
			<path d="M17,23.5c0-0.7-0.6-1.2-1.3-1.2H4c-0.7,0-1.3,0.6-1.3,1.2s0.6,1.2,1.3,1.2h11.7C16.4,24.8,17,24.2,17,23.5z" />
			<path d="M17,28.6c0-0.7-0.6-1.2-1.3-1.2H7.2c-0.7,0-1.2,0.6-1.2,1.2s0.6,1.2,1.2,1.2h8.6C16.4,29.8,17,29.3,17,28.6z" />
		</g>
	</svg>

);

const LastMinIcon = () => {
	return <Icon component={ LastMinuteSvg } />;
};


export default LastMinIcon;