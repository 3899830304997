import React from "react";
import { Switch, Route, useRouteMatch } from 'react-router-dom';
import SubPage from 'components/pages/hashtag/sub-pages';


const HashtagRoute = ({ hashtag, history }) => {

    const { path } = useRouteMatch();

    return (
        <Switch>

            <Route path={ `${ path }/followers` } >
                <SubPage.Followers
                    hashtag={ hashtag }
                    history={ history } />
            </Route>

            <Route path={ `${ path }/followers/page/:pageNum` } exact>
                <SubPage.Followers
                    hashtag={ hashtag }
                    history={ history } />
            </Route>

            <Route path={ `${ path }` }>
                <SubPage.Posts
                    hashtag={ hashtag }
                    history={ history } />
            </Route>

            <Route path={ `${ path }/page/:pageNum` }>
                <SubPage.Posts
                    hashtag={ hashtag }
                    history={ history } />
            </Route>
        </Switch>
    );
};

export default HashtagRoute;