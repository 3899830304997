import React, { useState } from "react";
import { useRouteMatch } from "react-router-dom";

import { GET_POSTS } from 'graphql/query/post-gql';

import Post from 'components/post';
import Collection from 'components/collection';
import { useActiveMenuItem, useBreadCrumbs } from "components/use-hooks";
import { Tables } from "components/layout";
import { Localize } from "components/service";
import { gqlBuilderWhere } from "utils";


const tableHelper = Post.Helpers.TableMain();


const CollectionPostSubPage = ({ collection }) => {


    const basePath = Collection.Const.basePath;
    const postBasePath = Post.Const.basePath;

    useActiveMenuItem([ "collections" ], [ "collections" ]);

    const breadcrumbs = [
        {
            label: Localize({ children: "BREADCRUMBS.Label_Collections" }).props.children,
            path: `${ basePath }`
        },
        {
            label: collection?.title,
            path: `${ basePath }/${ collection?.id }`
        },
        {
            label: Localize({ children: "BREADCRUMBS.Label_Posts" }).props.children,
            path: `${ basePath }/${ collection?.id }${ postBasePath }`
        }
    ]

    useBreadCrumbs(breadcrumbs);

    const routeUrl = `${ basePath }/${ collection?.id }${ postBasePath }`;
    let match = useRouteMatch(`${ routeUrl }/page/:pageNum`);

    const objOrderBy = [ { column: "CREATED_AT", order: "DESC" } ];

    const [ searchText, setSearchText ] = useState("");
    const [ filters, setFilters ] = useState();
    const [ resetSorting, setResetSorting ] = useState(false);

    const modelFilters = `postsFilters_collectionID-${ collection?.id }`;
    const modelSorting = `postsSorting_collectionID-${ collection?.id }`;

    const postsFilters = JSON.parse(localStorage.getItem(modelFilters));
    const postsSorting = JSON.parse(localStorage.getItem(modelSorting));


    return (

        <Tables.Main
            model="posts"
            extraClass="table-posts"
            query={ GET_POSTS }
            varSet={ { perPage: 30, type: 'COLLECTION', id_type: collection?.id } }
            routeUrl={ routeUrl }
            currentPage={ match ? parseInt(match.params.pageNum) : 1 }
            searchText={ searchText }
            objFilter={ filters || gqlBuilderWhere(postsFilters) }
            objOrderBy={ postsSorting || objOrderBy }
            sortingName={ modelSorting }
            tableHelper={ tableHelper }
            resetSorting={ resetSorting }
        >
            <div className="table-action-bar">

                <div className="col">
                    <Post.Filters.Table
                        filters={ filters }
                        setSearchText={ setSearchText }
                        setFilters={ setFilters }
                        postsFilters={ postsFilters }
                        setResetSorting={ setResetSorting }
                        objOrderBy={ objOrderBy }
                        modelFilters={ modelFilters }
                        modelSorting={ modelSorting }
                    />
                </div>

                <div className="col"> </div>
            </div>
        </Tables.Main>
    )
};

export default CollectionPostSubPage;