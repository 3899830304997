import React, { useState } from "react";
import { useRouteMatch } from "react-router-dom";

import { GET_COLLECTIONS } from 'graphql/query/collection-gql';

import Collection from 'components/collection';
import { useActiveMenuItem, useBreadCrumbs } from "components/use-hooks";
import { PageWrapDefault, Tables } from "components/layout";
import { Localize } from "components/service";
import { gqlBuilderWhere } from "utils";
import './collection.scss';


const basePath = Collection.Const.basePath;
const tableHelper = Collection.Helpers.TableMain();


const CollectionsPage = () => {

    useActiveMenuItem([ "collections" ], [ "collections" ]);

    const breadcrumbs = [
        {
            label: Localize({ children: "BREADCRUMBS.Label_Collections" }).props.children,
            path: `${ basePath }`
        }
    ]

    useBreadCrumbs(breadcrumbs);

    let match = useRouteMatch(`${ basePath }/page/:pageNum`);

    const objOrderBy = [ { column: "CREATED_AT", order: "DESC" } ];

    const [ searchText, setSearchText ] = useState("");
    const [ filters, setFilters ] = useState();
    const [ resetSorting, setResetSorting ] = useState(false);

    const collectionsFilters = JSON.parse(localStorage.getItem("collectionsFilters"));
    const collectionsSorting = JSON.parse(localStorage.getItem("collectionsSorting"));


    return (
        <PageWrapDefault
            className="page-collections"
            dataExist={ true }
            title={ <Localize>PAGES.Title_Collections</Localize> }
            staticPath=""
        >
            <Tables.Main
                model="collections"
                query={ GET_COLLECTIONS }
                varSet={ { perPage: 30 } }
                routeUrl={ `${ basePath }` }
                currentPage={ match ? parseInt(match.params.pageNum) : 1 }
                searchText={ searchText }
                objFilter={ filters || gqlBuilderWhere(collectionsFilters) }
                objOrderBy={ collectionsSorting || objOrderBy }
                sortingName="collectionsSorting"
                tableHelper={ tableHelper }
                resetSorting={ resetSorting }
            >
                <div className="table-action-bar">

                    <div className="col">
                        <Collection.Filters.Table
                            filters={ filters }
                            setSearchText={ setSearchText }
                            setFilters={ setFilters }
                            collectionsFilters={ collectionsFilters }
                            setResetSorting={ setResetSorting }
                            objOrderBy={ objOrderBy }
                        />
                    </div>

                    <div className="col"> </div>
                </div>
            </Tables.Main>
        </PageWrapDefault>
    )
};

export default CollectionsPage;