import React from "react";
import { Form, Input, Button } from 'antd';
import { userUseMutation } from '../../hooks';
import { Localize } from "components/service";


const UserQuestionForm = ({ userQuestion, variables, action }) => {

    const [ form ] = Form.useForm();

    const id = userQuestion?.id ? +userQuestion?.id : undefined;

    const {
        _setUserQuestionCreateUpdate,
        loadingMutationUserQuestionCreateUpdate
    } = userUseMutation.questionCreateUpdate(id, variables);



    return (

        <>

            { id ? <span style={ {
                whiteSpace: 'pre-line',
                fontSize: '14px', maxHeight: '200px',
                overflow: 'auto', display: 'block'
            } }
            >
                { userQuestion?.question }
            </span> : '' }

            <Form
                key="edit-user-question-form"
                layout="vertical"
                form={ form }
                className="model-form edit-user-question-form"
                onFinish={ (values) => {

                    _setUserQuestionCreateUpdate({
                        variables: {
                            input: {
                                id: id ? id : undefined,
                                answer: id ? values?.text : undefined,
                                question: id ? undefined : values?.text,
                            }
                        }
                    })
                    action();
                } }>

                <br />

                <Form.Item
                    name="text"
                    label={ id ? <Localize>FORMS.Input_Label_Answer</Localize> : <Localize>FORMS.Input_Label_Question</Localize> }
                    initialValue={ userQuestion?.answer }
                >
                    <Input.TextArea
                        maxLength={ 10000 }
                        placeholder={ Localize({ children: "FORMS.Input_Placeholder_ShortDescription" }).props.children }
                        autoSize={ { minRows: 3, maxRows: 5 } }
                        showCount={
                            { formatter: ({ count, maxLength }) => maxLength - count }
                        }
                    />
                </Form.Item>

                <div className="form-btn-holder">

                    <Button
                        style={ { marginRight: '0' } }
                        className="btn-right"
                        type="primary"
                        htmlType="submit"
                        loading={ loadingMutationUserQuestionCreateUpdate }
                    >
                        { id ? <Localize>GLOBAL.Button_Text_SaveChanges</Localize> : <Localize>USER.Button_Text_Create</Localize> }
                    </Button>
                </div>
            </Form>
        </>


    );
};

export default UserQuestionForm;


