import { gql } from "@apollo/client";

export const logGraphicFragment = gql`
  fragment countFields on LogStatistics {
    date
    per_day
    total
  }
`;

export const GET_LOG_GRAPHIC = gql`
  query GetlogGraphic(
    $end_day: Date
    $key: [LogStatisticEnum]
    $start_day: Date
  ) {
    logGraphic(end_day: $end_day, key: $key, start_day: $start_day) {
      users_count {
        ...countFields
      }

      users_visit_count {
        ...countFields
      }

      posts_count {
        ...countFields
      }

      attachments_count {
        ...countFields
      }

      collections_count {
        ...countFields
      }

      hashtags_count {
        ...countFields
      }

      comments_count {
        ...countFields
      }

      media_count {
        ...countFields
      }
    }
  }

  ${logGraphicFragment}
`;
