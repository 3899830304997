import React, { useState } from "react";
import { Input } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { useRouteMatch } from "react-router-dom";

import { GET_COMMENTS } from 'graphql/query/comment-gql';

import Comment from 'components/comment';
import { useActiveMenuItem, useBreadCrumbs } from "components/use-hooks";
import { PageWrapDefault, Tables } from "components/layout";
import { Localize } from "components/service";

import './comment.scss';

const { Search } = Input;

const basePath = Comment.Const.basePath;
const tableHelper = Comment.Helpers.TableMain;



const CommentsPage = () => {


    useActiveMenuItem([ "posts" ], [ "posts" ]);

    const breadcrumbs = [
        {
            label: Localize({ children: "BREADCRUMBS.Label_Comments" }).props.children,
            path: `${ basePath }`
        }
    ]

    useBreadCrumbs(breadcrumbs);

    let match = useRouteMatch(`${ basePath }/page/:pageNum`);

    const objOrderBy = [ { column: "CREATED_AT", order: "DESC" } ];

    const [ searchText, setSearchText ] = useState("");

    const pageNavbar = [
        {
            label: Localize({ children: "NAVBAR_PAGES.Label_Posts" }).props.children,
            path: "posts"
        },

        {
            label: Localize({ children: "NAVBAR_PAGES.Label_AllComments" }).props.children,
            path: "all-comments"
        } ];


    return (
        <PageWrapDefault
            className="page-comments"
            dataExist={ true }
            title={ <Localize>PAGES.Title_AllComments</Localize> }
            pageNavbar={ pageNavbar }
            staticPath=""
        >
            <Tables.Main
                model="comments"
                extraClass="table-comments"
                query={ GET_COMMENTS }
                varSet={ { perPage: 30 } }
                routeUrl={ `${ basePath }` }
                currentPage={ match ? parseInt(match.params.pageNum) : 1 }
                searchText={ searchText }
                objOrderBy={ objOrderBy }
                tableHelper={ tableHelper }
            >
                <div className="table-action-bar">

                    <div className="col">
                        <Search
                            className="filter-search"
                            prefix={ <SearchOutlined /> }
                            onChange={ (e) => setSearchText(e.target.value) }
                        />
                    </div>

                    <div className="col"> </div>
                </div>
            </Tables.Main>
        </PageWrapDefault>
    )
};

export default CommentsPage;