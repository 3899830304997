import { Link } from "react-router-dom";
import { Button } from "antd";
import User from "components/user";
import Dealer from "components/dealer";
import { useBreadCrumbs } from "components/use-hooks";
import { Localize } from "components/service";


const userBasePath = User.Const.basePath;
const dealerBasePath = Dealer.Const.basePath;


const UserDealerSubPage = ({ user, history }) => {

    const breadcrumbs = [
        {
            label: Localize({ children: "BREADCRUMBS.Label_Users" }).props.children,
            path: `${ userBasePath }`
        },
        {
            label: user?.name,
            path: `${ userBasePath }/${ user?.id }`
        },
        {
            label: Localize({ children: "BREADCRUMBS.Label_Dealer" }).props.children,
            path: `${ userBasePath }/${ user?.id }/dealer`
        }
    ]

    useBreadCrumbs(breadcrumbs);


    return (
        <div className="row-grid col-lg-2">

            <div className="col-left">
                <Dealer.Forms.Edit userID={ user.id } dealer={ user?.dealer } history={ history } />
                <Link to={ `${ dealerBasePath }/${ user?.dealer?.id }` }>
                    <Button type="primary"><Localize>GLOBAL.Button_Text_Edit</Localize></Button>
                </Link>
            </div>
            <div className="col-right"></div>
        </div>
    )
}

export default UserDealerSubPage;