
import { useMutation } from "@apollo/client";
import { SELLER_STATISTIC_DELETE } from "graphql/mutation/seller-gql";
import { GET_SELLER_STATISTICS } from "graphql/query/seller-gql";
import { successNotification, errorNotification } from "components/request-result";


const useMutationSellerRemove = (id, variables) => {


  const [ _setMutationSellerRemove, { loading } ] = useMutation(SELLER_STATISTIC_DELETE,
    {
      update(cache, { data }) {

        const {
          sellerStatisticDelete: {
            label,
            message
          }
        } = data;


        const cacheSellerStatistics = cache.readQuery({ query: GET_SELLER_STATISTICS, variables });

        const filteredArr = cacheSellerStatistics
          .sellerStatistics
          .data
          .filter((item) => item.id !== id);


        cache.writeQuery({
          query: GET_SELLER_STATISTICS,
          variables,
          data: {
            sellerStatistics: {
              data: [
                ...filteredArr
              ],
              __typename: "SellerStatisticPaginator"
            }
          }
        })

        successNotification({
          title: label,
          description: message
        });

      },
      onError(error) {
        errorNotification(error);
      }
    }
  );

  return {
    _setMutationSellerRemove,
    loadingMutationSellerRemove: loading,
  }
};

export default useMutationSellerRemove;