import React from 'react';
import Icon from '@ant-design/icons';


const DownloadSvg = () => (

	<svg fill="currentColor" width="1em" height="1em" viewBox="0 0 48 48" strokeWidth="1">
		<g>
			<path d="M22.2,33.3c0.2,0.3,0.6,0.5,1,0.5s0.8-0.2,1-0.5L35,22.1c0.5-0.5,0.5-1.3,0-1.8c-0.5-0.5-1.3-0.5-1.8,0l-8.7,9V7.9
		c0-0.7-0.6-1.2-1.2-1.2S22,7.3,22,7.9v21.5l-8.7-9c-0.5-0.5-1.3-0.5-1.8,0c-0.5,0.5-0.5,1.3,0,1.8L22.2,33.3z"/>
			<path d="M43.5,27.2c-0.7,0-1.2,0.6-1.2,1.2v10.8H5.7V28.5c0-0.7-0.6-1.2-1.2-1.2s-1.2,0.6-1.2,1.2v12.1c0,0.7,0.6,1.2,1.2,1.2h39.1
		c0.7,0,1.2-0.6,1.2-1.2V28.5C44.8,27.8,44.2,27.2,43.5,27.2z"/>
		</g>
	</svg>

);

const DownloadIcon = () => {
	return <Icon component={ DownloadSvg } />;
};


export default DownloadIcon;

