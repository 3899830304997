const UserContactField = ({ user }) => {

    return (

        <>
            <div>{ user.email ? <a href={ `mailto:${ user.email }` }>{ user.email }</a> : <strong>&mdash;</strong> }</div>
            <div>{ user.phone ? <a href={ `tel:${ user.phone }` }>{ user.phone }</a> : <strong>&mdash;</strong> }</div>
        </>
    );
};

export default UserContactField;