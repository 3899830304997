import { Avatar } from "antd";
import { FileImageOutlined } from "@ant-design/icons";
import Collection from "components/collection";

import { useBreadCrumbs } from "components/use-hooks";
import { Localize } from "components/service";

const collectionBasePath = Collection.Const.basePath;


const CollectionOverviewSubPage = ({ collection, history }) => {

    const breadcrumbs = [
        {
            label: Localize({ children: "BREADCRUMBS.Label_Collections" }).props.children,
            path: `${ collectionBasePath }`
        },
        {
            label: collection?.title ?? <strong>the collection has no title</strong>,
            path: `${ collectionBasePath }/${ collection?.id }`
        },
    ]


    useBreadCrumbs(breadcrumbs);


    return (
        <div className="row-grid col-lg-2 sub-page-collection">

            <div className="col-left">
                <div className="d-flex align-items-center avatar-box">
                    <div style={ { minWidth: '75px' } }>
                        <Avatar style={ { minWidth: '60px', minHeight: '60px' } } shape="square" size="large" icon={ collection?.cover_url ? <img src={ collection.cover_url } alt='avatar' /> : <FileImageOutlined /> } />
                    </div>
                </div>

                <br />

                <Collection.Forms.Collection collection={ collection } history={ history } />
            </div>

            <div className="col-right"></div>
        </div>
    )
}

export default CollectionOverviewSubPage;