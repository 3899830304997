import UserFields from "./fields";
import UserForms from "./forms";
import UserActions from "./actions";
import UserConst from "./users-const";
import UserHelpers from "./helpers";
import UserFilters from './filters';
import { userUseMutation } from "./hooks";


const User = {
  Actions: UserActions,
  Filters: UserFilters,
  Fields: UserFields,
  Forms: UserForms,
  Const: UserConst,
  Helpers: UserHelpers,
  Hooks: userUseMutation
}

export default User;
