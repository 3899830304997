import React from 'react';
import { createRoot } from 'react-dom/client';
import { ApolloProvider } from "@apollo/client";

import client from "graphql/client";

import App from 'components/app';
import Auth from "components/auth";
import Router from "components/routes";
import { ScrollToTop } from 'components/service';


import 'scss/vendors/antd/antd.less';
import "scss/default.scss";


const rootElement = document.getElementById('root');
const root = createRoot(rootElement);


root.render(
    <ApolloProvider client={ client }>
        <Router>
            <Auth>
                <ScrollToTop />
                <App />
            </Auth>
        </Router>
    </ApolloProvider>
);
