import React from 'react';
import Icon from '@ant-design/icons';

const NoNotificationsSvg = () => (
    <svg fill="currentColor" width="1em" height="1em" viewBox="0 0 160 160">
        <circle cx="80" cy="80" r="80" />
        <g>
            <g>
                <path fill="#FFFFFF" d="M99,55.6c-3.4-2.7-4-7.6-1.3-11c2.7-3.4,7.6-4,11-1.3c3.4,2.7,4,7.6,1.3,11C107.3,57.7,102.4,58.2,99,55.6z
				 M106.1,46.5c-1.6-1.2-3.9-1-5.1,0.6c-1.2,1.6-1,3.9,0.6,5.1c1.6,1.2,3.9,1,5.1-0.6C107.9,50.1,107.7,47.8,106.1,46.5z"/>
                <path fill="#D5D7D8" d="M103.8,57.8c-1.8,0-3.6-0.6-5.2-1.8l0,0c-3.6-2.9-4.3-8.1-1.4-11.8c2.9-3.6,8.1-4.3,11.8-1.4
				c1.8,1.4,2.9,3.4,3.1,5.6c0.3,2.2-0.4,4.4-1.7,6.2C108.8,56.7,106.3,57.8,103.8,57.8z M99.3,55.1c3.2,2.5,7.8,2,10.3-1.2
				c1.2-1.5,1.7-3.5,1.5-5.4c-0.2-1.9-1.2-3.7-2.7-4.9c-3.2-2.5-7.8-2-10.3,1.2C95.6,48,96.1,52.6,99.3,55.1L99.3,55.1z M103.8,53.6
				c-0.9,0-1.8-0.3-2.6-0.9c-0.9-0.7-1.4-1.7-1.6-2.8c-0.1-1.1,0.2-2.2,0.9-3.1c1.4-1.8,4.1-2.1,5.9-0.7c1.8,1.4,2.1,4.1,0.7,5.9
				c-0.7,0.9-1.7,1.4-2.8,1.6C104.2,53.5,104,53.6,103.8,53.6z M103.8,46.3c-0.9,0-1.8,0.4-2.5,1.2c-0.5,0.7-0.7,1.5-0.6,2.3
				c0.1,0.8,0.5,1.6,1.2,2.1c0.7,0.5,1.5,0.7,2.3,0.6c0.8-0.1,1.6-0.5,2.1-1.2c1.1-1.4,0.8-3.3-0.5-4.4l0,0
				C105.2,46.5,104.5,46.3,103.8,46.3z"/>
            </g>
            <g>
                <path fill="#FFFFFF" d="M98.1,99.1c0.3-9,5.8-12.9,12.1-27.1c6-13.4-4.6-22.1-5.9-23.2c0,0,0,0,0,0c0,0,0,0,0,0
				c-1.3-1.1-12.3-9.3-23.9-0.3C68,57.9,65.5,64.1,56.8,66.5c-9.2,2.5-10.3,3.3-16,11.7l24.6,19.4l0.6,0.5l24.6,19.4
				C97.2,109.8,97.7,108.6,98.1,99.1z"/>
                <path fill="#D5D7D8" d="M90.5,118.1L40,78.3l0.3-0.4c5.9-8.4,7.1-9.3,16.3-11.9c5.2-1.4,8.1-4.2,12.4-8.4c2.7-2.6,6.1-5.9,11-9.6
				c11.9-9.2,23.3-0.7,24.6,0.3l0,0c1.3,1,12.2,10,6.1,23.8c-2.5,5.6-4.9,9.7-6.8,12.9c-3.1,5.2-5.1,8.7-5.3,14l0,0
				c-0.3,9.6-0.9,11-7.7,18.6L90.5,118.1z M41.5,78.1l48.9,38.6c6.3-7.1,6.8-8.4,7.1-17.6c0.2-5.6,2.4-9.4,5.4-14.5
				c1.9-3.2,4.3-7.3,6.8-12.8c5.8-13-4.6-21.6-5.8-22.5c-1.2-1-12-9-23.3-0.3c-4.8,3.7-8.2,6.9-10.9,9.6c-4.3,4.2-7.4,7.2-12.9,8.7
				C48.1,69.5,46.9,70.2,41.5,78.1z"/>
            </g>
            <g>

                <ellipse transform="matrix(0.6189 -0.7855 0.7855 0.6189 -51.6876 88.9835)" fill="#EDEDEE" cx="65.9"
                    cy="97.8" rx="5.2" ry="31.7" />
                <path fill="#D5D7D8" d="M89.6,118.3c-1.3,0-3.8-0.7-8.7-3.4c-5.3-2.9-12-7.5-18.6-12.7c-10.2-8-24.4-21.1-21.7-24.5
				c0.4-0.5,1.6-2.1,10.2,2.7c5.3,2.9,12,7.5,18.6,12.7c10.2,8,24.4,21.1,21.7,24.5C91,117.9,90.7,118.3,89.6,118.3z M42.1,78.2
				c-0.4,0-0.7,0.1-0.8,0.2c-1.2,1.5,6.4,11,21.6,23c6.6,5.2,13.2,9.7,18.5,12.6c6.2,3.4,8.5,3.5,8.9,3c1.2-1.5-6.4-11-21.6-23
				c-6.6-5.2-13.2-9.7-18.5-12.6C45.7,78.9,43.3,78.2,42.1,78.2z"/>
            </g>
            <path fill="#D5D7D8" d="M74,124.5c-7.7,0-12.2-6.3-12.3-6.4c-0.2-0.2-0.1-0.6,0.1-0.7c0.2-0.2,0.6-0.1,0.7,0.1
			c0.3,0.4,6.7,9.2,17.3,4.7c0.3-0.1,0.6,0,0.7,0.3c0.1,0.3,0,0.6-0.3,0.7C78,124.1,75.9,124.5,74,124.5z"/>
            <path fill="#D5D7D8" d="M74.4,130.5c-11.5,0-18.4-9.5-18.5-9.6c-0.2-0.2-0.1-0.6,0.1-0.7c0.2-0.2,0.6-0.1,0.7,0.1
			c0.4,0.6,10.3,14.2,26.6,7.2c0.3-0.1,0.6,0,0.7,0.3c0.1,0.3,0,0.6-0.3,0.7C80.5,129.9,77.3,130.5,74.4,130.5z"/>
            <g>
                <path fill="#FFFFFF" d="M78.5,106.3l1.4-3.3l-4-3.4l-2,4.8c-2.6,0-5.2,1.5-6.3,4.1c-1.4,3.4,0.1,7.3,3.5,8.7
				c3.4,1.4,7.3-0.1,8.7-3.5C81,111.1,80.4,108.2,78.5,106.3z"/>
                <path fill="#D5D7D8" d="M73.8,118.3c-0.9,0-1.9-0.2-2.8-0.6c-1.8-0.8-3.1-2.1-3.9-3.9c-0.7-1.8-0.7-3.7,0-5.5
				c1.1-2.6,3.6-4.3,6.4-4.4l2.1-5.1l4.9,4.2l-1.5,3.3c1.9,2.1,2.4,5,1.3,7.6C79.3,116.6,76.6,118.3,73.8,118.3z M73.8,104.9
				c-2.5,0-4.7,1.5-5.7,3.7c-0.6,1.5-0.7,3.2,0,4.7c0.6,1.5,1.8,2.7,3.3,3.4c3.1,1.3,6.7-0.1,8.1-3.3c1-2.3,0.5-5-1.3-6.7l-0.3-0.3
				l1.4-3.2l-3.2-2.7l-1.9,4.4l-0.4,0C73.9,104.9,73.8,104.9,73.8,104.9z"/>
            </g>
            <g>
                <path fill="#FFFFFF" d="M78.2,59.6c0,0-5.5,6.7-10.3,9.1c-4.7,2.4-9.2,2.5-12.3,4.5" />
                <path fill="#D5D7D8" d="M55.6,73.7c-0.2,0-0.3-0.1-0.4-0.2c-0.2-0.2-0.1-0.6,0.2-0.7c1.6-1,3.5-1.5,5.6-2.1c2-0.6,4.4-1.2,6.7-2.4
				c4.6-2.4,10.1-8.9,10.1-9c0.2-0.2,0.5-0.3,0.7-0.1c0.2,0.2,0.3,0.5,0.1,0.7c-0.2,0.3-5.7,6.8-10.4,9.3c-2.4,1.2-4.8,1.9-6.9,2.5
				c-2.1,0.6-3.9,1.1-5.3,2C55.8,73.7,55.7,73.7,55.6,73.7z"/>
            </g>
        </g>
    </svg>
);

const NoPostsSvg = () => (
    <svg fill="currentColor" width="1em" height="1em" viewBox="0 0 160 160">
        <circle cx="80" cy="80" r="80" />
        <g>
            <g>
                <path fill="#EDEDEE"
                    d="M104.5,52.4L53.2,65.5c0,0,4.4,37.6,20.3,62.1l48.8-13.8C122.3,113.8,106.9,85.3,104.5,52.4z" />
                <path fill="#D5D7D8" d="M73.3,128.2l-0.2-0.3c-15.8-24.3-20.3-61.9-20.4-62.3l-0.1-0.5L105,51.7l0,0.6c2.3,32.4,17.5,60.9,17.7,61.2
				l0.3,0.6L73.3,128.2z M53.8,65.9c0.6,4.3,5.4,38.5,20,61.1l47.8-13.5c-2.2-4.2-15.2-30.6-17.5-60.4L53.8,65.9z"/>
            </g>
            <path fill="#D5D7D8" d="M71.3,105.8l-0.2-0.3c-0.3-0.4-7.1-9.6-9.9-33.4l-0.1-0.5L97.4,62l0.1,0.5c0,0.2,4.7,22.7,8.9,32.8l0.2,0.6
			L71.3,105.8z M62.4,72.4c2.5,20.9,8.2,30.5,9.4,32.2l33.4-9.3c-3.8-9.5-7.9-28.5-8.6-31.9L62.4,72.4z"/>
            <g>
                <path fill="#FFFFFF"
                    d="M108.4,50.4L60.6,35.3c0,0-5.1,31.3-36.2,49.2l46.5,21.2C70.9,105.7,102,93.6,108.4,50.4z" />
                <path fill="#D5D7D8" d="M70.9,106.2L23.2,84.6l0.9-0.5C54.6,66.5,60,35.6,60,35.3l0.1-0.6L109,50.1l-0.1,0.4
				c-6.4,43-37.5,55.5-37.8,55.7L70.9,106.2z M25.5,84.5l45.4,20.6c2.7-1.1,30.8-14.1,36.9-54.3L61,36
				C60.1,40.4,53.3,67.9,25.5,84.5z"/>
            </g>
            <path fill="#D5D7D8" d="M82.9,90.4l-0.3-0.1l-37.7-16l0.6-0.6c0.1-0.1,12-11.8,18.4-30l0.2-0.5l37.7,12.1l-0.1,0.5
			c0,0.2-3.9,21.3-18.5,34.4L82.9,90.4z M46.7,73.8l36,15.3c13-11.9,17.2-30.1,17.8-33.2L64.7,44.4C58.9,60.4,49.1,71.3,46.7,73.8z"
            />

            <rect x="50.4" y="71.9" transform="matrix(0.4263 -0.9046 0.9046 0.4263 -44.3333 92.7163)" fill="#D5D7D8"
                width="1.1" height="18.8" />
            <rect x="55.1" y="70" transform="matrix(0.405 -0.9143 0.9143 0.405 -47.4515 103.2298)" fill="#D5D7D8"
                width="1.1" height="36.2" />
            <polygon fill="#D5D7D8" points="90.3,80.4 85.4,65.5 70,69.8 65.8,61 52.8,66.5 52.4,65.5 66.3,59.7 70.5,68.6 86.1,64.2 91.3,80.1" />
        </g>
        <path fill="#D5D7D8" d="M76.6,59.3c-0.4,0-0.7-0.1-1.1-0.2c-1.6-0.6-2.4-2.6-1.8-4.4c0.7-1.8,2.6-2.7,4.2-2.1l0,0
		c0.8,0.3,1.4,0.9,1.8,1.8c0.3,0.8,0.3,1.7,0,2.6c-0.3,0.9-0.9,1.5-1.7,1.9C77.6,59.2,77.1,59.3,76.6,59.3z M76.9,53.5
		c-0.9,0-1.8,0.6-2.2,1.6c-0.5,1.2,0,2.6,1.1,3c0.5,0.2,1.1,0.2,1.6-0.1c0.6-0.3,1-0.8,1.2-1.4c0.2-0.6,0.2-1.3,0-1.8
		c-0.2-0.6-0.6-1-1.2-1.2l0,0C77.4,53.5,77.2,53.5,76.9,53.5z"/>
    </svg>
);

const NoDraftPostsSvg = () => (
    <svg fill="currentColor" width="1em" height="1em" viewBox="0 0 160 160">
        <circle cx="80" cy="80" r="80" />
        <g>
            <g>
                <path fill="#FFFFFF"
                    d="M124.7,59.6L74,43.6c0,0-5.4,33.1-38.4,52.1L85,118.1C85,118.1,117.9,105.4,124.7,59.6z" />
                <path fill="#D5D7D8" d="M84.9,118.7L34.5,95.8l0.9-0.5c32.3-18.6,38.1-51.4,38.1-51.7l0.1-0.6l51.7,16.3l-0.1,0.4
				c-6.7,45.5-39.7,58.8-40.1,59L84.9,118.7z M36.8,95.7L85,117.6c2.7-1.2,32.6-14.9,39.1-57.6L74.4,44.3
				C73.5,48.8,66.4,78.1,36.8,95.7z"/>
            </g>
            <path fill="#D5D7D8" d="M97.7,101.9l-0.3-0.1l-39.9-17l0.6-0.6c0.1-0.1,12.7-12.5,19.5-31.8l0.2-0.5l39.9,12.8l-0.1,0.5
			c0,0.2-4.1,22.5-19.6,36.5L97.7,101.9z M59.3,84.4l38.2,16.2c13.8-12.6,18.3-32,18.9-35.2l-38-12.2C72.3,70.2,61.8,81.8,59.3,84.4
			z"/>

            <rect x="63.3" y="82.4" transform="matrix(0.4263 -0.9046 0.9046 0.4263 -46.898 110.7155)" fill="#D5D7D8"
                width="1.1" height="19.9" />

            <rect x="68.3" y="80.3" transform="matrix(0.405 -0.9143 0.9143 0.405 -50.0509 122.0859)" fill="#D5D7D8"
                width="1.1" height="38.4" />
            <polygon fill="#D5D7D8" points="105.6,91.4 100.4,75.6 84,80.1 79.6,70.8 65.8,76.6 65.4,75.6 80.1,69.4 84.6,78.9 101.1,74.3
			106.6,91 		"/>
            <path fill="#D5D7D8" d="M90.4,71.1c-0.3,0-0.7-0.1-1-0.2c-1.6-0.6-2.4-2.5-1.7-4.2c0.3-0.8,0.9-1.5,1.7-1.9c0.8-0.4,1.6-0.5,2.4-0.2
			c1.6,0.6,2.4,2.5,1.7,4.2C93,70.2,91.7,71.1,90.4,71.1z M90.8,65.5c-0.3,0-0.6,0.1-0.9,0.2c-0.5,0.3-0.9,0.7-1.2,1.3
			c-0.4,1.2,0,2.5,1.1,2.9c1,0.4,2.3-0.2,2.7-1.4c0.4-1.2,0-2.5-1.1-2.9l0,0C91.2,65.5,91,65.5,90.8,65.5z"/>
            <g>
                <g>
                    <polygon fill="#FFFFFF" points="74.3,77.5 76.6,86.9 72.3,84.9 67.8,82.8" />
                    <path fill="#D5D7D8" d="M76.6,87.4c-0.1,0-0.2,0-0.2,0l-8.8-4.1c-0.2-0.1-0.3-0.2-0.3-0.4c0-0.2,0-0.4,0.2-0.5l6.5-5.3
					c0.1-0.1,0.3-0.2,0.5-0.1c0.2,0.1,0.3,0.2,0.3,0.4l2.3,9.4c0,0.2,0,0.4-0.2,0.5C76.8,87.4,76.7,87.4,76.6,87.4z M68.8,82.7
					l7,3.3L74,78.4L68.8,82.7z"/>
                </g>
                <g>
                    <polygon fill="#EDEDEE"
                        points="51.3,49.2 44.8,54.5 67.8,82.8 70.4,82.7 73.7,80.1 74.3,77.5 				" />
                    <path fill="#D5D7D8" d="M67.8,83.3c-0.2,0-0.3-0.1-0.4-0.2l-23-28.3c-0.1-0.1-0.1-0.2-0.1-0.4c0-0.1,0.1-0.3,0.2-0.4l6.6-5.3
					c0.2-0.2,0.6-0.2,0.7,0.1l23,28.3c0.1,0.1,0.1,0.3,0.1,0.5l-0.6,2.6c0,0.1-0.1,0.2-0.2,0.3l-3.3,2.7c-0.1,0.1-0.2,0.1-0.3,0.1
					L67.8,83.3C67.8,83.3,67.8,83.3,67.8,83.3z M45.5,54.6L68,82.3l2.2-0.1l3-2.4l0.5-2.2L51.3,49.9L45.5,54.6z"/>
                </g>
                <g>
                    <line fill="#FFFFFF" x1="47.2" y1="54.2" x2="70.4" y2="82.8" />

                    <rect x="58.3" y="50.1" transform="matrix(0.7764 -0.6302 0.6302 0.7764 -30.0321 52.3837)"
                        fill="#D5D7D8" width="1.1" height="36.8" />
                </g>
                <g>
                    <line fill="#FFFFFF" x1="50.5" y1="51.5" x2="73.7" y2="80.1" />

                    <rect x="61.6" y="47.4" transform="matrix(0.7764 -0.6302 0.6302 0.7764 -27.5861 53.8779)"
                        fill="#D5D7D8" width="1.1" height="36.8" />
                </g>
                <g>
                    <polygon fill="#FFFFFF"
                        points="48.6,49.1 45.3,51.8 44.7,54.4 47.3,54.3 50.6,51.6 51.3,49 				" />
                    <path fill="#D5D7D8" d="M44.7,54.9c-0.2,0-0.3-0.1-0.4-0.2c-0.1-0.1-0.1-0.3-0.1-0.5l0.6-2.6c0-0.1,0.1-0.2,0.2-0.3l3.3-2.7
					c0.1-0.1,0.2-0.1,0.3-0.1l2.7-0.1c0.2,0,0.3,0.1,0.4,0.2c0.1,0.1,0.1,0.3,0.1,0.5l-0.6,2.6c0,0.1-0.1,0.2-0.2,0.3l-3.3,2.7
					c-0.1,0.1-0.2,0.1-0.3,0.1L44.7,54.9C44.7,54.9,44.7,54.9,44.7,54.9z M45.8,52.1l-0.4,1.7l1.8-0.1l3-2.5l0.4-1.7l-1.8,0.1
					L45.8,52.1z"/>
                </g>
                <g>
                    <path fill="#EDEDEE" d="M72.3,84.9c0,0,2.7-0.8,3.2-2.6l0.9,4.3L72.3,84.9z" />
                    <path fill="#D5D7D8" d="M76.4,87.1c-0.1,0-0.1,0-0.2,0l-4.2-1.7c-0.2-0.1-0.3-0.3-0.3-0.5c0-0.2,0.2-0.4,0.4-0.5
					c0.7-0.2,2.5-1,2.9-2.2c0.1-0.2,0.2-0.4,0.5-0.4c0.2,0,0.4,0.2,0.5,0.4l0.9,4.3c0,0.2,0,0.4-0.2,0.5
					C76.7,87.1,76.6,87.1,76.4,87.1z M73.7,84.9l2,0.8l-0.4-2C74.8,84.3,74.2,84.6,73.7,84.9z"/>
                </g>
            </g>
        </g>
    </svg>
);

const NoCollectionsSvg = () => (
    <svg fill="currentColor" width="1em" height="1em" viewBox="0 0 160 160">
        <circle cx="80" cy="80" r="80" />
        <g>
            <polygon fill="#EDEDEE" points="114.6,113.4 45.2,113.5 38.2,69.1 107.6,69" />
            <path fill="#D5D7D8" d="M45.2,114c-0.3,0-0.5-0.2-0.5-0.4l-6.9-44.4c0-0.2,0-0.3,0.1-0.4c0.1-0.1,0.2-0.2,0.4-0.2l69.4-0.1
            c0,0,0,0,0,0c0.3,0,0.5,0.2,0.5,0.4l6.9,44.4c0,0.2,0,0.3-0.1,0.4c-0.1,0.1-0.2,0.2-0.4,0.2L45.2,114C45.2,114,45.2,114,45.2,114
            z M38.9,69.6l6.8,43.3l68.3-0.1l-6.8-43.3L38.9,69.6z"/>
        </g>
        <g>
            <path fill="#FFFFFF" d="M51.2,49.6c0,0-7,32.3-2.1,54.3l59.8-0.1c0,0,2.7-36.9,8.2-53.6L51.2,49.6z" />
            <path fill="#D5D7D8" d="M48.7,104.5l-0.1-0.4c-4.9-21.9,2-54.2,2.1-54.6l0.1-0.4l67.2,0.7l-0.2,0.7c-5.5,16.5-8.2,53.1-8.2,53.5
            l0,0.5L48.7,104.5z M51.6,50.1c-0.8,3.8-6.5,33-2.1,53.3l58.9-0.1c0.3-4.4,3-36.7,8-52.5L51.6,50.1z"/>
        </g>
        <g>
            <path fill="#FFFFFF" d="M56.9,55.1c0,0-6,18.6-6.8,50.7l63.4-0.6c0,0,3.5-34,11.6-50.5L56.9,55.1z" />
            <path fill="#D5D7D8" d="M49.5,106.3l0-0.5c0.9-31.8,6.8-50.6,6.9-50.8l0.1-0.4l69.4-0.5l-0.4,0.8c-7.9,16.2-11.5,50-11.5,50.4l0,0.5
            L49.5,106.3z M57.3,55.6c-0.8,2.7-5.8,20.8-6.7,49.6l62.4-0.6c0.5-4.1,4-33.9,11.2-49.5L57.3,55.6z"/>
        </g>
        <g>
            <path fill="#EDEDEE" d="M89.4,69.5l2.3-5c0.3-1.8-1-3.3-2.9-3.3l-22.9,0.1c-1.9,0-3.6,1.5-3.9,3.3l-16.8,49.1l69.2-0.4l16.1-44.4
            L89.4,69.5z"/>
            <path fill="#D5D7D8" d="M45.1,114.3c-0.2,0-0.3-0.1-0.4-0.2c-0.1-0.1-0.1-0.3-0.1-0.5l16.8-49.1c0.3-2,2.3-3.7,4.4-3.7l22.9-0.1
            c0,0,0,0,0,0c1.1,0,2,0.4,2.6,1.2c0.6,0.7,0.9,1.7,0.7,2.7c0,0,0,0.1,0,0.1L90.2,69l40.1-0.6c0.2,0,0.3,0.1,0.4,0.2
            c0.1,0.1,0.1,0.3,0.1,0.5l-16.1,44.4c-0.1,0.2-0.3,0.3-0.5,0.3L45.1,114.3C45.1,114.3,45.1,114.3,45.1,114.3z M88.8,61.7
            C88.8,61.7,88.8,61.7,88.8,61.7l-22.9,0.1c-1.6,0-3.2,1.3-3.4,2.9l-16.6,48.5l68.1-0.4l15.7-43.4L89.4,70c-0.2,0-0.3-0.1-0.4-0.2
            c-0.1-0.2-0.1-0.3,0-0.5l2.2-5c0.1-0.7-0.1-1.3-0.5-1.8C90.2,62,89.6,61.7,88.8,61.7z"/>
        </g>
    </svg>
);

const NoDocumentsSvg = () => (
    <svg fill="currentColor" width="1em" height="1em" viewBox="0 0 160 160">
        <circle cx="80" cy="80" r="80" />
        <g>
            <g>
                <polygon fill="#EDEDEE" points="32.3,101.4 88.5,91.4 123,108 66.1,118" />
                <path fill="#D5D7D8" d="M66.1,118.6c-0.1,0-0.2,0-0.2-0.1L32,101.9c-0.2-0.1-0.3-0.3-0.3-0.5s0.2-0.4,0.4-0.4l56.2-10.1
				c0.1,0,0.2,0,0.3,0l34.5,16.6c0.2,0.1,0.3,0.3,0.3,0.5s-0.2,0.4-0.4,0.4L66.1,118.6C66.2,118.6,66.2,118.6,66.1,118.6z
				 M33.9,101.7l32.3,15.8l55.1-9.8L88.4,91.9L33.9,101.7z"/>
            </g>
            <g>
                <polygon fill="#EDEDEE" points="32.4,95.1 88.6,85.1 123.2,101.7 66.3,111.7" />
                <path fill="#D5D7D8" d="M66.3,112.3c-0.1,0-0.2,0-0.2-0.1L32.2,95.6c-0.2-0.1-0.3-0.3-0.3-0.5s0.2-0.4,0.4-0.4l56.2-10.1
				c0.1,0,0.2,0,0.3,0l34.5,16.6c0.2,0.1,0.3,0.3,0.3,0.5s-0.2,0.4-0.4,0.4L66.3,112.3L66.3,112.3z M34.1,95.4l32.3,15.8l55.1-9.8
				L88.6,85.6L34.1,95.4z"/>
            </g>
            <g>
                <polygon fill="#EDEDEE" points="32.3,89 88.5,78.9 123,95.5 66.1,105.6" />
                <path fill="#D5D7D8" d="M66.1,106.1c-0.1,0-0.2,0-0.2-0.1L32,89.4c-0.2-0.1-0.3-0.3-0.3-0.5s0.2-0.4,0.4-0.4l56.2-10.1
				c0.1,0,0.2,0,0.3,0L123.3,95c0.2,0.1,0.3,0.3,0.3,0.5s-0.2,0.4-0.4,0.4L66.1,106.1C66.2,106.1,66.2,106.1,66.1,106.1z M33.9,89.2
				L66.2,105l55.1-9.8L88.4,79.4L33.9,89.2z"/>
            </g>
            <g>
                <path fill="#FFFFFF"
                    d="M115.1,57.9l-35.8-17c0,0-7,31.5-44.8,43.3l38.7,17.9C73.1,102.1,109.6,89.6,115.1,57.9z" />
                <path fill="#D5D7D8" d="M73.1,102.6L33,84.1l1.3-0.4c19.4-6,30.4-17.4,36.3-25.8c6.4-9.2,8.2-17,8.2-17.1l0.1-0.6l36.8,17.5
				l-0.1,0.4c-5.5,31.6-42,44.4-42.3,44.6L73.1,102.6z M35.9,84.2l37.3,17.3c2.9-1.1,35.9-13.8,41.4-43.3l-35-16.6
				C78.4,46.1,69.5,73.1,35.9,84.2z"/>
            </g>
            <rect x="85.6" y="53" transform="matrix(0.4356 -0.9001 0.9001 0.4356 -5.9292 111.7368)" fill="#D5D7D8"
                width="1.1" height="15.1" />
            <rect x="88.1" y="53.6" transform="matrix(0.4196 -0.9077 0.9077 0.4196 -10.1831 119.8888)" fill="#D5D7D8"
                width="1.1" height="28.7" />
            <rect x="84.9" y="57.1" transform="matrix(0.4195 -0.9077 0.9077 0.4195 -15.3054 119.0615)" fill="#D5D7D8"
                width="1.1" height="28.7" />
            <rect x="81.7" y="61" transform="matrix(0.4195 -0.9077 0.9077 0.4195 -20.6742 118.4205)" fill="#D5D7D8"
                width="1.1" height="28.7" />
            <rect x="77.9" y="64.4" transform="matrix(0.4196 -0.9077 0.9077 0.4196 -25.982 116.9268)" fill="#D5D7D8"
                width="1.1" height="28.7" />
            <rect x="73.5" y="67.5" transform="matrix(0.4195 -0.9077 0.9077 0.4195 -31.2806 114.6898)" fill="#D5D7D8"
                width="1.1" height="28.7" />
            <rect x="67.1" y="72.9" transform="matrix(0.4277 -0.9039 0.9039 0.4277 -37.3656 109.2555)" fill="#D5D7D8"
                width="1.1" height="22.5" />
        </g>
    </svg>
);

const NoFollowCollectionsSvg = () => (
    <svg fill="currentColor" width="1em" height="1em" viewBox="0 0 160 160">
        <circle cx="80" cy="80" r="80" />
        <g>
            <g>
                <path fill="#EDEDEE" d="M108.9,45.6H80.7c-2.5,0-4.5,2-4.5,4.5V67c0,2.5,2,4.5,4.5,4.5h10l0.9,1.5l3.2,5.5L98,73l0.9-1.5h10
				c2.5,0,4.5-2,4.5-4.5V50.1C113.4,47.6,111.4,45.6,108.9,45.6z"/>
                <path fill="#D5D7D8" d="M94.8,79.1L94.8,79.1c-0.2,0-0.4-0.1-0.5-0.3L90.5,72h-9.7c-2.8,0-5-2.2-5-5V50.1c0-2.8,2.2-5,5-5h28.2
				c2.8,0,5,2.2,5,5V67c0,2.8-2.2,5-5,5h-9.7l-3.9,6.8C95.2,79,95,79.1,94.8,79.1z M80.7,46.1c-2.2,0-3.9,1.8-3.9,3.9V67
				c0,2.2,1.8,3.9,3.9,3.9h10c0.2,0,0.4,0.1,0.5,0.3l3.6,6.3l3.6-6.3c0.1-0.2,0.3-0.3,0.5-0.3h10c2.2,0,3.9-1.8,3.9-3.9V50.1
				c0-2.2-1.8-3.9-3.9-3.9H80.7z"/>
            </g>
            <g>
                <path fill="#C4C6C8" d="M98.2,58.5c0,1.7-0.3,3-0.8,3.8s-1.4,1.3-2.5,1.3c-1.1,0-1.9-0.4-2.5-1.3s-0.9-2.1-0.9-3.8
				c0-1.7,0.3-3,0.8-3.9s1.4-1.2,2.5-1.2c1.1,0,1.9,0.4,2.5,1.3C98,55.6,98.2,56.9,98.2,58.5z M93.1,58.5c0,1.4,0.1,2.3,0.4,2.9
				c0.3,0.6,0.7,0.9,1.3,0.9c0.6,0,1.1-0.3,1.3-0.9c0.3-0.6,0.4-1.5,0.4-2.9c0-1.3-0.1-2.3-0.4-2.9c-0.3-0.6-0.7-0.9-1.3-0.9
				c-0.6,0-1.1,0.3-1.3,0.9C93.3,56.2,93.1,57.2,93.1,58.5z"/>
            </g>
        </g>
        <g>
            <g>
                <polygon fill="#FFFFFF" points="97.6,112.6 51.2,112.7 46.6,83 93,82.9" />
                <path fill="#D5D7D8"
                    d="M50.7,113.2l-4.8-30.8l47.5-0.1l4.8,30.8L50.7,113.2z M47.2,83.5l4.5,28.7l45.4,0l-4.5-28.7L47.2,83.5z" />
            </g>
            <g>
                <path fill="#FFFFFF" d="M80.8,83.2l1.5-3.4c0.2-1.2-0.7-2.2-1.9-2.2l-15.3,0.1c-1.3,0-2.4,1-2.6,2.2l-11.3,32.9l46.3-0.3l10.8-29.7
				L80.8,83.2z"/>
                <path fill="#D5D7D8" d="M50.4,113.4l11.5-33.6c0.2-1.4,1.6-2.6,3.1-2.6l15.3-0.1c0,0,0,0,0,0c0.8,0,1.4,0.3,1.9,0.8
				c0.5,0.5,0.6,1.2,0.5,2l0,0.1l-1.2,2.6l27.3-0.4l-11.1,30.8L50.4,113.4z M80.4,78.2C80.4,78.2,80.4,78.2,80.4,78.2l-15.4,0.1
				c-1,0-2,0.8-2.1,1.8l-11,32.3l45.2-0.2l10.4-28.7L80,83.8l1.8-4.1c0-0.4-0.1-0.8-0.3-1.1C81.3,78.4,80.9,78.2,80.4,78.2z"/>
            </g>
        </g>
    </svg>
);

const NoFollowHashtagSvg = () => (
    <svg fill="currentColor" width="1em" height="1em" viewBox="0 0 160 160">
        <circle cx="80" cy="80" r="80" />
        <g transform="translate(5 -5)">
            <g>
                <path fill="#EDEDEE" d="M108.9,45.6H80.7c-2.5,0-4.5,2-4.5,4.5V67c0,2.5,2,4.5,4.5,4.5h10l0.9,1.5l3.2,5.5L98,73l0.9-1.5h10
				c2.5,0,4.5-2,4.5-4.5V50.1C113.4,47.6,111.4,45.6,108.9,45.6z"/>
                <path fill="#D5D7D8" d="M94.8,79.1L94.8,79.1c-0.2,0-0.4-0.1-0.5-0.3L90.5,72h-9.7c-2.8,0-5-2.2-5-5V50.1c0-2.8,2.2-5,5-5h28.2
				c2.8,0,5,2.2,5,5V67c0,2.8-2.2,5-5,5h-9.7l-3.9,6.8C95.2,79,95,79.1,94.8,79.1z M80.7,46.1c-2.2,0-3.9,1.8-3.9,3.9V67
				c0,2.2,1.8,3.9,3.9,3.9h10c0.2,0,0.4,0.1,0.5,0.3l3.6,6.3l3.6-6.3c0.1-0.2,0.3-0.3,0.5-0.3h10c2.2,0,3.9-1.8,3.9-3.9V50.1
				c0-2.2-1.8-3.9-3.9-3.9H80.7z"/>
            </g>
            <g>
                <path fill="#C4C6C8" d="M98.2,58.5c0,1.7-0.3,3-0.8,3.8s-1.4,1.3-2.5,1.3c-1.1,0-1.9-0.4-2.5-1.3s-0.9-2.1-0.9-3.8
				c0-1.7,0.3-3,0.8-3.9s1.4-1.2,2.5-1.2c1.1,0,1.9,0.4,2.5,1.3C98,55.6,98.2,56.9,98.2,58.5z M93.1,58.5c0,1.4,0.1,2.3,0.4,2.9
				c0.3,0.6,0.7,0.9,1.3,0.9c0.6,0,1.1-0.3,1.3-0.9c0.3-0.6,0.4-1.5,0.4-2.9c0-1.3-0.1-2.3-0.4-2.9c-0.3-0.6-0.7-0.9-1.3-0.9
				c-0.6,0-1.1,0.3-1.3,0.9C93.3,56.2,93.1,57.2,93.1,58.5z"/>
            </g>
        </g>
        <g>
            <g>
                <rect x="54.9" y="84.4" fill="#D5D7D8" width="38.2" height="1.1" />
                <rect x="54.9" y="97.4" fill="#D5D7D8" width="38.2" height="1.1" />
            </g>
            <g>

                <rect x="44.9" y="90.9" transform="matrix(0.1533 -0.9882 0.9882 0.1533 -35.8266 141.1086)"
                    fill="#D5D7D8" width="39.1" height="1.1" />

                <rect x="64.1" y="90.9" transform="matrix(0.1533 -0.9882 0.9882 0.1533 -19.5595 160.0939)"
                    fill="#D5D7D8" width="39.1" height="1.1" />
            </g>
        </g>
    </svg>
);

const NoFollowUsersSvg = () => (
    <svg fill="currentColor" width="1em" height="1em" viewBox="0 0 160 160">
        <circle cx="80" cy="80" r="80" />
        <g>
            <g>
                <path fill="#EDEDEE" d="M108.1,41.3H79.9c-2.5,0-4.5,2-4.5,4.5v16.9c0,2.5,2,4.5,4.5,4.5h10l0.9,1.5l3.2,5.5l3.2-5.5l0.9-1.5h10
				c2.5,0,4.5-2,4.5-4.5V45.8C112.6,43.4,110.6,41.3,108.1,41.3z"/>
                <path fill="#D5D7D8" d="M94,74.8c-0.2,0-0.4-0.1-0.5-0.3l-3.9-6.8h-9.7c-2.8,0-5-2.2-5-5V45.8c0-2.8,2.2-5,5-5h28.2c2.8,0,5,2.2,5,5
				v16.9c0,2.8-2.2,5-5,5h-9.7l-3.9,6.8C94.4,74.7,94.2,74.8,94,74.8z M79.9,41.9c-2.2,0-3.9,1.8-3.9,3.9v16.9
				c0,2.2,1.8,3.9,3.9,3.9h10c0.2,0,0.4,0.1,0.5,0.3l3.6,6.3l3.6-6.3c0.1-0.2,0.3-0.3,0.5-0.3h10c2.2,0,3.9-1.8,3.9-3.9V45.8
				c0-2.2-1.8-3.9-3.9-3.9H79.9z"/>
            </g>
            <g>
                <path fill="#C4C6C8" d="M97.4,54.3c0,1.7-0.3,3-0.8,3.8c-0.6,0.8-1.4,1.3-2.5,1.3c-1.1,0-1.9-0.4-2.5-1.3c-0.6-0.9-0.9-2.1-0.9-3.8
				c0-1.7,0.3-3,0.8-3.9c0.6-0.8,1.4-1.2,2.5-1.2c1.1,0,1.9,0.4,2.5,1.3C97.1,51.3,97.4,52.6,97.4,54.3z M92.3,54.3
				c0,1.4,0.1,2.3,0.4,2.9s0.7,0.9,1.3,0.9s1.1-0.3,1.3-0.9c0.3-0.6,0.4-1.5,0.4-2.9c0-1.3-0.1-2.3-0.4-2.9
				c-0.3-0.6-0.7-0.9-1.3-0.9s-1.1,0.3-1.3,0.9S92.3,52.9,92.3,54.3z"/>
            </g>
        </g>
        <g>
            <g>
                <path fill="#FFFFFF" d="M91.3,114.2c-0.1-10.6-8.7-19.2-19.3-19.2s-19.2,8.6-19.3,19.2H91.3z" />
                <path fill="#D5D7D8" d="M91.9,114.8H52.1l0-0.5c0.1-10.8,9-19.7,19.9-19.7c10.8,0,19.8,8.8,19.9,19.7L91.9,114.8z M53.2,113.7h37.6
				c-0.4-10-8.7-18.1-18.8-18.1C61.9,95.6,53.6,103.7,53.2,113.7z"/>
            </g>
            <g>
                <circle fill="#FFFFFF" cx="72" cy="82.7" r="9.9" />
                <path fill="#D5D7D8" d="M72,93.1c-5.7,0-10.4-4.7-10.4-10.4S66.3,72.3,72,72.3s10.4,4.7,10.4,10.4S77.7,93.1,72,93.1z M72,73.3
				c-5.1,0-9.3,4.2-9.3,9.3c0,5.1,4.2,9.3,9.3,9.3s9.3-4.2,9.3-9.3C81.3,77.5,77.2,73.3,72,73.3z"/>
            </g>
        </g>
    </svg>
);

const NoContentIcon = props => {

    if (props.type === "notification")
    {
        return <Icon component={ NoNotificationsSvg } />;
    }

    if (props.type === "posts")
    {
        return <Icon component={ NoPostsSvg } />;
    }

    if (props.type === "drafts")
    {
        return <Icon component={ NoDraftPostsSvg } />;
    }

    if (props.type === "collections")
    {
        return <Icon component={ NoCollectionsSvg } />;
    }

    if (props.type === "documents")
    {
        return <Icon component={ NoDocumentsSvg } />;
    }

    if (props.type === "userFollowing")
    {
        return <Icon component={ NoFollowUsersSvg } />;
    }

    if (props.type === "collectionFollowing")
    {
        return <Icon component={ NoFollowCollectionsSvg } />;
    }

    if (props.type === "hashtagFollowing")
    {
        return <Icon component={ NoFollowHashtagSvg } />;
    }

    return null;
};


export default NoContentIcon;