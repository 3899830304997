import React from "react";
import { Form, Button, Switch } from 'antd';
import EmailFormItems from "../fields";
import { emailUseMutation } from '../../hooks';
import { Localize } from "components/service";

import './email-rule-form.scss';



const EmailRuleForm = ({ option }) => {


    const [ form ] = Form.useForm();

    const initialValues = (name = '') => option?.filter(({ key }) => key === name)[ 0 ].value

    const {
        _setEmailOption,
        loadingMutationEmailOption,
    } = emailUseMutation.option();


    return (

        <Form
            key="edit-email-form"
            layout="vertical"
            form={ form }
            className="model-form rule-email-form"
            onFinish={ (values) => {

                _setEmailOption({
                    variables: {
                        input: [
                            {
                                key: "account_activation_by_email",
                                value: values.account_activation_by_email ? '1' : '0'
                            },
                            {
                                key: "registration_by_email",
                                value: values.registration_by_email ? '1' : '0'
                            },
                            {

                                key: "allowed_email",
                                value: values.allowed_email
                            },
                            {
                                key: "allowed_personal_email",
                                value: values.allowed_personal_email
                            }
                        ]
                    }
                })
            } }>

            <Form.Item
                name="account_activation_by_email"
                label={ <Localize>FORMS.Input_Label_ActivationByEmail</Localize> }
                initialValue={ initialValues('account_activation_by_email') === '1' ? true : false }
                className='form-item-switch'
            >
                <Switch defaultChecked={ initialValues('account_activation_by_email') === '1' ? true : false } />
            </Form.Item>

            <Form.Item
                name="registration_by_email"
                label={ <Localize>FORMS.Input_Label_RegistrationByEmail</Localize> }
                initialValue={ initialValues('registration_by_email') === '1' ? true : false }
                className='form-item-switch'
            >
                <Switch defaultChecked={ initialValues('registration_by_email') === '1' ? true : false } />
            </Form.Item>

            <Form.Item
                name="allowed_personal_email"
                label={ <Localize>FORMS.Input_Label_AllowedPersonalEmail</Localize> }
            >
                <EmailFormItems.TagRule name="allowed_personal_email" form={ form } initialValue={ JSON.parse(initialValues('allowed_personal_email')) } />
            </Form.Item>

            <Form.Item
                name="allowed_email"
                label={ <Localize>FORMS.Input_Label_AllowedEmail</Localize> }
            >
                <EmailFormItems.TagRule name="allowed_email" form={ form } initialValue={ JSON.parse(initialValues('allowed_email')) } />
            </Form.Item>

            <div className="form-btn-holder">

                <Button
                    className="btn-right"
                    type="primary"
                    htmlType="submit"
                    loading={ loadingMutationEmailOption }
                >
                    <Localize>GLOBAL.Button_Text_SaveChanges</Localize>
                </Button>
            </div>
        </Form>
    );
};

export default EmailRuleForm;