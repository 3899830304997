

function convertToSelect(data) {

    const result = [];

    data?.forEach(item => {
        result.push({
            label: `${ item.node.name } ${ item.node.surname }`,
            value: item.node.id,
            disabled: (item.node.role_id === '1' || item.node.role_id === '2'),
        });
    });
    return result;
}


function statusColor(status) {
    switch (status)
    {
        case 'block':
            return 'color-red';

        case 'inactive':
            return 'color-yellow';

        case 'active':
            return 'color-green';

        default:
            return '';
    }
}




const usersFuncHelper = {
    convertToSelect,
    statusColor,
};

export default usersFuncHelper;