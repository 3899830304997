import SellerInfoField from "./seller-info-field";
import SellerQuarterField from "./seller-quarter-field";
import SellerRemoveField from "./seller-remove-field";


const SellerFields = {
    Info: SellerInfoField,
    Quarter: SellerQuarterField,
    Remove: SellerRemoveField
}

export default SellerFields;