import { gql } from "@apollo/client";


export const GET_DEALERS = gql`
    query GetDealers(
        $text: String,
        $first: Int,
        $page: Int,
        $orderBy: [QueryDealersOrderByOrderByClause!],
        $where: QueryDealersWhereWhereConditions
    ){
    dealers(
        text: $text,
        first: $first,
        page: $page,
        orderBy: $orderBy,
        where: $where
        ) {
        paginatorInfo{
            total
            count
            currentPage
            perPage
            lastPage
        }
        data {
            id
            company_name
            dealer_number
            street
            zip_code
            city
            region
            district
            created_at
            updated_at
        }
      }
    }
`;


export const GET_DEALERS_CURSOR = gql`
    query DealersCursor($text: String, $first: Int, $after: String) {
        dealersCursor(text: $text, first: $first, after: $after) {
            pageInfo {
            hasNextPage
            hasPreviousPage
            startCursor
            endCursor
            total
            count
            currentPage
        }
        edges {
            node {
                id
                company_name
                dealer_number
            }
            cursor
        }
     }
  }
`;


export const GET_DEALER = gql`
    query GetDealer($id: ID!){
        dealer(id: $id) {
            id
            company_name
            dealer_number
            street
            zip_code
            city
            region
            district
            created_at
            updated_at
            }
        }
`;

