import DealerFields from "./fields";
import DealerForms from "./forms";
import DealerConst from "./dealer-const";
import DealerHelpers from "./helpers";
import DealerFilters from './filters';
import { dealerUseMutation } from "./hooks";


const Dealer = {
  Filters: DealerFilters,
  Fields: DealerFields,
  Forms: DealerForms,
  Const: DealerConst,
  Helpers: DealerHelpers,
  Hooks: dealerUseMutation
}

export default Dealer;
