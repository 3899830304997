import React from 'react';
import Icon from '@ant-design/icons';


const LogOutSvg = () => (
	<svg fill="currentColor" width="1em" height="1em" viewBox="0 0 48 48" strokeWidth="1">
		<g>
			<path d="M26.7,33.7c0.2,0.3,0.6,0.4,0.9,0.4c0.3,0,0.6-0.1,0.9-0.3l8.8-8.4c0.3-0.2,0.5-0.6,0.5-1s-0.2-0.7-0.5-1l-8.8-8.4
		c-0.5-0.5-1.3-0.5-1.8,0c-0.5,0.5-0.5,1.3,0,1.8l6.6,6.4H17.2c-0.7,0-1.2,0.6-1.2,1.2s0.6,1.2,1.2,1.2h16.2l-6.6,6.4
		C26.3,32.4,26.3,33.2,26.7,33.7z"/>
			<path d="M38.9,16.2c0.7,0,1.2-0.6,1.2-1.2v-4.1c0-2.9-2.6-5.3-5.9-5.3H13.7c-3.2,0-5.9,2.4-5.9,5.3v26.9c0,2.9,2.6,5.3,5.9,5.3
		h20.5c3.2,0,5.9-2.4,5.9-5.3v-4.3c0-0.7-0.6-1.2-1.2-1.2s-1.2,0.6-1.2,1.2v4.3c0,1.6-1.5,2.8-3.4,2.8H13.7c-1.9,0-3.4-1.3-3.4-2.8
		V10.9c0-1.6,1.5-2.8,3.4-2.8h20.5c1.9,0,3.4,1.3,3.4,2.8V15C37.6,15.7,38.2,16.2,38.9,16.2z"/>
		</g>
	</svg>
);

const LogOutIcon = props => {
	return <Icon className={ props.className } component={ LogOutSvg } />;
};


export default LogOutIcon;