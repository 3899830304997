import { useHistory } from 'react-router-dom';
import { useMutation } from "@apollo/client";
import { USER_UPDATE, USER_CREATE } from "graphql/mutation/user-gql";
import UserConst from '../users-const';
import { successNotification, errorNotification } from "components/request-result";



const useMutationUserCreateUpdate = (id, redirect = true) => {

  const history = useHistory();

  const USER_CREATE_UPDATE = id ? USER_UPDATE : USER_CREATE;
  const userCreateUpdate = id ? 'userUpdate' : 'userCreate';

  const [ _setUserCreateUpdate, { loading } ] = useMutation(USER_CREATE_UPDATE,
    {
      update(cache, { data }) {

        const {
          [ userCreateUpdate ]: {
            label,
            message,
          }
        } = data;


        redirect && history.push(UserConst.basePath);

        successNotification({
          title: label,
          description: message
        });
      },
      onError(error) {
        errorNotification(error);
      }
    }
  );


  return {
    _setUserCreateUpdate,
    loadingMutationUserCreateUpdate: loading,
  }
};

export default useMutationUserCreateUpdate;

