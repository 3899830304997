const sellerBasePath = '/sales';


const SellerConst = {
  basePath     : sellerBasePath
};

export default SellerConst;









