import { useMutation } from "@apollo/client";
import { UPDATE_SUMMARY_STATISTIC } from "graphql/mutation/statistic-gql";
import { successNotification, errorNotification } from "components/request-result";



const useMutationUpdateStatus = (summaryLabels) => {


  const [ _setMutationUpdateStatus, { loading } ] = useMutation(UPDATE_SUMMARY_STATISTIC,
    {
      update(cache, { data }) {

        successNotification({
          title: summaryLabels[ data.summaryStatisticUpdate[ 0 ].key ],
          description: 'Updated'
        });
      },
      onError(error) {
        errorNotification(error);
      }
    }
  );


  return {
    _setMutationUpdateStatus,
    loadingMutationUpdateStatus: loading,
  }
};

export default useMutationUpdateStatus;

